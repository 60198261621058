const products = [
    {
        "id": "e5c7132d-65a4-48c2-92bb-eddedcf81710",
        "product_description": "Bolo Decorado 15 anos (Opção 2)",
        "product_details": "Bolo Decorado 15 anos (Opção 2)",
        "product_unique_name": "bolo-decorado-15-anos-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_15_anos_(Opção_2).jpg",
        "category": [
            {
                "id": "5145e7b1-b4ee-4cac-a927-7be6c6a5e383",
                "theme_id": "09b4b0b9-3149-435e-8ed7-8d62277649e7",
                "category_description": "Bolo 15 anos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a0bfbb51-b671-4ceb-958b-f01262f2e6f4",
                "path": "product\/Bolo_Decorado_15_anos_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "bc5a9f4d-fb8b-4b49-a502-1abdeeb35596",
        "product_description": "Bolo Decorado 15 anos ",
        "product_details": "Bolo Decorado 15 anos ",
        "product_unique_name": "bolo-decorado-15-anos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_15_anos.jpg",
        "category": [
            {
                "id": "5145e7b1-b4ee-4cac-a927-7be6c6a5e383",
                "theme_id": "09b4b0b9-3149-435e-8ed7-8d62277649e7",
                "category_description": "Bolo 15 anos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d49b23c6-0d8d-4b39-9658-ff16d664bc0a",
                "path": "product\/Bolo_Decorado_15_anos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "e51340be-8a1d-4a29-aafc-15670443bbde",
        "product_description": "Bolo Decorado 15 anos (Opção 3)",
        "product_details": "Bolo Decorado 15 anos (Opção 3)",
        "product_unique_name": "bolo-decorado-15-anos-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_15_anos_(Opção_3).jpg",
        "category": [
            {
                "id": "5145e7b1-b4ee-4cac-a927-7be6c6a5e383",
                "theme_id": "09b4b0b9-3149-435e-8ed7-8d62277649e7",
                "category_description": "Bolo 15 anos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8334794d-fb65-4450-9096-3ae6ef42ab95",
                "path": "product\/Bolo_Decorado_15_anos_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "312460e1-608c-43db-8a7d-11b5a2bacf79",
        "product_description": "Bolo Decorado Casamento",
        "product_details": "Bolo Decorado Casamento",
        "product_unique_name": "bolo-decorado-casamento",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": true,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Casamento_(1).jpg",
        "category": [
            {
                "id": "3fc2596f-02cc-40bb-91e7-6af77ccce265",
                "theme_id": "5de73a22-ac2a-44f6-9ae1-e59cc2c04a81",
                "category_description": "Bolos Casamento",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e43c3cb4-64c4-4127-9151-5a505d9d313b",
                "path": "product\/Bolo_Casamento_(1).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "a393995c-3bfe-4d70-90e8-1798d8fe77dc",
        "product_description": "Bolo Decorado Formatura de Direito",
        "product_details": "Bolo Decorado Formatura de Direito",
        "product_unique_name": "bolo-decorado-formatura-de-direito",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": true,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Formatura_Direito.jpg",
        "category": [
            {
                "id": "e5c1ccea-0417-4ad5-bdba-f68c437a0a33",
                "theme_id": "59e90483-c715-48e8-bbca-7d726b6e19b6",
                "category_description": "Bolos Formatura",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e1f16b9c-b1e6-40e6-9239-330e7b2df9af",
                "path": "product\/Bolo_Formatura_Direito.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "07dd2996-2061-4101-a904-61e439af1cc7",
        "product_description": "Bolo Formatura de Designer ",
        "product_details": "Bolo Formatura de Designer ",
        "product_unique_name": "bolo-formatura-de-designer",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Formatura_de_Designer.jpg",
        "category": [
            {
                "id": "e5c1ccea-0417-4ad5-bdba-f68c437a0a33",
                "theme_id": "59e90483-c715-48e8-bbca-7d726b6e19b6",
                "category_description": "Bolos Formatura",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8b470056-55f9-47aa-8fd3-09c73a0c4b94",
                "path": "product\/Bolo_Formatura_de_Designer.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "2626544a-ec7f-4ca2-a557-71569e7900b3",
        "product_description": "Doces de Formatura Quiropraxia",
        "product_details": "Doces de Formatura Quiropraxia",
        "product_unique_name": "doces-de-formatura-quiropraxia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Doces_de_Formatura_Quiroraxia.jpg",
        "category": [
            {
                "id": "66c156af-4395-4ef7-9eb7-b2319511f8a8",
                "theme_id": "59e90483-c715-48e8-bbca-7d726b6e19b6",
                "category_description": "Doces Formatura",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7701b211-70ac-49dc-befc-6857772c4395",
                "path": "product\/Doces_de_Formatura_Quiroraxia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "8a286f93-c978-4ec8-bf64-486fa69a5bca",
        "product_description": "Doces Decorados Formatura de Direito",
        "product_details": "Doces Decorados Formatura de Direito",
        "product_unique_name": "doces-decorados-formatura-de-direito",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Formatura_Direito.jpg",
        "category": [
            {
                "id": "66c156af-4395-4ef7-9eb7-b2319511f8a8",
                "theme_id": "59e90483-c715-48e8-bbca-7d726b6e19b6",
                "category_description": "Doces Formatura",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f7f3f82a-f34b-4341-927b-a7a8ba73ed1f",
                "path": "product\/Doces_Decorados_Formatura_Direito.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "dc64dfcb-3b59-4848-95ac-177577258462",
        "product_description": "Bolo Decorado Lego ",
        "product_details": "Bolo Decorado Lego ",
        "product_unique_name": "bolo-decorado-lego",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Lego_bolo_arthur_6.jpg",
        "category": [
            {
                "id": "0c49b0d4-5a40-440c-8198-84f499e861fc",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lego",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7e306f30-6a72-4ea7-9292-be3cc3f2fed4",
                "path": "product\/Lego_bolo_arthur_6.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "b0c6468b-50fd-448f-af12-64244b69411a",
        "product_description": "Cupcakes Decorados Lego",
        "product_details": "Cupcakes Decorados Lego",
        "product_unique_name": "cupcakes-decorados-lego",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": true,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/lego_cups_arthur_6.jpg",
        "category": [
            {
                "id": "0c49b0d4-5a40-440c-8198-84f499e861fc",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lego",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d094bc42-212a-4199-98e1-756f2ce82e88",
                "path": "product\/lego_cups_arthur_6.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "371afe34-d699-425a-abc1-ee965f5b6487",
        "product_description": "Doces Decorados Lego ",
        "product_details": "Doces Decorados Lego ",
        "product_unique_name": "doces-decorados-lego",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/lego_doces_arthur_6.jpg",
        "category": [
            {
                "id": "0c49b0d4-5a40-440c-8198-84f499e861fc",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lego",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "86547897-56cb-4137-b2da-49dbea84b269",
                "path": "product\/lego_doces_arthur_6.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "d33797b8-aa94-48b5-831a-e04d2fad8a23",
        "product_description": "Bolo Decorado Lego Ninjago",
        "product_details": "Bolo Decorado Lego Ninjago",
        "product_unique_name": "bolo-decorado-lego-ninjago",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Lego_Ninjago.jpg",
        "category": [
            {
                "id": "0c49b0d4-5a40-440c-8198-84f499e861fc",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lego",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1fa41778-47e1-4e63-8c70-29fa3be2ed02",
                "path": "product\/Bolo_Decorado_Lego_Ninjago.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "bbdcfd90-9467-45ea-bd76-2fdfa8029f27",
        "product_description": "Doces Decorados Lego Ninjago",
        "product_details": "Doces Decorados Lego Ninjago",
        "product_unique_name": "doces-decorados-lego-ninjago",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Lego_Ninjago.jpg",
        "category": [
            {
                "id": "0c49b0d4-5a40-440c-8198-84f499e861fc",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lego",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a78f3458-7e5b-4f77-b507-1b061b7e8e82",
                "path": "product\/Doces_Decorados_Lego_Ninjago.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "1eb34741-961a-4a1c-8c50-53693df06fca",
        "product_description": "Bolo Decorado Lego Ninjago (Opção 2)",
        "product_details": "Bolo Decorado Lego Ninjago (Opção 2)",
        "product_unique_name": "bolo-decorado-lego-ninjago-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Lego_Ninjago.jpg",
        "category": [
            {
                "id": "0c49b0d4-5a40-440c-8198-84f499e861fc",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lego",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e2abe3c6-0bf4-4c3d-8396-206fa6f53177",
                "path": "product\/Bolo_Lego_Ninjago.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "91682812-1233-4da8-bda8-5764371be191",
        "product_description": "Cupcakes Decorados Lego Ninjago",
        "product_details": "Cupcakes Decorados Lego Ninjago",
        "product_unique_name": "cupcakes-decorados-lego-ninjago",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Lego_Ninjago.jpg",
        "category": [
            {
                "id": "0c49b0d4-5a40-440c-8198-84f499e861fc",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lego",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "44df2332-c91d-4f35-b485-766ddb33bf62",
                "path": "product\/Cupcakes_Decorados_Lego_Ninjago.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "bd191f3b-4ee0-4816-845d-e0fd0835c5c4",
        "product_description": "Bolo Decorado Jardim Encantado",
        "product_details": "Bolo Decorado Jardim Encantado",
        "product_unique_name": "bolo-decorado-jardim-encantado",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": true,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Cecília.jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3a756ef8-1fb7-49a3-a45a-857dbbcb8636",
                "path": "product\/Bolo_Cecília.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "c034fc35-4392-4412-b697-833c52218eca",
        "product_description": "Cones Trufados de brigadeiro - Jardim Encantado",
        "product_details": "Cones Trufados de brigadeiro - Jardim Encantado",
        "product_unique_name": "cones-trufados-de-brigadeiro-jardim-encantado",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Cones_trufados_de_brigadeiro_cecília.jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1c5ac584-ee51-413c-9b69-c3764c9b4051",
                "path": "product\/Cones_trufados_de_brigadeiro_cecília.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "8eca29b9-6bfa-48a4-82c1-99161fc150f7",
        "product_description": "Fudges - Jardim Encantado",
        "product_details": "Fudges - Jardim Encantado",
        "product_unique_name": "fudges-jardim-encantado",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Fudges_Cecília.jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f9e3f3b8-52e4-41c8-a070-b3fc57239b2e",
                "path": "product\/Fudges_Cecília.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "086fe186-e64a-42ed-ae8a-cc49ba9369c1",
        "product_description": "Doces Decorados Jardim Encantado",
        "product_details": "Doces Decorados Jardim Encantado",
        "product_unique_name": "doces-decorados-jardim-encantado",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": true,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Doces_Cecília.jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1971f4dd-c1ed-412b-ac19-723dcc0a0469",
                "path": "product\/Doces_Cecília.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "2a2ea137-e330-4259-b874-a4eec925df0e",
        "product_description": "Bolo Decorado Jardim Encantado (opção 2)",
        "product_details": "Bolo Decorado Jardim Encantado (opção 2)",
        "product_unique_name": "bolo-decorado-jardim-encantado-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Jardim_Ecantado_(opção_2).jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1d83209c-ec5b-4804-b328-f8299d78435e",
                "path": "product\/Bolo_Jardim_Ecantado_(opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "43b88286-039e-4b12-8724-f2ad94d6cfb4",
        "product_description": "Bolo Decorado Jardim Encantado (opção 3)",
        "product_details": "Bolo Decorado Jardim Encantado (opção 3)",
        "product_unique_name": "bolo-decorado-jardim-encantado-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Jardim_Ecantado.jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ed74fdb5-e23f-44e0-8a6b-3c98f3c940de",
                "path": "product\/Bolo_Jardim_Ecantado.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "0e57a6b7-7438-474d-8f72-e2211bfae808",
        "product_description": "Cupcakes Decorados Jardim Encantado",
        "product_details": "Cupcakes Decorados Jardim Encantado",
        "product_unique_name": "cupcakes-decorados-jardim-encantado",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Cupcake_Jardim_Encantado.jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "481a9a44-9c08-4c80-bb31-ec35f0848b38",
                "path": "product\/Cupcake_Jardim_Encantado.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "5f3c1103-0ede-41bc-b565-928b44a44434",
        "product_description": "Doces Decorados Jardim Encantado (opção 2)",
        "product_details": "Doces Decorados Jardim Encantado (opção 2)",
        "product_unique_name": "doces-decorados-jardim-encantado-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Jardim_Encantado.jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2df652e0-e960-4352-a6f0-7f3d1dd43ccf",
                "path": "product\/Doces_Decorados_Jardim_Encantado.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "08eba98e-c9e2-4b26-8b61-b9e0a96924c8",
        "product_description": "Bolo Decorado Jardim Encantado (Opção 4)",
        "product_details": "Bolo Decorado Jardim Encantado (Opção 4)",
        "product_unique_name": "bolo-decorado-jardim-encantado-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Jardim_Encantado_(Opção_2).jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bfe3af84-217e-468c-857c-d8482da7e8e6",
                "path": "product\/Bolo_Jardim_Encantado_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "b83e40e8-acdb-40a6-a925-b10cd21278b0",
        "product_description": "Doces Decorados Jardim Encantado (Opção 3)",
        "product_details": "Doces Decorados Jardim Encantado (Opção 3)",
        "product_unique_name": "doces-decorados-jardim-encantado-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Jardim_Encantado_opção_2.jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2e84521f-50e1-4736-b154-5cb75049f1de",
                "path": "product\/Doces_Decorados_Jardim_Encantado_opção_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "213b25fb-a710-466d-bfc8-930eb62681e5",
        "product_description": "Cupcakes Decorados Jardim Encantado (Opção 2)",
        "product_details": "Cupcakes Decorados Jardim Encantado (Opção 2)",
        "product_unique_name": "cupcakes-decorados-jardim-encantado-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Jardim_Encatado_(Opção_2).jpg",
        "category": [
            {
                "id": "ea70d856-3359-4c26-866d-d29f35f1587f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jardim Encantado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5361aeac-0376-4d1b-bdee-489a17c7d87b",
                "path": "product\/Cupcakes_Jardim_Encatado_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "3c96b051-4f40-4e60-83f7-14f8d6148b82",
        "product_description": "Bolo Decorado Heróis (Homem Aranha e Mulher Gato) ",
        "product_details": "Bolo Decorado Heróis (Homem Aranha e Mulher Gato) ",
        "product_unique_name": "bolo-decorado-herois-homem-aranha-e-mulher-gato",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": true,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_herois_homem_aranha_e_mulher_gato.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1df82e90-94ac-4b13-82ec-2eaff430e46f",
                "path": "product\/Bolo_herois_homem_aranha_e_mulher_gato.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "3715787d-3767-4cfe-bab0-7cc24a52e517",
        "product_description": "Bolo Decorado Super Heróis ",
        "product_details": "Bolo Decorado Super Heróis ",
        "product_unique_name": "bolo-decorado-super-herois",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_super_herois.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "abbe4f39-7549-4315-8566-a0df8e393c5b",
                "path": "product\/Bolo_super_herois.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "b51c2ba3-cb4e-424e-93dd-a9394cb41fc4",
        "product_description": "Cupcakes Decorados Super Heróis",
        "product_details": "Cupcakes Decorados Super Heróis",
        "product_unique_name": "cupcakes-decorados-super-herois",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Super_Herois.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4cc7310d-02f2-427a-9497-8e2452f9ba6a",
                "path": "product\/Cupcakes_Super_Herois.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "acc5ef99-152a-4473-8e69-5b4e924f4193",
        "product_description": "Bolo Decorado Arlequina",
        "product_details": "Bolo Decorado Arlequina",
        "product_unique_name": "bolo-decorado-arlequina",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": true,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Alerquina.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cfea6fe9-f2b0-47f5-909a-0df821f60197",
                "path": "product\/Bolo_Alerquina.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "58c302ee-bb43-4dde-b3ee-fe72386c5f01",
        "product_description": "Bolo Decorado Hulk",
        "product_details": "Bolo Decorado Hulk",
        "product_unique_name": "bolo-decorado-hulk",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Hulk.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "050cc4dd-a6ab-4978-96ee-61f3da22cc98",
                "path": "product\/Bolo_Hulk.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "ac01e7d7-0f2f-4d30-96c7-1bba20b1759d",
        "product_description": "Bolo Decorado Homem Aranha",
        "product_details": "Bolo Decorado Homem Aranha",
        "product_unique_name": "bolo-decorado-homem-aranha",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Homem_Aranha.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3856c7ed-3402-4697-9812-9761f465f6ed",
                "path": "product\/Bolo_Homem_Aranha.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "44364811-9d96-4383-b230-60c17a911630",
        "product_description": "Doces Decorados Homem Aranha",
        "product_details": "Doces Decorados Homem Aranha",
        "product_unique_name": "doces-decorados-homem-aranha",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Homem_Aranha.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b8cb342f-6ef6-4443-af8c-23147dc55830",
                "path": "product\/Doces_Decorados_Homem_Aranha.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "3ae8e292-330f-4eb4-b0d2-fab94a05d8d5",
        "product_description": "Bolo Decorado Super Heróis (Opção 2)",
        "product_details": "Bolo Decorado Super Heróis (Opção 2)",
        "product_unique_name": "bolo-decorado-super-herois-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Super_Herois.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "005dddd0-d9a2-4c28-9f22-8fd405559bac",
                "path": "product\/Bolo_Decorado_Super_Herois.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "c9772541-6aff-467b-a5cb-c68edbba0ad2",
        "product_description": "Cupcakes Decorados Super Heróis (Opção 2)",
        "product_details": "Cupcakes Decorados Super Heróis (Opção 2)",
        "product_unique_name": "cupcakes-decorados-super-herois-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Super_Herois.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "33a432a1-a690-4063-a7b1-e694affaeaf9",
                "path": "product\/Cupcakes_Decorados_Super_Herois.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "0a72949b-9482-437a-8574-9e3b5d8691fd",
        "product_description": "Cupcakes Decorados Super Heróis Marvel",
        "product_details": "Cupcakes Decorados Super Heróis Marvel",
        "product_unique_name": "cupcakes-decorados-super-herois-marvel",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Super_Herois_Marvel.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9410308c-f0b1-40a7-9e74-42564a5276c4",
                "path": "product\/Cupcakes_Decorados_Super_Herois_Marvel.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "338b29fa-757e-4d9f-b53b-679a1c752495",
        "product_description": "Doces Decorados Super Heróis Marvel",
        "product_details": "Doces Decorados Super Heróis Marvel",
        "product_unique_name": "doces-decorados-super-herois-marvel",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Herois_Marvel.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6a094c9a-2457-4a4e-84cc-250a589ab725",
                "path": "product\/Doces_Decorados_Herois_Marvel.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "55d4d2d9-a247-4484-af21-a1b1ad03f486",
        "product_description": "Bolo Decorado Super Heróis Vingadores",
        "product_details": "Bolo Decorado Super Heróis Vingadores",
        "product_unique_name": "bolo-decorado-super-herois-vingadores",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Super_Herois_Vingadores.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6f37397b-e26f-44f6-8b4d-a8938ade89ce",
                "path": "product\/Bolo_Super_Herois_Vingadores.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "52dfbd4f-16b0-4bc2-92eb-f53e3769c125",
        "product_description": "Bolo Decorado Super Heróis (Opção 3)",
        "product_details": "Bolo Decorado Super Heróis (Opção 3)",
        "product_unique_name": "bolo-decorado-super-herois-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_HEROIS.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "48300a4c-82c9-4cdb-b781-f0516b68e00d",
                "path": "product\/Bolo_Decorado_HEROIS.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "0f062374-4807-4045-86c2-ca289db9dfa7",
        "product_description": "Bolo Decorado Super Heróis (Opção 4)",
        "product_details": "Bolo Decorado Super Heróis (Opção 4)",
        "product_unique_name": "bolo-decorado-super-herois-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-01-26T14:23:33.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Super_Herois_2.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "fefcdb9a-ab8f-4b56-adaa-596e5e9a7de2",
                "path": "product\/Bolo_Decorado_Super_Herois_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "76ef32bb-a38a-4814-af05-e6c42e4bab0b",
        "product_description": "Bolo Decorado Super Heróis (Opção 5)",
        "product_details": "Bolo Decorado Super Heróis (Opção 5)",
        "product_unique_name": "bolo-decorado-super-herois-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Super_Herois_3.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "89eb9c09-94e3-40ea-a972-db818e242fab",
                "path": "product\/Bolo_Decorado_Super_Herois_3.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "981c725e-767f-4a64-aed5-214966081812",
        "product_description": "Doces Decorados Super Heróis ",
        "product_details": "Doces Decorados Super Heróis ",
        "product_unique_name": "doces-decorados-super-herois",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Super_Herois_2.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3039b8d4-6204-424a-b9ff-cf9b4daf1c77",
                "path": "product\/Doces_Decorados_Super_Herois_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "7ea0f66b-ef33-4844-b9c7-9b1ddbd1bb6a",
        "product_description": "Cupcakes Decorados Super Heróis (Opção 3)",
        "product_details": "Cupcakes Decorados Super Heróis (Opção 3)",
        "product_unique_name": "cupcakes-decorados-super-herois-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Marvel.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8a463c2d-9905-4a9d-9954-3f2e7e7f2cee",
                "path": "product\/Cupcakes_Decorados_Marvel.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "dbc4b979-846f-4a76-9b96-2152122970df",
        "product_description": "Bolo Decorado Super Heróis (Opção 6)",
        "product_details": "Bolo Decorado Super Heróis (Opção 6)",
        "product_unique_name": "bolo-decorado-super-herois-opcao-6",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Super_Herois_2.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e5f33faa-6119-42e1-b222-cc31d99dc758",
                "path": "product\/Bolo_Super_Herois_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "e70f95cf-ae44-4eb9-a579-6ff7c0fb78af",
        "product_description": "Cupcakes Decorados Super Heróis (Opção 4)",
        "product_details": "Cupcakes Decorados Super Heróis (Opção 4)",
        "product_unique_name": "cupcakes-decorados-super-herois-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Super_Herois_(Opção_2).jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0d93cd8a-5a6e-4aeb-ba75-b96f2ab9e2ba",
                "path": "product\/Cupcakes_Decorados_Super_Herois_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "0a827d21-dacd-4099-b647-45d9bd32cd07",
        "product_description": "Pirulitos Decorados Super Heróis",
        "product_details": "Pirulitos Decorados Super Heróis",
        "product_unique_name": "pirulitos-decorados-super-herois",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Super_Heróis.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1eedb034-187e-44e1-9ec2-189a325083d9",
                "path": "product\/Pirulitos_Decorados_Super_Heróis.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "de59bdfa-75ce-4890-b768-942596ef6c55",
        "product_description": "Bolo Decorado Super Heróis (Opção 7)",
        "product_details": "Bolo Decorado Super Heróis (Opção 7)",
        "product_unique_name": "bolo-decorado-super-herois-opcao-7",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Super_Herois_4.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4c1d0e08-e00d-4ed5-9393-f006277416a0",
                "path": "product\/Bolo_Decorado_Super_Herois_4.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "7374be14-1d6f-4231-9b50-01b2df2e7e93",
        "product_description": "Doces Decorados Super Heróis (Opção 3)",
        "product_details": "Doces Decorados Super Heróis (Opção 3)",
        "product_unique_name": "doces-decorados-super-herois-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Super_Herois.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8b5fb677-0674-4461-85a9-0f026b837713",
                "path": "product\/Doces_Decorados_Super_Herois.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "3b306fe1-e6a5-4415-969e-81c358c7b43c",
        "product_description": "Cupcakes Decorados Super Heróis (Opções 3)",
        "product_details": "Cupcakes Decorados Super Heróis (Opções 3)",
        "product_unique_name": "cupcakes-decorados-super-herois-opcoes-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Super_Herois_(Opção_3).jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b6abb65d-36ae-4454-bbeb-2fbf28828ba8",
                "path": "product\/Cupcakes_Decorados_Super_Herois_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "13239c21-f228-43d0-b19b-196011bde701",
        "product_description": "Bolo Decorado Homem Aranha (Opção 2)",
        "product_details": "Bolo Decorado Homem Aranha (Opção 2)",
        "product_unique_name": "bolo-decorado-homem-aranha-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Homem_Aranha.jpg",
        "category": [
            {
                "id": "14c47744-6c82-495d-80cf-5aeb80c1127b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Heróis",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "08ed7349-ed68-4d0b-b928-6cf62d3fffb7",
                "path": "product\/Bolo_Decorado_Homem_Aranha.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "a8fc361f-49e6-48bd-a628-ad6559a8a533",
        "product_description": "Bolo Decorado Among Us ",
        "product_details": "Bolo Decorado Among Us ",
        "product_unique_name": "bolo-decorado-among-us",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/bolo_among_us_pedro.jpg",
        "category": [
            {
                "id": "dbea5b19-edd9-4419-8b58-465e8244b7c8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Among Us",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "74c45d5b-8e43-4a78-b5a0-79aee917f6ae",
                "path": "product\/bolo_among_us_pedro.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "d1a11a1f-a7f5-43df-8e12-f989a0eedf56",
        "product_description": "Doces Decorados Among Us ",
        "product_details": "Doces Decorados Among Us ",
        "product_unique_name": "doces-decorados-among-us",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/doces_among_us.jpg",
        "category": [
            {
                "id": "dbea5b19-edd9-4419-8b58-465e8244b7c8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Among Us",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "92fe52c8-0f0c-499a-bd10-cbd1b203a28d",
                "path": "product\/doces_among_us.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "3154b617-872f-4710-a846-9e1f0dc28746",
        "product_description": "Bolo Decorado Among Us (Opção 2)",
        "product_details": "Bolo Decorado Among Us (Opção 2)",
        "product_unique_name": "bolo-decorado-among-us-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Among_Us_(Opção_2).jpg",
        "category": [
            {
                "id": "dbea5b19-edd9-4419-8b58-465e8244b7c8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Among Us",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "68d239c2-51c6-462c-803d-8c30da35c14b",
                "path": "product\/Bolo_Decorado_Among_Us_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "4898856e-810e-4d3f-be11-e445057dc890",
        "product_description": "Doces Decorados Among Us(Opção 2)",
        "product_details": "Doces Decorados Among Us(Opção 2)",
        "product_unique_name": "doces-decorados-among-usopcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Among_Us_(Opção_2).jpg",
        "category": [
            {
                "id": "dbea5b19-edd9-4419-8b58-465e8244b7c8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Among Us",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6bbab6a2-3dba-4375-965a-2b48967a834d",
                "path": "product\/Doces_Decorados_Among_Us_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "5cc93d85-f81d-4c3b-bef4-7f017b09bc86",
        "product_description": "Bolo Decorado Dunkin´ Donuts",
        "product_details": "Bolo Decorado Dunkin´ Donuts",
        "product_unique_name": "bolo-decorado-dunkin-donuts",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Dunkin´_Donuts_isabelle.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "081a8b55-8d6b-4309-b45e-c347bc893787",
                "path": "product\/Dunkin´_Donuts_isabelle.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "7e721dc2-0db5-466d-be8a-3ff231ae0218",
        "product_description": "Bolo Decorado Pirata",
        "product_details": "Bolo Decorado Pirata",
        "product_unique_name": "bolo-decorado-pirata",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Pirata_alexandre.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cad28c8e-7422-4262-b6e3-8343db73e338",
                "path": "product\/Bolo_Pirata_alexandre.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "7ee648d0-2850-42c0-87b0-ef0f42f4b445",
        "product_description": "Cupcake Decorado pirata ",
        "product_details": "Cupcake Decorado pirata ",
        "product_unique_name": "cupcake-decorado-pirata",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcake_pirata_alexandre.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "eb7c807d-4ce8-4441-9bfa-0d288aa45f6e",
                "path": "product\/Cupcake_pirata_alexandre.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "073afe23-e2d9-45e5-83fb-dc72e6c01362",
        "product_description": "Doces Decorados Pirata ",
        "product_details": "Doces Decorados Pirata ",
        "product_unique_name": "doces-decorados-pirata",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Docinhos_pirata_alexandre.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ec0eb5f3-4d6b-40b1-be83-e2c9545bb5a9",
                "path": "product\/Docinhos_pirata_alexandre.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "80fac1fb-1170-4ab5-846c-19bd6d280029",
        "product_description": "Bolo Decorado Maquiagens",
        "product_details": "Bolo Decorado Maquiagens",
        "product_unique_name": "bolo-decorado-maquiagens",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Maquiagem.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d3858811-dfe4-4512-839c-805c100f71cd",
                "path": "product\/Bolo_Maquiagem.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "570500bb-372a-4e5f-867f-1ef02d4af9a7",
        "product_description": "Cupcakes Decorados Maquiagens",
        "product_details": "Cupcakes Decorados Maquiagens",
        "product_unique_name": "cupcakes-decorados-maquiagens",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/cupcakes_maquiagem.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7cfaf7df-2e1e-4ef6-aa99-a198d87ec58d",
                "path": "product\/cupcakes_maquiagem.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "39914e39-92de-48a5-a844-360a0466a62b",
        "product_description": "Bolo Decorado Frutas Smile ",
        "product_details": "Bolo Decorado Frutas Smile ",
        "product_unique_name": "bolo-decorado-frutas-smile",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_frutas_smile.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c04c0f8a-f1e5-473f-beb6-b2ff225a3692",
                "path": "product\/Bolo_frutas_smile.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "ef5711d8-b9f7-4680-aa12-1970707abcfc",
        "product_description": "Cupcakes Decorados Frutas Smile",
        "product_details": "Cupcakes Decorados Frutas Smile",
        "product_unique_name": "cupcakes-decorados-frutas-smile",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_frutas_smile.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ca9aea30-616b-466b-8c9f-01deb446e62f",
                "path": "product\/Cupcakes_frutas_smile.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "bd5fcb28-e935-4e8d-b7a0-949b0920c506",
        "product_description": "Doces Decorados Frutas Smile",
        "product_details": "Doces Decorados Frutas Smile",
        "product_unique_name": "doces-decorados-frutas-smile",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Doces_frutas_smile.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3cd37e5b-b54e-4fd9-94e6-08bda84be7ab",
                "path": "product\/Doces_frutas_smile.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "d2d56250-1e59-49c6-b8a5-cc63bdfb889e",
        "product_description": "Bolo Decorado Fundo do Mar",
        "product_details": "Bolo Decorado Fundo do Mar",
        "product_unique_name": "bolo-decorado-fundo-do-mar",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Fundo_do_mar_(opção_2).jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "231cb3fd-2fbc-402a-aa85-52ee916a7810",
                "path": "product\/Bolo_Decorado_Fundo_do_mar_(opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "0ca2df34-1179-43ab-9d76-ab235bd98128",
        "product_description": "Bolo Decorado Menino e Neve ",
        "product_details": "Bolo Decorado Menino e Neve ",
        "product_unique_name": "bolo-decorado-menino-e-neve",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Menino_Neve.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2645006c-67df-4a58-8ea4-8bfa8b31ad76",
                "path": "product\/Bolo_Menino_Neve.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "678fa654-5e3b-4239-a1a7-3e1feca85af5",
        "product_description": "Bolo Decorado Nutella ",
        "product_details": "Bolo Decorado Nutella ",
        "product_unique_name": "bolo-decorado-nutella",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Nutella.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ff190aa7-d986-4e49-9bf3-b6287ae6ca95",
                "path": "product\/Bolo_Nutella.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "46ea28f3-fe19-497b-82ba-efcac76f41ce",
        "product_description": "Cupcakes Decorados Nutella",
        "product_details": "Cupcakes Decorados Nutella",
        "product_unique_name": "cupcakes-decorados-nutella",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/cupcakes_nutella.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8405fe8f-5007-40e9-b176-20c6d95dcc43",
                "path": "product\/cupcakes_nutella.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "5ab6081d-b3b9-459a-8cdc-1d8e4185f6f7",
        "product_description": "Bolo Decorado Astronauta",
        "product_details": "Bolo Decorado Astronauta",
        "product_unique_name": "bolo-decorado-astronauta",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Astronauta.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "77a298d5-fff9-4691-80f5-34872a792702",
                "path": "product\/Bolo_Decorado_Astronauta.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "831265c1-5534-416a-92a5-64c401c4d915",
        "product_description": "Cupcakes Decorados Astronauta ",
        "product_details": "Cupcakes Decorados Astronauta ",
        "product_unique_name": "cupcakes-decorados-astronauta",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Astronauta.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2ba170e9-b91f-4c8e-9982-1e00537c4002",
                "path": "product\/Cupcakes_Astronauta.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "7a1218d0-a746-47ed-94d8-ee9d958f0643",
        "product_description": "Cupcakes Decorados Rosas ",
        "product_details": "Cupcakes Decorados Rosas ",
        "product_unique_name": "cupcakes-decorados-rosas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Rosas.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "11b0c5c7-f7ce-44c9-8df1-a7ab7e253100",
                "path": "product\/Cupcakes_Rosas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "6633d7c5-d469-4f2e-bba8-25f2cc130092",
        "product_description": "Bolo Decorado Chile e Alpacas",
        "product_details": "Bolo Decorado Chile e Alpacas",
        "product_unique_name": "bolo-decorado-chile-e-alpacas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Chile_Alpacas.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c9dd246a-82e8-4c89-a6a1-560e1746a2b8",
                "path": "product\/Bolo_Chile_Alpacas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "de9834e8-bd8e-468c-a2e5-f417f06d5ab6",
        "product_description": "Bolo Decorado Carrinhos",
        "product_details": "Bolo Decorado Carrinhos",
        "product_unique_name": "bolo-decorado-carrinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Carrinhos.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "66d17311-20c6-4799-bf97-b4edfe38b2de",
                "path": "product\/Bolo_Carrinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "b15f91c6-c757-4cbf-b4f7-2a1053927e4c",
        "product_description": "Bolo Decorado Rosas",
        "product_details": "Bolo Decorado Rosas",
        "product_unique_name": "bolo-decorado-rosas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Flores.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "93d8eb52-ca37-446a-aa20-0ea011c34bb9",
                "path": "product\/Bolo_Flores.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "f8ca707a-d9e4-4ead-bdf7-d3c1562c10d6",
        "product_description": "Bolo Decorado Corações",
        "product_details": "Bolo Decorado Corações",
        "product_unique_name": "bolo-decorado-coracoes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Corações.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c38cdce5-8a66-4407-9c08-75d370866101",
                "path": "product\/Bolo_Corações.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "dd9220f7-9d53-40b7-8a66-c078e663f5e1",
        "product_description": "Bolo Decorado Bailarina",
        "product_details": "Bolo Decorado Bailarina",
        "product_unique_name": "bolo-decorado-bailarina",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bailarina.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a7c28a75-8a18-4961-8338-3e5ca70eaef7",
                "path": "product\/Bolo_Bailarina.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "7cf82e6b-1611-4d1c-a64c-b664f9b17300",
        "product_description": "Bolo Decorado Harry Styles e Dua Lipa",
        "product_details": "Bolo Decorado Harry Styles e Dua Lipa",
        "product_unique_name": "bolo-decorado-harry-styles-e-dua-lipa",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Harry_Styles_e_Dua_Lipa.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e3d0bdb6-97df-41d5-95e6-10c460621ced",
                "path": "product\/Bolo_Harry_Styles_e_Dua_Lipa.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "5e01803c-3b12-43a9-9484-977608fc3605",
        "product_description": "Bolo Decorado Sereia ",
        "product_details": "Bolo Decorado Sereia ",
        "product_unique_name": "bolo-decorado-sereia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Sereia.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "fb0d6a2b-0966-459a-8564-e4b99f20dc5b",
                "path": "product\/Bolo_Decorado_Sereia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "0edb4c7e-7649-4f10-870c-9a0b3412da31",
        "product_description": "Bolo Decorado Praia ",
        "product_details": "Bolo Decorado Praia ",
        "product_unique_name": "bolo-decorado-praia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Praia.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4aca8c67-4799-4c0c-8926-a00fc06d9742",
                "path": "product\/Bolo_Praia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "e1e4aa7b-2eb9-478e-96e3-4b9f97683c65",
        "product_description": "Bolo Decorado Frutas",
        "product_details": "Bolo Decorado Frutas",
        "product_unique_name": "bolo-decorado-frutas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Frutas.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b1b3d79c-0b29-4855-aae6-230c86a8b43c",
                "path": "product\/Bolo_Frutas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "5dd64300-983f-45b7-8f62-16a66d98a8f7",
        "product_description": "Bolo Decorado Nutella (Opção 2)",
        "product_details": "Bolo Decorado Nutella (Opção 2)",
        "product_unique_name": "bolo-decorado-nutella-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Nutella_650g.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9f473aa9-0d83-4f24-828c-abae23e2f559",
                "path": "product\/Bolo_Decorado_Nutella_650g.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "c9349834-b35c-415e-8e1e-579b6c6809b9",
        "product_description": "Bolo Decorado Avião",
        "product_details": "Bolo Decorado Avião",
        "product_unique_name": "bolo-decorado-aviao",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Avião.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2960f73b-9e1e-4517-84a2-ddf909fcc330",
                "path": "product\/Bolo_Decorado_Avião.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "81775d58-00c8-44d3-935e-bd12319b2a95",
        "product_description": "Cupcakes Decorados Avião",
        "product_details": "Cupcakes Decorados Avião",
        "product_unique_name": "cupcakes-decorados-aviao",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Avião.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e3fa1182-0d04-4074-8cdb-a84ae85db736",
                "path": "product\/Cupcakes_Decorados_Avião.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "72762b9c-fce9-4764-a14d-ddad5ef437c2",
        "product_description": "Bolo Decorado Doceria ",
        "product_details": "Bolo Decorado Doceria ",
        "product_unique_name": "bolo-decorado-doceria",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Doceria.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "77cfa7e2-a4c8-4e74-8892-314a29a50227",
                "path": "product\/Bolo_Decorado_Doceria.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "52b20fb6-5763-403e-b04c-827ddaa347b0",
        "product_description": "Bolo Decorado Astronauta (Opção 2)",
        "product_details": "Bolo Decorado Astronauta (Opção 2)",
        "product_unique_name": "bolo-decorado-astronauta-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Astronauta_(Opção_2).jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2def0b95-4f88-442c-8965-6e9021f9f66a",
                "path": "product\/Bolo_Decorado_Astronauta_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "f482430b-4cf4-4e5c-a5b8-00abb51bdd4c",
        "product_description": "Bolo Decorado Ursinho e Balões",
        "product_details": "Bolo Decorado Ursinho e Balões",
        "product_unique_name": "bolo-decorado-ursinho-e-baloes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Ursinho_e_Balões.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "88124fb4-904c-4f9b-a686-abafcccf1fa1",
                "path": "product\/Bolo_Decorado_Ursinho_e_Balões.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "f4c4651b-30f8-48f6-b4dc-c6e73099af35",
        "product_description": "Cupcakes Decorados Ursinhos e Balões",
        "product_details": "Cupcakes Decorados Ursinhos e Balões",
        "product_unique_name": "cupcakes-decorados-ursinhos-e-baloes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Ursinho_e_Balão.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "41dff638-e71a-42c6-9960-bce9c518a7fb",
                "path": "product\/Cupcakes_Decorados_Ursinho_e_Balão.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "76dd238f-4c0e-4e2b-ad99-c4a79521f8f2",
        "product_description": "Doces Decorados Ursinhos e Balões",
        "product_details": "Doces Decorados Ursinhos e Balões",
        "product_unique_name": "doces-decorados-ursinhos-e-baloes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Docinhos_Decorados_Ursinho_e_Balão.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4f6884d4-c12a-4225-a37e-0ab8fb928743",
                "path": "product\/Docinhos_Decorados_Ursinho_e_Balão.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "e04dfda0-a556-44a9-92b5-538d735f849b",
        "product_description": "Bolo Decorado Frutinhas Smile",
        "product_details": "Bolo Decorado Frutinhas Smile",
        "product_unique_name": "bolo-decorado-frutinhas-smile",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Frutinhas_Smile.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "39b4dd9d-2e5b-475c-aca1-48fb99896f91",
                "path": "product\/Bolo_Decorado_Frutinhas_Smile.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "8658b217-9304-41ab-b023-9499d03b0cef",
        "product_description": "Doces Decorados Frutinhas Smile",
        "product_details": "Doces Decorados Frutinhas Smile",
        "product_unique_name": "doces-decorados-frutinhas-smile",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Frutas_Smile.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7717de54-d3eb-454b-82c0-aaeaeef58e28",
                "path": "product\/Doces_Decorados_Frutas_Smile.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "070cbfc3-8646-41d7-8a70-460860d66523",
        "product_description": "Bolo Decorado Doceria (Opção 2)",
        "product_details": "Bolo Decorado Doceria (Opção 2)",
        "product_unique_name": "bolo-decorado-doceria-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Doceria_(Opção_2).jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ea801e2d-4dc3-467f-9870-cba988ca05d5",
                "path": "product\/Bolo_Decorado_Doceria_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "9f07fc1e-3046-4d43-b2f4-7e9aae667de4",
        "product_description": "Cupcakes Decorados Doceria",
        "product_details": "Cupcakes Decorados Doceria",
        "product_unique_name": "cupcakes-decorados-doceria",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Doceria.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e106a68a-106e-495f-b522-3d16bb10fb29",
                "path": "product\/Cupcakes_Decorados_Doceria.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "dcea9142-dc90-42d1-9e48-27b95ddac569",
        "product_description": "Pirulitos Decorados Doceria",
        "product_details": "Pirulitos Decorados Doceria",
        "product_unique_name": "pirulitos-decorados-doceria",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Doceria.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "38259c7f-a2fb-42f0-b2c6-bc07178f893e",
                "path": "product\/Pirulitos_Decorados_Doceria.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "5b66ba33-8378-4ee4-832d-1c7bdb84b213",
        "product_description": "Bolo Decorado O Pequeno e Poderoso Bheem",
        "product_details": "Bolo Decorado O Pequeno e Poderoso Bheem",
        "product_unique_name": "bolo-decorado-o-pequeno-e-poderoso-bheem",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_O_Pequeno_e_Poderoso_Bheem.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ffb8a410-8485-4c79-8d05-db9a7d8c202b",
                "path": "product\/Bolo_Decorado_O_Pequeno_e_Poderoso_Bheem.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "adf49abd-b2b2-42db-a377-4b48d225b39e",
        "product_description": "Cupcakes Decorados O Pequeno e Poderoso Bheem",
        "product_details": "Cupcakes Decorados O Pequeno e Poderoso Bheem",
        "product_unique_name": "cupcakes-decorados-o-pequeno-e-poderoso-bheem",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_O_Pequeno_e_Poderoso_Bheem.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a4cf4e28-def3-4b31-bb03-5271b696b6ca",
                "path": "product\/Cupcakes_Decorados_O_Pequeno_e_Poderoso_Bheem.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "c5dac054-ef26-4d31-bdc2-2a79fdb76302",
        "product_description": "Bolo Decorado Ursinho ",
        "product_details": "Bolo Decorado Ursinho ",
        "product_unique_name": "bolo-decorado-ursinho",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Ursinho.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "066d4119-0712-4cab-95e2-ee2b2062b34b",
                "path": "product\/Bolo_Decorado_Ursinho.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "a07283b2-6c96-43b0-8056-3c98177f672b",
        "product_description": "Cupcakes Decorados Ursinhos (Opção 3)",
        "product_details": "Cupcakes Decorados Ursinhos (Opção 3)",
        "product_unique_name": "cupcakes-decorados-ursinhos-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorado_Ursinho.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ba0a8069-2078-476a-bacc-645a335562e0",
                "path": "product\/Cupcakes_Decorado_Ursinho.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "51d968c3-6fad-4c9c-8688-f5d193a3efa7",
        "product_description": "Doces Decorados Ursinhos (Opção 2)",
        "product_details": "Doces Decorados Ursinhos (Opção 2)",
        "product_unique_name": "doces-decorados-ursinhos-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Ursinhos1.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ab67bdba-ba3f-422e-aa38-94f1f51ec298",
                "path": "product\/Doces_Decorados_Ursinhos1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "ae2dbb3b-be0c-44b3-8cce-77d8c399fa68",
        "product_description": "Pirulitos Decorados Bichinhos",
        "product_details": "Pirulitos Decorados Bichinhos",
        "product_unique_name": "pirulitos-decorados-bichinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Bichinhos.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "66ff2b53-1f9a-4387-bc88-8823af138882",
                "path": "product\/Pirulitos_Decorados_Bichinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "cb760c9b-c8ae-4dd7-910c-ca82d3329e09",
        "product_description": "Bolo Decorado Gaúcho",
        "product_details": "Bolo Decorado Gaúcho",
        "product_unique_name": "bolo-decorado-gaucho",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Gaúcho.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cd853228-c381-4a3f-8376-ad74763266ce",
                "path": "product\/Bolo_Decorado_Gaúcho.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "859132a3-f04b-4cc7-a861-80eb7b1df58c",
        "product_description": "Bolo Decorado Astronauta (Opção 3)",
        "product_details": "Bolo Decorado Astronauta (Opção 3)",
        "product_unique_name": "bolo-decorado-astronauta-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Astronauta_(Opção_3).jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9970957e-149e-4956-a768-c56d0195a275",
                "path": "product\/Bolo_Decorado_Astronauta_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "5b4820de-6da6-44cf-9321-05e88eb27752",
        "product_description": "Cupcakes Decorados Astronauta (Opção 2)",
        "product_details": "Cupcakes Decorados Astronauta (Opção 2)",
        "product_unique_name": "cupcakes-decorados-astronauta-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Astronauta_Opção_2__JPG.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1bcbd4ea-a455-41b8-a28c-68163684b1c6",
                "path": "product\/Cupcakes_Astronauta_Opção_2__JPG.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "d330e6f7-5843-4577-bc7d-88c8b585be5f",
        "product_description": "Doces Decorados Astronauta ",
        "product_details": "Doces Decorados Astronauta ",
        "product_unique_name": "doces-decorados-astronauta",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Astronauta.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "080b87ee-1343-4894-8b6b-2b7fe4d20838",
                "path": "product\/Doces_Decorados_Astronauta.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "69415cd2-f3f2-4a49-bfdd-a1af9515ac36",
        "product_description": "Bolo Decorado Astronauta (Opção 4)",
        "product_details": "Bolo Decorado Astronauta (Opção 4)",
        "product_unique_name": "bolo-decorado-astronauta-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Astronauta_(Opção_4).jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "04e35a23-409a-43ba-9810-275a5e5fbb96",
                "path": "product\/Bolo_Decorado_Astronauta_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "58e1b445-b51d-4813-883b-07cd0bd30ead",
        "product_description": "Cupcakes Astronauta Opção 3",
        "product_details": "Cupcakes Astronauta Opção 3",
        "product_unique_name": "cupcakes-astronauta-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Astronauta_Opção_3.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6340274b-b11d-47da-b9a7-d6afbb6c4ce6",
                "path": "product\/Cupcakes_Astronauta_Opção_3.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "8d24b338-bddc-4a9d-a718-4ceb8425b65b",
        "product_description": "Bolo Decorado Surf",
        "product_details": "Bolo Decorado Surf",
        "product_unique_name": "bolo-decorado-surf",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Surf.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f225a6d1-0732-4cf1-b54a-77568a31c855",
                "path": "product\/Bolo_Decorado_Surf.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:34.000000Z",
                "updated_at": "2023-01-26T14:23:34.000000Z"
            }
        ]
    },
    {
        "id": "7b732d3d-9c7c-44cf-b23d-ad08b962e8a9",
        "product_description": "Cupcakes Decorados Surf",
        "product_details": "Cupcakes Decorados Surf",
        "product_unique_name": "cupcakes-decorados-surf",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:34.000000Z",
        "updated_at": "2023-01-26T14:23:34.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Surf.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a831528a-9b4c-4d07-89b1-3b339e58e5ab",
                "path": "product\/Cupcakes_Decorados_Surf.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "2b12eefa-14af-424e-aab3-80623f4a45da",
        "product_description": "Doces Decorados Surf",
        "product_details": "Doces Decorados Surf",
        "product_unique_name": "doces-decorados-surf",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Surf.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1c144094-a4ea-48df-80ee-b3ffb4d6cd05",
                "path": "product\/Doces_Decorados_Surf.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "818089de-4db3-4b10-90c5-46536bb39869",
        "product_description": "Bolo Decorado Chef",
        "product_details": "Bolo Decorado Chef",
        "product_unique_name": "bolo-decorado-chef",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Personalizado_Chef.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6525b69b-d353-4577-ba78-ef2d8975a4bb",
                "path": "product\/Bolo_Personalizado_Chef.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "ad990fd3-aba7-4b9b-bf0c-fac4a90e171d",
        "product_description": "Bolo Decorado Menina e Balões",
        "product_details": "Bolo Decorado Menina e Balões",
        "product_unique_name": "bolo-decorado-menina-e-baloes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Menina_e_Balões.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "713d750c-3244-4833-a89e-5d0849ea9126",
                "path": "product\/Bolo_Decorado_Menina_e_Balões.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "777b9ec9-e1e3-40f6-9a1f-8ed271eeded5",
        "product_description": "Bolo Decorado Dinossauros e Carros ",
        "product_details": "Bolo Decorado Dinossauros e Carros ",
        "product_unique_name": "bolo-decorado-dinossauros-e-carros",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dinossauros_e_Carros.jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9f404712-2005-437a-86e2-92fa8b778aaa",
                "path": "product\/Bolo_Decorado_Dinossauros_e_Carros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "aebbdaf6-6922-4472-b965-756bd92bbd41",
        "product_description": "Bolo Decorado Astronauta (Opção 5)",
        "product_details": "Bolo Decorado Astronauta (Opção 5)",
        "product_unique_name": "bolo-decorado-astronauta-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Astronauta_(Opção_5).jpg",
        "category": [
            {
                "id": "3f217586-25f2-421c-b79b-0854946c6a71",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Personalizados",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b34d2d95-13df-484b-8bc1-c9decf1befba",
                "path": "product\/Bolo_Decorado_Astronauta_(Opção_5).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "f302915d-a2a9-463d-ba8e-f5935bffde00",
        "product_description": "Bolo Decorado Barbie ",
        "product_details": "Bolo Decorado Barbie ",
        "product_unique_name": "bolo-decorado-barbie",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/bolo_barbie.jpg",
        "category": [
            {
                "id": "1ddc6736-e005-4aaa-96b6-fe95223d2956",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Barbie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3fcbff36-b936-446b-893d-520b9dda6f2e",
                "path": "product\/bolo_barbie.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "1ea0df43-4cac-4ab1-ae9d-b31d6b7c5ebd",
        "product_description": "Doces Decorados Barbie",
        "product_details": "Doces Decorados Barbie",
        "product_unique_name": "doces-decorados-barbie",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Barbie.jpg",
        "category": [
            {
                "id": "1ddc6736-e005-4aaa-96b6-fe95223d2956",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Barbie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "14f58151-564b-4054-bc54-5441b61af489",
                "path": "product\/Doces_Decorados_Barbie.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "7de7ad3f-0b37-4035-ac47-3f2d77937385",
        "product_description": "Bolo Decorado Barbie (Opção 2)",
        "product_details": "Bolo Decorado Barbie (Opção 2)",
        "product_unique_name": "bolo-decorado-barbie-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Barbie.jpg",
        "category": [
            {
                "id": "1ddc6736-e005-4aaa-96b6-fe95223d2956",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Barbie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "311ad844-2294-47c8-ac1e-e3aca03bbfa0",
                "path": "product\/Bolo_Decorado_Barbie.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "0c44e446-f712-495e-89b7-df6bc239f2f0",
        "product_description": "Bolo Decorado Patrulha Canina ",
        "product_details": "Bolo Decorado Patrulha Canina ",
        "product_unique_name": "bolo-decorado-patrulha-canina",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Patrulha_Canina_(Opção_3).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3792871f-7609-47f2-a9de-87b0fb341b25",
                "path": "product\/Bolo_Decorado_Patrulha_Canina_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "67414253-87ab-459f-88e3-f6c93522d3fd",
        "product_description": "Cupcake Decorado Patrulha Canina",
        "product_details": "Cupcake Decorado Patrulha Canina",
        "product_unique_name": "cupcake-decorado-patrulha-canina",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Patrulha_Canina_(Opção_2).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4d8874a8-b78a-45bc-adae-3b3200cf8783",
                "path": "product\/Cupcakes_Decorados_Patrulha_Canina_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "57200980-01b2-4539-8e80-621831d28571",
        "product_description": "Bolo Decorado Patrulha Canina (Opção 3) ",
        "product_details": "Bolo Decorado Patrulha Canina (Opção 3) ",
        "product_unique_name": "bolo-decorado-patrulha-canina-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Patrulha_Canina_(Opção_3).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b456a198-fd20-44c9-b448-57d25220b07d",
                "path": "product\/Bolo_Decorado_Patrulha_Canina_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "17e0362d-6b9c-435d-a02c-bcf281558b9b",
        "product_description": "Bolo Decorado Patrulha Canina (Opção 4)",
        "product_details": "Bolo Decorado Patrulha Canina (Opção 4)",
        "product_unique_name": "bolo-decorado-patrulha-canina-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Patrulha_Canina_(Opção_4).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d8c866c0-e9c5-4675-b0dd-9bb80239a93b",
                "path": "product\/Bolo_Decorado_Patrulha_Canina_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "d1a857ee-f46d-4b6b-b59f-2c98022912e6",
        "product_description": "Bolo Decorado Patrulha Canina (Opção 5)",
        "product_details": "Bolo Decorado Patrulha Canina (Opção 5)",
        "product_unique_name": "bolo-decorado-patrulha-canina-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Patrulha_Canina_(Opção_5).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "de2d0d38-1236-46a4-b812-fdfb787fe573",
                "path": "product\/Bolo_Decorado_Patrulha_Canina_(Opção_5).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "bde96201-58e0-4ff8-a823-7c8408ebc1df",
        "product_description": "Bolo Decorado Patrulha Canina (Opção 6)",
        "product_details": "Bolo Decorado Patrulha Canina (Opção 6)",
        "product_unique_name": "bolo-decorado-patrulha-canina-opcao-6",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Patrulha_Canina_(Opção_6).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "53c629b7-ab9c-489b-9a69-6280b1d76360",
                "path": "product\/Bolo_Decorado_Patrulha_Canina_(Opção_6).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "1bd56b73-fa96-4d3c-bdb2-e53207a1678e",
        "product_description": "Bolo Decorado Patrulha Canina (Opção 7)",
        "product_details": "Bolo Decorado Patrulha Canina (Opção 7)",
        "product_unique_name": "bolo-decorado-patrulha-canina-opcao-7",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Patrulha_Canina_(Opção_7).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8d758db0-1660-43f5-9da0-dfef34fb6ee7",
                "path": "product\/Bolo_Decorado_Patrulha_Canina_(Opção_7).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "61347934-ed66-4d7e-b1a6-a3c5fef1de68",
        "product_description": "Bolo Decorado Patrulha Canina (Opção 8)",
        "product_details": "Bolo Decorado Patrulha Canina (Opção 8)",
        "product_unique_name": "bolo-decorado-patrulha-canina-opcao-8",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Patrulha_Canina_(Opção_8).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6734d007-353a-4b75-b948-f564548f8394",
                "path": "product\/Bolo_Decorado_Patrulha_Canina_(Opção_8).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "ea37544a-3dcc-456b-9b62-9a3cf1de7ca6",
        "product_description": "Bolo Pequena Sereia ",
        "product_details": "Bolo Pequena Sereia ",
        "product_unique_name": "bolo-pequena-sereia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Pequena_Sereia.jpg",
        "category": [
            {
                "id": "f9fee4cf-160e-4600-8a10-27cd3fef175c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pequena Sereia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1ef0d29f-8332-44ae-8636-d4484fab00ef",
                "path": "product\/Bolo_Pequena_Sereia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "f731ec33-2ed4-4d8b-86ff-1c2d22fa5afb",
        "product_description": "Bolo Decorado Patrulha Canina (Opção 9)",
        "product_details": "Bolo Decorado Patrulha Canina (Opção 9)",
        "product_unique_name": "bolo-decorado-patrulha-canina-opcao-9",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Patrulha_Canina_(Opção_9).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d86f9cf1-4de9-476d-8cfc-28c7881c5569",
                "path": "product\/Bolo_Decorado_Patrulha_Canina_(Opção_9).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "bbb53047-818e-4960-97d7-8dbe98fa2747",
        "product_description": "Colheres de Brigadeiro Patrulha Canina",
        "product_details": "Colheres de Brigadeiro Patrulha Canina",
        "product_unique_name": "colheres-de-brigadeiro-patrulha-canina",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Colheres_de_Brigadeiro_Patrulha_Canina.jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4641a698-2e26-4b62-b3ea-859cd4f7dc0b",
                "path": "product\/Colheres_de_Brigadeiro_Patrulha_Canina.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "47362faa-d8f9-4973-95ad-9fc59d2e02fb",
        "product_description": "Bolo Decorado Patrulha Canina (Opção 10)",
        "product_details": "Bolo Decorado Patrulha Canina (Opção 10)",
        "product_unique_name": "bolo-decorado-patrulha-canina-opcao-10",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Patrulha_Canina_(Opção_10).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8567a329-260a-4ef7-9c99-942e462139f2",
                "path": "product\/Bolo_Decorado_Patrulha_Canina_(Opção_10).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "e39a152b-6e4d-4368-be0d-6fc5a63f3c1e",
        "product_description": "Cupcakes Decorados Patrulha Canina (Opção 3)",
        "product_details": "Cupcakes Decorados Patrulha Canina (Opção 3)",
        "product_unique_name": "cupcakes-decorados-patrulha-canina-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Patrulha_Canina_(Opção_3).jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4ae896a6-93fb-4387-868f-ec13b21eb9dc",
                "path": "product\/Cupcakes_Decorados_Patrulha_Canina_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "fb072d18-7866-473a-a5b1-06f145bf17aa",
        "product_description": "Doces Decorados Patrulha Canina",
        "product_details": "Doces Decorados Patrulha Canina",
        "product_unique_name": "doces-decorados-patrulha-canina",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Patrulha_Canina.jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7af5b17b-70db-45e8-b329-bf48ce544d2e",
                "path": "product\/Doces_Decorados_Patrulha_Canina.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "1d73c933-5e0f-4396-82e7-04c2829aee75",
        "product_description": "Bolo Decorado Harry Potter",
        "product_details": "Bolo Decorado Harry Potter",
        "product_unique_name": "bolo-decorado-harry-potter",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": true,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/bolo_harry_potter_2.jpg",
        "category": [
            {
                "id": "35f958d2-a337-46bd-9c62-5c9340ceccd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Harry Potter",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "26797d04-7ed2-4982-a7bc-ed4265e2b5ad",
                "path": "product\/bolo_harry_potter_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "c80f182c-3a24-4327-b041-0af67c01a80a",
        "product_description": "Bolo Decorado Harry Potter (Opção 2)",
        "product_details": "Bolo Decorado Harry Potter (Opção 2)",
        "product_unique_name": "bolo-decorado-harry-potter-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Harry_Potter_3.jpg",
        "category": [
            {
                "id": "35f958d2-a337-46bd-9c62-5c9340ceccd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Harry Potter",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "fa916f94-2ead-4fa4-9524-938f50af4bf8",
                "path": "product\/Bolo_Harry_Potter_3.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "30eef54c-6aaa-4c08-94db-9f829a4761b0",
        "product_description": "Cupcakes Decorados Harry Potter",
        "product_details": "Cupcakes Decorados Harry Potter",
        "product_unique_name": "cupcakes-decorados-harry-potter",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": true,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Harry_Potter.jpg",
        "category": [
            {
                "id": "35f958d2-a337-46bd-9c62-5c9340ceccd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Harry Potter",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "63a6199e-fa74-4941-a9d6-17719c7a87a3",
                "path": "product\/Cupcakes_Harry_Potter.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "e1ccf1b9-a7fa-4c9a-a452-839aa554ac12",
        "product_description": "Cupcakes Decorados Harry Potter (Opção 2)",
        "product_details": "Cupcakes Decorados Harry Potter (Opção 2)",
        "product_unique_name": "cupcakes-decorados-harry-potter-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Harry_Potter_2.jpg",
        "category": [
            {
                "id": "35f958d2-a337-46bd-9c62-5c9340ceccd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Harry Potter",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5ae1022f-8eb7-4eeb-a31c-ac479f106bcd",
                "path": "product\/Cupcakes_Harry_Potter_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "815f1104-a005-425b-855c-e5b203f4225f",
        "product_description": "Cupcakes Decorados Harry Potter (opção 3)",
        "product_details": "Cupcakes Decorados Harry Potter (opção 3)",
        "product_unique_name": "cupcakes-decorados-harry-potter-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Harry_Potter_2.jpg",
        "category": [
            {
                "id": "35f958d2-a337-46bd-9c62-5c9340ceccd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Harry Potter",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "23106040-2f2b-412f-a658-42f6cc66b481",
                "path": "product\/Cupcakes_Harry_Potter_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "be020ac3-ba6f-4075-81d5-f1645f5f9615",
        "product_description": "Doces Decorados Harry Potter (opção 2)",
        "product_details": "Doces Decorados Harry Potter (opção 2)",
        "product_unique_name": "doces-decorados-harry-potter-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Harry_Potter_(Opção_2).jpg",
        "category": [
            {
                "id": "35f958d2-a337-46bd-9c62-5c9340ceccd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Harry Potter",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6e05f605-c5a6-495e-88ec-696b2859c97c",
                "path": "product\/Doces_Decorados_Harry_Potter_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "bb5a6ce5-016e-4a7d-88b5-f482aba7b465",
        "product_description": "Bolo Decorado Harry Potter (Opção 4)",
        "product_details": "Bolo Decorado Harry Potter (Opção 4)",
        "product_unique_name": "bolo-decorado-harry-potter-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Harry_Potter_4.jpg",
        "category": [
            {
                "id": "35f958d2-a337-46bd-9c62-5c9340ceccd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Harry Potter",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "61ec402a-a0d6-4c5a-bc25-2c563fd45112",
                "path": "product\/Bolo_Harry_Potter_4.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "0e1da0dd-04d0-4a16-8afa-67886a563d2a",
        "product_description": "Bolo Decorado Harry Potter (Opção 5)",
        "product_details": "Bolo Decorado Harry Potter (Opção 5)",
        "product_unique_name": "bolo-decorado-harry-potter-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Harry_Potter.jpg",
        "category": [
            {
                "id": "35f958d2-a337-46bd-9c62-5c9340ceccd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Harry Potter",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9b6be2fe-8a13-4e63-a308-3dd8281592cc",
                "path": "product\/Bolo_Decorado_Harry_Potter.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "df1129af-354a-481a-bc1b-9fc4dd081734",
        "product_description": "Bolo Decorado Frozen ",
        "product_details": "Bolo Decorado Frozen ",
        "product_unique_name": "bolo-decorado-frozen",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/bolo_frozen.jpg",
        "category": [
            {
                "id": "5345f605-cd86-41d1-a50e-2199466dd1b9",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Frozen",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6f7b6869-3827-477a-a82e-672c4d815bb6",
                "path": "product\/bolo_frozen.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "535a5ec0-f518-4391-9ec5-6d19af322e9a",
        "product_description": "Cupcakes Decorados Frozen ",
        "product_details": "Cupcakes Decorados Frozen ",
        "product_unique_name": "cupcakes-decorados-frozen",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/cupcake_frozen.jpg",
        "category": [
            {
                "id": "5345f605-cd86-41d1-a50e-2199466dd1b9",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Frozen",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c6559b8b-ea7f-457f-9f51-6c6855532fbb",
                "path": "product\/cupcake_frozen.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "1a9fc61f-f8e8-41b3-b603-0730e6181ae5",
        "product_description": "Doces Decorados Frozen ",
        "product_details": "Doces Decorados Frozen ",
        "product_unique_name": "doces-decorados-frozen",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Doce_decorado_frozen.jpg",
        "category": [
            {
                "id": "5345f605-cd86-41d1-a50e-2199466dd1b9",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Frozen",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "88488d21-ca52-4cc6-b1be-a6823aea4fc0",
                "path": "product\/Doce_decorado_frozen.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "63d7931a-c651-4ae0-81be-1e94c9aff91f",
        "product_description": "Bolo Decorado Frozen (Opção 2)",
        "product_details": "Bolo Decorado Frozen (Opção 2)",
        "product_unique_name": "bolo-decorado-frozen-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Frozen.jpg",
        "category": [
            {
                "id": "5345f605-cd86-41d1-a50e-2199466dd1b9",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Frozen",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c63cdc2f-ac6d-4a3d-a3e8-fd3df7ae9a49",
                "path": "product\/Bolo_Decorado_Frozen.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "f95d2fca-c745-42a4-9601-8c39cea702ad",
        "product_description": "Bolo Decorado Princesas ",
        "product_details": "Bolo Decorado Princesas ",
        "product_unique_name": "bolo-decorado-princesas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Princesas.jpg",
        "category": [
            {
                "id": "7e00ba1b-29fa-40ca-b2ca-91e6f739ea87",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Princesas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e01eb50a-3a1e-4a63-af2d-a139e15692dc",
                "path": "product\/Bolo_Princesas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "a0aff898-9502-49c3-b3bd-0bda3b6e29c2",
        "product_description": "Cupcakes Decorados Princesas",
        "product_details": "Cupcakes Decorados Princesas",
        "product_unique_name": "cupcakes-decorados-princesas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Princesas.jpg",
        "category": [
            {
                "id": "7e00ba1b-29fa-40ca-b2ca-91e6f739ea87",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Princesas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "fc3b803e-bf5d-40ad-87c7-d653f7d79bb8",
                "path": "product\/Cupcakes_Princesas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "baeb9f54-a4af-403b-87f1-e4c79c2c7982",
        "product_description": "Bolo Decorado Três Princesas",
        "product_details": "Bolo Decorado Três Princesas",
        "product_unique_name": "bolo-decorado-tres-princesas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Princesas1.jpg",
        "category": [
            {
                "id": "7e00ba1b-29fa-40ca-b2ca-91e6f739ea87",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Princesas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a9980847-d032-4bd0-aae5-1522241d93b0",
                "path": "product\/Bolo_Princesas1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "e5b05853-e837-450f-8669-6c142cee6b3e",
        "product_description": "Cupcakes Decorados Princesas (Opção 2) ",
        "product_details": "Cupcakes Decorados Princesas (Opção 2) ",
        "product_unique_name": "cupcakes-decorados-princesas-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Princesas_(opção_2).jpg",
        "category": [
            {
                "id": "7e00ba1b-29fa-40ca-b2ca-91e6f739ea87",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Princesas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "95cc6bce-d2a1-4abb-8cd7-25fc707c0001",
                "path": "product\/Cupcakes_Princesas_(opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "ac9b8fba-8e20-4f5a-bc10-847816d2591a",
        "product_description": "Bolo Decorado Meu Primeiro Dentinho",
        "product_details": "Bolo Decorado Meu Primeiro Dentinho",
        "product_unique_name": "bolo-decorado-meu-primeiro-dentinho",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Meu_Primeiro_Dentinho.jpg",
        "category": [
            {
                "id": "7e00ba1b-29fa-40ca-b2ca-91e6f739ea87",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Princesas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "13889348-0132-40d0-aeef-0f066c453193",
                "path": "product\/Bolo_Meu_Primeiro_Dentinho.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "71a8828c-8484-48b1-b9a0-ee61d136612f",
        "product_description": "Bolo Decorado Princesas (Opção 2)",
        "product_details": "Bolo Decorado Princesas (Opção 2)",
        "product_unique_name": "bolo-decorado-princesas-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Princesas.jpg",
        "category": [
            {
                "id": "7e00ba1b-29fa-40ca-b2ca-91e6f739ea87",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Princesas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9fd18690-ba04-4b8a-98fd-5ff0f055816b",
                "path": "product\/Bolo_Decorado_Princesas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "cf1a65ce-2c40-4c7a-aa58-8e1b8dbd9aef",
        "product_description": "Bolo Decorado Princesas (Opção 3)",
        "product_details": "Bolo Decorado Princesas (Opção 3)",
        "product_unique_name": "bolo-decorado-princesas-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Princesas_(Opção_2).jpg",
        "category": [
            {
                "id": "7e00ba1b-29fa-40ca-b2ca-91e6f739ea87",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Princesas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9525de24-c32e-4432-8331-5a1c8f580a28",
                "path": "product\/Bolo_Decorado_Princesas_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "8822241b-4930-48de-9b20-71a811cc3354",
        "product_description": "Bolo Decorado Stitch ",
        "product_details": "Bolo Decorado Stitch ",
        "product_unique_name": "bolo-decorado-stitch",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Stitch.jpg",
        "category": [
            {
                "id": "13a17d4d-c587-4cc4-98b7-cac5ba161444",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lilo e Stitch",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "25e7674d-221e-45c8-9d88-98c1fcc3c387",
                "path": "product\/Bolo_Stitch.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "56c7f689-d09f-4f26-805a-c491b48c5840",
        "product_description": "Cupcakes Decorados Stitch",
        "product_details": "Cupcakes Decorados Stitch",
        "product_unique_name": "cupcakes-decorados-stitch",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Stitch.jpg",
        "category": [
            {
                "id": "13a17d4d-c587-4cc4-98b7-cac5ba161444",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lilo e Stitch",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "752c7c8c-094d-49d3-90c0-da6994340d0b",
                "path": "product\/Cupcakes_Stitch.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "0be303c6-ce4e-44e5-9753-fff459b316a4",
        "product_description": "Bolo Decorado Nossa Senhora",
        "product_details": "Bolo Decorado Nossa Senhora",
        "product_unique_name": "bolo-decorado-nossa-senhora",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Nossa_Senhora.jpg",
        "category": [
            {
                "id": "52ecf1da-9399-4074-b390-89119c1b7987",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Religiosos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f72b05f5-7597-4f65-b7ae-5d97ec429a7a",
                "path": "product\/Bolo_Nossa_Senhora.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "24fdfc76-7dad-44d0-8b44-43a5373d218e",
        "product_description": "Cupcakes Decorados Nossa Senhora",
        "product_details": "Cupcakes Decorados Nossa Senhora",
        "product_unique_name": "cupcakes-decorados-nossa-senhora",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Nossa_Senhora.jpg",
        "category": [
            {
                "id": "52ecf1da-9399-4074-b390-89119c1b7987",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Religiosos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3d99b0b0-e28e-43ab-95c0-2f4710691fff",
                "path": "product\/Cupcakes_Nossa_Senhora.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "3e466c69-7fa7-417d-a4ed-ea3ca1460564",
        "product_description": "Doces Decorados Nossa Senhora ",
        "product_details": "Doces Decorados Nossa Senhora ",
        "product_unique_name": "doces-decorados-nossa-senhora",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Nossa_Senhora.jpg",
        "category": [
            {
                "id": "52ecf1da-9399-4074-b390-89119c1b7987",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Religiosos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a51a1da2-91cb-4709-9ba1-6267b521ff71",
                "path": "product\/Doces_Decorados_Nossa_Senhora.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "a91fc16d-d197-4997-ba21-df8876584065",
        "product_description": "Cupcakes Decorados Arca de Noé (Opção 2)",
        "product_details": "Cupcakes Decorados Arca de Noé (Opção 2)",
        "product_unique_name": "cupcakes-decorados-arca-de-noe-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Cupcake_Decorado_Arca_de_Noé.jpg",
        "category": [
            {
                "id": "52ecf1da-9399-4074-b390-89119c1b7987",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Religiosos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "601348da-2184-42ce-94a9-447c4d75e60c",
                "path": "product\/Cupcake_Decorado_Arca_de_Noé.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "51ad4c7f-03dc-484a-ac7d-ea3912fe65f3",
        "product_description": "Bolo Decorado Arca de Noé (opção 2)",
        "product_details": "Bolo Decorado Arca de Noé (opção 2)",
        "product_unique_name": "bolo-decorado-arca-de-noe-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Arca_de_Noé.jpg",
        "category": [
            {
                "id": "52ecf1da-9399-4074-b390-89119c1b7987",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Religiosos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1ce521a0-6563-47f9-a6a5-fff61eacf095",
                "path": "product\/Bolo_Arca_de_Noé.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "9fcd71fa-b49c-40f2-ad97-814547c70fa3",
        "product_description": "Bolo Decorado Minions",
        "product_details": "Bolo Decorado Minions",
        "product_unique_name": "bolo-decorado-minions",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-01-26T14:23:35.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Minions.jpg",
        "category": [
            {
                "id": "8859853e-70c2-491e-937a-4cf0b488dcae",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Minions",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d23ca1c6-1f6f-4438-957e-ca15a95e129e",
                "path": "product\/Bolo_Minions.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "cc3348e2-0feb-4580-acef-a49be78fe46b",
        "product_description": "Cupcakes Decorados Minions",
        "product_details": "Cupcakes Decorados Minions",
        "product_unique_name": "cupcakes-decorados-minions",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Minions.jpg",
        "category": [
            {
                "id": "8859853e-70c2-491e-937a-4cf0b488dcae",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Minions",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "df95bb13-5ffc-4133-a567-51f3b4b12178",
                "path": "product\/Cupcakes_Minions.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "167406d2-e25b-4e04-8f1b-53c08e96581d",
        "product_description": "Doces Decorados Minions ",
        "product_details": "Doces Decorados Minions ",
        "product_unique_name": "doces-decorados-minions",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Minions.jpg",
        "category": [
            {
                "id": "8859853e-70c2-491e-937a-4cf0b488dcae",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Minions",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b446bc6c-a429-432d-b25d-064079e70f8c",
                "path": "product\/Doces_Decorados_Minions.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "a62ffcc9-8cac-48d3-9b67-a44eb4573f30",
        "product_description": "Picolé Pop Minions ",
        "product_details": "Picolé Pop Minions ",
        "product_unique_name": "picole-pop-minions",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Picolé_Pop_Minions.jpg",
        "category": [
            {
                "id": "8859853e-70c2-491e-937a-4cf0b488dcae",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Minions",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "142cf2ff-6ee3-4d24-8c66-33f578eb39c8",
                "path": "product\/Picolé_Pop_Minions.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "dc845f67-5519-40c0-96a7-a3f6e5680db1",
        "product_description": "Bolo Decorado Minions (Opção 2) ",
        "product_details": "Bolo Decorado Minions (Opção 2) ",
        "product_unique_name": "bolo-decorado-minions-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Minions_Rosa.jpg",
        "category": [
            {
                "id": "8859853e-70c2-491e-937a-4cf0b488dcae",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Minions",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e5ee51b1-4b31-4817-902f-8d07958e1e0b",
                "path": "product\/Bolo_Minions_Rosa.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "2909a67a-3771-4479-80d3-ccd855aa5552",
        "product_description": "Bolo Decorado Minions Azul",
        "product_details": "Bolo Decorado Minions Azul",
        "product_unique_name": "bolo-decorado-minions-azul",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Minions_Azul.jpg",
        "category": [
            {
                "id": "8859853e-70c2-491e-937a-4cf0b488dcae",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Minions",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b1e16aea-61de-4f0b-9c96-23942ae5cdf9",
                "path": "product\/Bolo_Decorado_Minions_Azul.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "ec1a45de-4bcf-4c6f-9a54-d91c0c7772bf",
        "product_description": "Bolo Decorado Minions (Opção 3)",
        "product_details": "Bolo Decorado Minions (Opção 3)",
        "product_unique_name": "bolo-decorado-minions-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Minions_Opção_3JPG.jpg",
        "category": [
            {
                "id": "8859853e-70c2-491e-937a-4cf0b488dcae",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Minions",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3ff282f4-2990-48cd-b698-be0b23b236b0",
                "path": "product\/Bolo_Decorado_Minions_Opção_3JPG.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "c4101dcd-6b2d-423d-8c4d-cc9843bb644c",
        "product_description": "Cupcakes Decorados Minions (Opção 2)",
        "product_details": "Cupcakes Decorados Minions (Opção 2)",
        "product_unique_name": "cupcakes-decorados-minions-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Minions_(Opção_2).jpg",
        "category": [
            {
                "id": "8859853e-70c2-491e-937a-4cf0b488dcae",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Minions",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "641f2d2a-1596-41fe-bf15-9e400671503f",
                "path": "product\/Cupcakes_Minions_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "db705a22-4a88-4be8-8b64-dedb047a0948",
        "product_description": "Cones Trufados My Little Pony",
        "product_details": "Cones Trufados My Little Pony",
        "product_unique_name": "cones-trufados-my-little-pony",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cones_Trufados_My_Little_Pony.jpg",
        "category": [
            {
                "id": "a899d527-3063-4758-9746-cfe1534b98af",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "My Little Pony",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "fe518d12-3dfa-4fb5-9805-dfb4294fef56",
                "path": "product\/Cones_Trufados_My_Little_Pony.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "184ed0dc-eafa-4536-9aa9-451fecc85fe0",
        "product_description": "Cupcakes Decorados My Little Pony ",
        "product_details": "Cupcakes Decorados My Little Pony ",
        "product_unique_name": "cupcakes-decorados-my-little-pony",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_My_Little_Pony1.jpg",
        "category": [
            {
                "id": "a899d527-3063-4758-9746-cfe1534b98af",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "My Little Pony",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "37e3e6ac-c891-4535-ae3e-083965365208",
                "path": "product\/Cupcakes_My_Little_Pony1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "2fc13caf-e337-40eb-a6b2-11e3cab175c8",
        "product_description": "Doces Decorados My Little Pony",
        "product_details": "Doces Decorados My Little Pony",
        "product_unique_name": "doces-decorados-my-little-pony",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_My_Little_Pony.jpg",
        "category": [
            {
                "id": "a899d527-3063-4758-9746-cfe1534b98af",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "My Little Pony",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a6d49eac-4c3c-4848-8120-87e46e93e2b4",
                "path": "product\/Doces_Decorados_My_Little_Pony.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "6914fbdb-9388-4bce-a70b-719471a835b8",
        "product_description": "Bolo Decorado My Little Pony",
        "product_details": "Bolo Decorado My Little Pony",
        "product_unique_name": "bolo-decorado-my-little-pony",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/My_Little_Pony.jpg",
        "category": [
            {
                "id": "a899d527-3063-4758-9746-cfe1534b98af",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "My Little Pony",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f5380d52-32d4-4a35-96a7-326374575377",
                "path": "product\/My_Little_Pony.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "39e8d02e-10a0-475c-b453-f158300d1ce5",
        "product_description": "Cupcakes Decorados My Little Pony (Opção 2)",
        "product_details": "Cupcakes Decorados My Little Pony (Opção 2)",
        "product_unique_name": "cupcakes-decorados-my-little-pony-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_My_Little_Pony2.jpg",
        "category": [
            {
                "id": "a899d527-3063-4758-9746-cfe1534b98af",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "My Little Pony",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bb3704ec-4574-4893-ac3e-e74e8d63ecc4",
                "path": "product\/Cupcakes_My_Little_Pony2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "5daf29a6-ab63-4ff0-b81d-08b784434a13",
        "product_description": "Bolo Decorado Now United",
        "product_details": "Bolo Decorado Now United",
        "product_unique_name": "bolo-decorado-now-united",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Now_United.jpg",
        "category": [
            {
                "id": "ea1db169-e6bf-48d5-8009-62e84bbc3e50",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Now United",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "60d4c5a9-a843-4b7a-a321-8ea29afab1f3",
                "path": "product\/Bolo_Now_United.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "63105f9f-2437-448a-9835-57dd774dad88",
        "product_description": "Cupcakes Decorados Now United",
        "product_details": "Cupcakes Decorados Now United",
        "product_unique_name": "cupcakes-decorados-now-united",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Now_United.jpg",
        "category": [
            {
                "id": "ea1db169-e6bf-48d5-8009-62e84bbc3e50",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Now United",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8a93a125-5ddc-48e8-a390-c80de83a10b9",
                "path": "product\/Cupcakes_Now_United.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "98918861-89b9-4dd9-896e-e365ff934b18",
        "product_description": "Doces Decorados Now United",
        "product_details": "Doces Decorados Now United",
        "product_unique_name": "doces-decorados-now-united",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Now_United.jpg",
        "category": [
            {
                "id": "ea1db169-e6bf-48d5-8009-62e84bbc3e50",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Now United",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7e754898-ce97-4a0c-84b0-e8fca5ec54c7",
                "path": "product\/Doces_Decorados_Now_United.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "1e3ddb3d-12eb-4dd2-9992-88f32c467289",
        "product_description": "Bolo Decorado Now United (Opção 2)",
        "product_details": "Bolo Decorado Now United (Opção 2)",
        "product_unique_name": "bolo-decorado-now-united-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Now_United.jpg",
        "category": [
            {
                "id": "ea1db169-e6bf-48d5-8009-62e84bbc3e50",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Now United",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "970e2b00-d8b8-4aa0-ae80-b16b53c795f6",
                "path": "product\/Bolo_Decorado_Now_United.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "54c3f1dd-8f07-4b01-b656-d58077189540",
        "product_description": "Cupcakes Decorados Now United (Opção 2)",
        "product_details": "Cupcakes Decorados Now United (Opção 2)",
        "product_unique_name": "cupcakes-decorados-now-united-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Now_United.jpg",
        "category": [
            {
                "id": "ea1db169-e6bf-48d5-8009-62e84bbc3e50",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Now United",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5fa59848-582a-43db-941f-e9f25aa1ba04",
                "path": "product\/Cupcakes_Decorados_Now_United.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "41d22e73-e319-4431-88fd-06867a44e149",
        "product_description": "Bolo Decorado Batizado Anjos Azul",
        "product_details": "Bolo Decorado Batizado Anjos Azul",
        "product_unique_name": "bolo-decorado-batizado-anjos-azul",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Batizado_Anjos_(Menino).jpg",
        "category": [
            {
                "id": "bd35dc6d-f831-492a-96fd-a1ff8dce8256",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Batizado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4e84b552-8e90-4cc3-827a-548003fb60b6",
                "path": "product\/Bolo_Batizado_Anjos_(Menino).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "ada604eb-dece-4a3d-8977-e3e2fd9ab4a4",
        "product_description": "Cupcakes Decorados Batizado Anjos Azul",
        "product_details": "Cupcakes Decorados Batizado Anjos Azul",
        "product_unique_name": "cupcakes-decorados-batizado-anjos-azul",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Batizado_anjos_(Menino).jpg",
        "category": [
            {
                "id": "bd35dc6d-f831-492a-96fd-a1ff8dce8256",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Batizado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "07590812-e855-445e-bd3f-54a69640d584",
                "path": "product\/Cupcakes_Batizado_anjos_(Menino).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "b1bb5bd2-3982-452a-a98b-935203b9127c",
        "product_description": "Doces Decorados Batizados Anjos Azul",
        "product_details": "Doces Decorados Batizados Anjos Azul",
        "product_unique_name": "doces-decorados-batizados-anjos-azul",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Batizado_(Menino).jpg",
        "category": [
            {
                "id": "bd35dc6d-f831-492a-96fd-a1ff8dce8256",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Batizado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0c911f5b-6567-4b94-b85a-54928f76799b",
                "path": "product\/Doces_Decorados_Batizado_(Menino).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "8154318a-109c-4f6a-bb68-4525508dd8ec",
        "product_description": "Doces Decorados Batizado",
        "product_details": "Doces Decorados Batizado",
        "product_unique_name": "doces-decorados-batizado",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Batizado.jpg",
        "category": [
            {
                "id": "bd35dc6d-f831-492a-96fd-a1ff8dce8256",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Batizado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a4ebf804-311a-4803-85e3-377416a9f304",
                "path": "product\/Doces_Decorados_Batizado.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "abdd8abe-58ff-4e23-aa6c-8002a1761c8c",
        "product_description": "Bolo Decorado Batizado (Opção 2)",
        "product_details": "Bolo Decorado Batizado (Opção 2)",
        "product_unique_name": "bolo-decorado-batizado-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Batizado_(Opção_2).jpg",
        "category": [
            {
                "id": "bd35dc6d-f831-492a-96fd-a1ff8dce8256",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Batizado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "20018f7b-8256-4a64-9c57-8eb2e912ab41",
                "path": "product\/Bolo_Decorado_Batizado_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "0f1c68e3-a542-4bf4-a076-ddc5f0a2e995",
        "product_description": "Bolo Decorado Masha e o Urso ",
        "product_details": "Bolo Decorado Masha e o Urso ",
        "product_unique_name": "bolo-decorado-masha-e-o-urso",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Masha_e_o_urso.jpg",
        "category": [
            {
                "id": "ae8335e5-194f-4b3b-9285-d71ba3420744",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Masha e o Urso",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6df7d954-af5e-44ee-95da-dd814745a7dc",
                "path": "product\/Bolo_Masha_e_o_urso.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "e453fb0a-cb36-40ad-b08f-0d71c5bf7b1d",
        "product_description": "Doces Decorados Masha e o Urso ",
        "product_details": "Doces Decorados Masha e o Urso ",
        "product_unique_name": "doces-decorados-masha-e-o-urso",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Masha_e_o_Urso.jpg",
        "category": [
            {
                "id": "ae8335e5-194f-4b3b-9285-d71ba3420744",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Masha e o Urso",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "15d4f21d-5b1b-4085-b609-4a6a3794059f",
                "path": "product\/Doces_Decorados_Masha_e_o_Urso.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "a50eed30-8c0d-485c-9b49-5c2f9d698cdd",
        "product_description": "Pirulitos Decorados Masha e o Urso ",
        "product_details": "Pirulitos Decorados Masha e o Urso ",
        "product_unique_name": "pirulitos-decorados-masha-e-o-urso",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Masha_e_o_Urso.jpg",
        "category": [
            {
                "id": "ae8335e5-194f-4b3b-9285-d71ba3420744",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Masha e o Urso",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ffbaf665-e417-4c27-b3d4-e584cbcb5bed",
                "path": "product\/Pirulitos_Masha_e_o_Urso.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "6e3c99ef-f407-4b9d-bc84-eb73bee6f238",
        "product_description": "Bolo Decorado Masha e o Urso (Opção 2)",
        "product_details": "Bolo Decorado Masha e o Urso (Opção 2)",
        "product_unique_name": "bolo-decorado-masha-e-o-urso-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Masha_e_o_Urso.jpg",
        "category": [
            {
                "id": "ae8335e5-194f-4b3b-9285-d71ba3420744",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Masha e o Urso",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c84c45db-bcae-455a-8cd2-35187eb97ec8",
                "path": "product\/Bolo_Decorado_Masha_e_o_Urso.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "27ebd584-9bb8-48d0-af19-ae53e92bf559",
        "product_description": "Cupcakes Decorados Masha e o Urso",
        "product_details": "Cupcakes Decorados Masha e o Urso",
        "product_unique_name": "cupcakes-decorados-masha-e-o-urso",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Masha_e_o_Urso.jpg",
        "category": [
            {
                "id": "ae8335e5-194f-4b3b-9285-d71ba3420744",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Masha e o Urso",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "841857b6-8155-43a1-aee5-fcd6e23864b0",
                "path": "product\/Cupcakes_Decorados_Masha_e_o_Urso.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "ea81b58f-e093-4fad-a20c-ce4cbdb12968",
        "product_description": "Doces Decorados Masha e o Urso (Opção 2)",
        "product_details": "Doces Decorados Masha e o Urso (Opção 2)",
        "product_unique_name": "doces-decorados-masha-e-o-urso-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Masha_e_o_Urso_(Opção_2).jpg",
        "category": [
            {
                "id": "ae8335e5-194f-4b3b-9285-d71ba3420744",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Masha e o Urso",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "129fb097-80c0-4876-852f-b65beea26d34",
                "path": "product\/Doces_Decorados_Masha_e_o_Urso_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "16892694-1721-4114-af1b-fe6464a69100",
        "product_description": "Bolo Decorado Unicórnios",
        "product_details": "Bolo Decorado Unicórnios",
        "product_unique_name": "bolo-decorado-unicornios",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Unicórnios.jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8977ec8d-19ba-4409-9802-88f27a6ada0d",
                "path": "product\/Bolo_Decorado_Unicórnios.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "fe967b38-140a-422a-9ec7-e8f0e1259080",
        "product_description": "Cupcakes Decorados Unicórnios",
        "product_details": "Cupcakes Decorados Unicórnios",
        "product_unique_name": "cupcakes-decorados-unicornios",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Unicórnios1.jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0420aff9-f8f0-406c-885d-a7951206968f",
                "path": "product\/Cupcakes_Decorados_Unicórnios1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "b56b791a-4257-4840-97c4-d82835772618",
        "product_description": "Bolo Decorado Unicórnios (Opção 2)",
        "product_details": "Bolo Decorado Unicórnios (Opção 2)",
        "product_unique_name": "bolo-decorado-unicornios-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Unicórnios_(Opção_2)1.jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1c93bf5b-25aa-4179-bb94-d6689fd9d49f",
                "path": "product\/Bolo_Decorado_Unicórnios_(Opção_2)1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "81cb1287-9734-46e9-ab78-b63db561dd9c",
        "product_description": "Doces Decorados Unicórnios",
        "product_details": "Doces Decorados Unicórnios",
        "product_unique_name": "doces-decorados-unicornios",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Unicórnios.jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e602056b-7f07-4b8f-a8eb-21a2796a88f1",
                "path": "product\/Doces_Decorados_Unicórnios.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "96c6fb6f-bd17-4b8f-9e45-43fb5414bfdc",
        "product_description": "Bolo Decorado Unicórnios (Opção 3)",
        "product_details": "Bolo Decorado Unicórnios (Opção 3)",
        "product_unique_name": "bolo-decorado-unicornios-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Unicórnios_(Opção_2).jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6b2ba45f-b17c-43f4-b7d5-4dc8c66ebd5a",
                "path": "product\/Bolo_Decorado_Unicórnios_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "6b4ae3ae-fd60-45e1-994c-02b1cbbe0a4d",
        "product_description": "Bolo Decorado Unicórnios (Opção 4)",
        "product_details": "Bolo Decorado Unicórnios (Opção 4)",
        "product_unique_name": "bolo-decorado-unicornios-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Unicórnios_(Opção_3).jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3c688b5e-dbca-443e-a523-36b6f47e2eeb",
                "path": "product\/Bolo_Decorado_Unicórnios_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "bce7bf27-3a98-4671-8a3f-af7fc2871412",
        "product_description": "Cupcakes Decorados Unicórnios (Opções 2)",
        "product_details": "Cupcakes Decorados Unicórnios (Opções 2)",
        "product_unique_name": "cupcakes-decorados-unicornios-opcoes-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Unicórnios.jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0b80fd47-ff2e-49fa-b74e-6f891753080e",
                "path": "product\/Cupcakes_Decorados_Unicórnios.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "80aa17cb-54e4-48f7-b137-714224f920f5",
        "product_description": "Pirulitos Decorados Unicórnios",
        "product_details": "Pirulitos Decorados Unicórnios",
        "product_unique_name": "pirulitos-decorados-unicornios",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Unicórnios.jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "05a0e2cc-66ae-458f-b9d9-cc38c69f94da",
                "path": "product\/Pirulitos_Decorados_Unicórnios.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "2c25f612-4964-42f2-a8cd-4339fcbf0f80",
        "product_description": "Bolo Decorado Unicórnios (Opção 5)",
        "product_details": "Bolo Decorado Unicórnios (Opção 5)",
        "product_unique_name": "bolo-decorado-unicornios-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Unicórnios_(Opção_4).jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "91c2ade2-7236-4f08-baeb-2a6741b78673",
                "path": "product\/Bolo_Decorado_Unicórnios_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "53f073b2-f920-4417-9dba-ea761c5caf6a",
        "product_description": "Cupcakes Decorados Unicórnios (Opção 2)",
        "product_details": "Cupcakes Decorados Unicórnios (Opção 2)",
        "product_unique_name": "cupcakes-decorados-unicornios-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Unicórnios_(Opção_2).jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ff2d7842-60a1-4f09-94f3-c294e284229e",
                "path": "product\/Cupcakes_Decorados_Unicórnios_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "d43cd8f4-2df0-4e55-a66a-347f0dcc3185",
        "product_description": "Doces Decorados Unicórnios (Opção 2)",
        "product_details": "Doces Decorados Unicórnios (Opção 2)",
        "product_unique_name": "doces-decorados-unicornios-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Unicórnios_(Opção_2).jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c4f897cf-315d-4ab3-8b64-c745dbab518c",
                "path": "product\/Doces_Decorados_Unicórnios_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "d70f113e-7a2e-4989-90d7-bf22f8588de3",
        "product_description": "Bolo Decorado Unicórnios (Opção 6)",
        "product_details": "Bolo Decorado Unicórnios (Opção 6)",
        "product_unique_name": "bolo-decorado-unicornios-opcao-6",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Unicórnios_(Opção_5).jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d546632d-16ac-47aa-8cf0-fa7e2b6ee157",
                "path": "product\/Bolo_Decorado_Unicórnios_(Opção_5).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "3fc3d65f-7821-4d94-b4d5-eda68817090d",
        "product_description": "Cupcakes Decorados Unicórnios (Opção 3)",
        "product_details": "Cupcakes Decorados Unicórnios (Opção 3)",
        "product_unique_name": "cupcakes-decorados-unicornios-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Unicórnios_(Opção_3).jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "669e8862-1fe7-4834-9432-6818ef7e2e6d",
                "path": "product\/Cupcakes_Decorados_Unicórnios_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "74579f22-4b9a-4e8c-9495-7e77f4dd3f5a",
        "product_description": "Bolo Decorado Unicórnios (Opção 7)",
        "product_details": "Bolo Decorado Unicórnios (Opção 7)",
        "product_unique_name": "bolo-decorado-unicornios-opcao-7",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Unicórnios_(Opção_6).jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "725a2d44-deea-46fc-852f-f491ad5cce3a",
                "path": "product\/Bolo_Decorado_Unicórnios_(Opção_6).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "79e642b5-a91b-4ac5-b7d8-674ccd94b73a",
        "product_description": "Cupcakes Decorados (Opção 4)",
        "product_details": "Cupcakes Decorados (Opção 4)",
        "product_unique_name": "cupcakes-decorados-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Unicórnios_(Opção_4).jpg",
        "category": [
            {
                "id": "e5a4e1e0-19f2-4af6-9408-65943d49adc5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Unicórnios",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "28686ee0-abd7-4306-b9cc-a7599c5c9111",
                "path": "product\/Cupcakes_Decorados_Unicórnios_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "b3ebbc08-5099-41ee-a3d4-9f21f615d768",
        "product_description": "Bolo Decorado Metoo Dolls ",
        "product_details": "Bolo Decorado Metoo Dolls ",
        "product_unique_name": "bolo-decorado-metoo-dolls",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Mee_Too_dolls1.jpg",
        "category": [
            {
                "id": "09e4bd7c-ea88-463a-b3b7-dafb73c161f8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Meetoo Dolls",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "548fd9ec-a01e-41c0-83f7-ed295dea1a0e",
                "path": "product\/Bolo_Decorado_Mee_Too_dolls1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "32cd7e50-92b9-4308-adbd-2aecceb4cdc5",
        "product_description": "Cupcakes Decorados Metoo Dolls",
        "product_details": "Cupcakes Decorados Metoo Dolls",
        "product_unique_name": "cupcakes-decorados-metoo-dolls",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_mee_too_dolls.jpg",
        "category": [
            {
                "id": "09e4bd7c-ea88-463a-b3b7-dafb73c161f8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Meetoo Dolls",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "be7b46a7-01b2-48a6-bf2a-f1868c8cd1b7",
                "path": "product\/Cupcakes_mee_too_dolls.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "f3182578-9f4d-46be-9de8-dc6fc3c66d1d",
        "product_description": "Bolo Decorado Meetoo Dolls",
        "product_details": "Bolo Decorado Meetoo Dolls",
        "product_unique_name": "bolo-decorado-meetoo-dolls",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_MeeToo_Dolls.jpg",
        "category": [
            {
                "id": "09e4bd7c-ea88-463a-b3b7-dafb73c161f8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Meetoo Dolls",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4b0ab9ef-3b35-4c51-a369-7d4aab5c55c0",
                "path": "product\/Bolo_MeeToo_Dolls.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "08eab8d8-a3ac-4914-b899-15a0efaede11",
        "product_description": "Bolo Decorado Meetoo Dolls (Opção 2)",
        "product_details": "Bolo Decorado Meetoo Dolls (Opção 2)",
        "product_unique_name": "bolo-decorado-meetoo-dolls-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Mee_Too_dolls.jpg",
        "category": [
            {
                "id": "09e4bd7c-ea88-463a-b3b7-dafb73c161f8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Meetoo Dolls",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ef881d94-ea70-41d8-8705-0318555b4e5c",
                "path": "product\/Bolo_Decorado_Mee_Too_dolls.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "5073d4a3-22f2-4a9f-b07e-2c2aa39d6961",
        "product_description": "Cupcakes Decorados Batizado",
        "product_details": "Cupcakes Decorados Batizado",
        "product_unique_name": "cupcakes-decorados-batizado",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-02-02T21:47:25.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Batizado.jpg",
        "category": [
            {
                "id": "bd35dc6d-f831-492a-96fd-a1ff8dce8256",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Batizado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "daf59067-ed2b-4ef3-a4fb-f4b6e36f8472",
                "event_description": "Batizado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "1564a606-b5b5-4677-b8bd-5cf925006380",
                "path": "product\/Cupcakes_Decorados_Batizado.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "93a69b51-fe04-486c-88a6-a70772798883",
        "product_description": "Bolo Decorado Meetoo Dolls (Opção 3)",
        "product_details": "Bolo Decorado Meetoo Dolls (Opção 3)",
        "product_unique_name": "bolo-decorado-meetoo-dolls-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Mee_Too_dolls_Opção_3.jpg",
        "category": [
            {
                "id": "09e4bd7c-ea88-463a-b3b7-dafb73c161f8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Meetoo Dolls",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "102bc12b-f4ad-49df-a8e2-1a39e57b0b28",
                "path": "product\/Bolo_Decorado_Mee_Too_dolls_Opção_3.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "8bb7d16b-162f-435b-89e7-7bd467a75eb4",
        "product_description": "Bolo Decorado Me Too Dolls (Opção 4)",
        "product_details": "Bolo Decorado Me Too Dolls (Opção 4)",
        "product_unique_name": "bolo-decorado-me-too-dolls-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Mee_Too_dolls_Opção_4.jpg",
        "category": [
            {
                "id": "09e4bd7c-ea88-463a-b3b7-dafb73c161f8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Meetoo Dolls",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bb03b262-cf4f-44ac-a705-a6e88aade928",
                "path": "product\/Bolo_Decorado_Mee_Too_dolls_Opção_4.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "e685d657-17fe-453f-b339-b0f1c152b1b8",
        "product_description": "Cupcakes Decorados Mee Too Dolls",
        "product_details": "Cupcakes Decorados Mee Too Dolls",
        "product_unique_name": "cupcakes-decorados-mee-too-dolls",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Me_Too.jpg",
        "category": [
            {
                "id": "09e4bd7c-ea88-463a-b3b7-dafb73c161f8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Meetoo Dolls",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f5989cb5-b469-46ce-9482-8b12c82ec585",
                "path": "product\/Cupcakes_Decorados_Me_Too.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "7d8d344a-4cfa-4c71-afdb-79f358634417",
        "product_description": "Bolo Decorado Monstros S.A",
        "product_details": "Bolo Decorado Monstros S.A",
        "product_unique_name": "bolo-decorado-monstros-sa",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Monstros_SA.jpg",
        "category": [
            {
                "id": "ec143be3-9455-4568-9c0a-6bd573997228",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Monstros S.A",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d6e0b15d-0650-443e-a289-f602f092680b",
                "path": "product\/Bolo_Monstros_SA.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "e10dfdf7-117b-48c5-b36f-41882b528fe4",
        "product_description": "Cupcakes Decorados Monstros SA",
        "product_details": "Cupcakes Decorados Monstros SA",
        "product_unique_name": "cupcakes-decorados-monstros-sa",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Monstros_SA.jpg",
        "category": [
            {
                "id": "ec143be3-9455-4568-9c0a-6bd573997228",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Monstros S.A",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a150dc0e-efd8-49ac-b82d-8f160f12c91d",
                "path": "product\/Cupcakes_Monstros_SA.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "a25fd02c-05d7-4f60-8fa4-b0d55c91f80e",
        "product_description": "Pirulitos Decorados Monstros S.A",
        "product_details": "Pirulitos Decorados Monstros S.A",
        "product_unique_name": "pirulitos-decorados-monstros-sa",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-01-26T14:23:36.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Monstros_SA.jpg",
        "category": [
            {
                "id": "ec143be3-9455-4568-9c0a-6bd573997228",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Monstros S.A",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "539b6f10-d353-4a65-b546-9c6d920cb271",
                "path": "product\/Pirulitos_Monstros_SA.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "8c4cc302-422a-463f-b3c4-0219272a94c6",
        "product_description": "Doces Decorados Monstros S.A ",
        "product_details": "Doces Decorados Monstros S.A ",
        "product_unique_name": "doces-decorados-monstros-sa",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Monstros_SA.jpg",
        "category": [
            {
                "id": "ec143be3-9455-4568-9c0a-6bd573997228",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Monstros S.A",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "52d7fe40-eb90-498c-b620-31d4c6077ed2",
                "path": "product\/Doces_Decorados_Monstros_SA.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "87d82093-85c6-4d78-b8a0-4628624d7e25",
        "product_description": "Bolo Decorado Woodland",
        "product_details": "Bolo Decorado Woodland",
        "product_unique_name": "bolo-decorado-woodland",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Woodland.jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "80ef31e9-a697-4aa0-88b3-73d551de44a6",
                "path": "product\/Bolo_Woodland.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "ab20d489-c925-43f6-8e72-68698b1879b3",
        "product_description": "Cupcakes Decorados Woodland",
        "product_details": "Cupcakes Decorados Woodland",
        "product_unique_name": "cupcakes-decorados-woodland",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Mini_Cups_Decorados_Woodland.jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3e0e57ff-5de1-48ed-9724-8d58e8998f46",
                "path": "product\/Mini_Cups_Decorados_Woodland.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "1f102762-4438-4c9d-8f2b-43712fe8b067",
        "product_description": "Bolo Decorado Woodland (Opção 2)",
        "product_details": "Bolo Decorado Woodland (Opção 2)",
        "product_unique_name": "bolo-decorado-woodland-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Woodland.jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "49b4541a-8708-4f09-837a-4bd8b205ef58",
                "path": "product\/Bolo_Decorado_Woodland.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "fe99e950-3e56-4dcd-b582-97445ded661a",
        "product_description": "Bolo Decorado Woodland (Opção 3)",
        "product_details": "Bolo Decorado Woodland (Opção 3)",
        "product_unique_name": "bolo-decorado-woodland-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Woodland_(Opção_2).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b6d50671-cf71-4f65-a053-394b8843652e",
                "path": "product\/Bolo_Decorado_Woodland_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "d0cd6dad-eca7-42c1-b1aa-73d168c37e0b",
        "product_description": "Doces Decorados Woodland (Opção 2)",
        "product_details": "Doces Decorados Woodland (Opção 2)",
        "product_unique_name": "doces-decorados-woodland-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Woodland_(Opção_2).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "11866dc9-f2e9-40f5-b2a4-df3140d33904",
                "path": "product\/Doces_Decorados_Woodland_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "1538284a-29c6-4eaf-bd55-8915f9c2264a",
        "product_description": "Bolo Decorado Woodland (Opção 4)",
        "product_details": "Bolo Decorado Woodland (Opção 4)",
        "product_unique_name": "bolo-decorado-woodland-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Woodland_(Opção_3).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2c105c5f-2b7a-428e-aecb-5aae22486231",
                "path": "product\/Bolo_Decorado_Woodland_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "a51c68dd-e8ac-4c80-b3ed-2dd79de3725c",
        "product_description": "Bolo Decorado Woodland (Opção 5)",
        "product_details": "Bolo Decorado Woodland (Opção 5)",
        "product_unique_name": "bolo-decorado-woodland-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Woodland_(Opção_4).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a62cd29d-ce6c-493e-a11e-8f75d3bc9632",
                "path": "product\/Bolo_Decorado_Woodland_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "d86a3ce6-df10-41d9-a972-55c5948b5de7",
        "product_description": "Bolo Decorado Woodland (Opção 6)",
        "product_details": "Bolo Decorado Woodland (Opção 6)",
        "product_unique_name": "bolo-decorado-woodland-opcao-6",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Woodland_(Opção_5).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "20c8703e-23cd-4d71-8e6e-7115a636f788",
                "path": "product\/Bolo_Decorado_Woodland_(Opção_5).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "3224d9f1-2ace-4bd1-b404-0f839c9b103a",
        "product_description": "Doces Decorados Woodland",
        "product_details": "Doces Decorados Woodland",
        "product_unique_name": "doces-decorados-woodland",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Doces_Woodland.jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5a1fc5ea-253e-4803-a582-e02fdeaf5954",
                "path": "product\/Doces_Woodland.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "765a533a-7340-424b-832b-6a884444d8b3",
        "product_description": "Bolo Decorado Woodland (Opção 7)",
        "product_details": "Bolo Decorado Woodland (Opção 7)",
        "product_unique_name": "bolo-decorado-woodland-opcao-7",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Woodland_(Opção_6).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5dacb9e1-764b-4193-87d4-6aad857085c4",
                "path": "product\/Bolo_Decorado_Woodland_(Opção_6).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "a7c6e281-de72-4ba8-8fd0-9aae7d7fa37c",
        "product_description": "Doces Decorados Woodland (Opção 5)",
        "product_details": "Doces Decorados Woodland (Opção 5)",
        "product_unique_name": "doces-decorados-woodland-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Woodland_(Opção_5).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "02c096cc-9661-4460-a5a5-831eda120b20",
                "path": "product\/Doces_Decorados_Woodland_(Opção_5).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "a3188fca-19d2-4f7e-aeaf-fa8befd585d7",
        "product_description": "Bolo Decorado Woodland (Opção 8)",
        "product_details": "Bolo Decorado Woodland (Opção 8)",
        "product_unique_name": "bolo-decorado-woodland-opcao-8",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Woodland_(Opção_8).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "da6215b9-77ef-4fa1-885c-40e6b7452ea1",
                "path": "product\/Bolo_Decorado_Woodland_(Opção_8).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "e81943d0-e6e7-43da-beaa-bac67bf2078a",
        "product_description": "Bolo Decorado Woodland (Opção 9)",
        "product_details": "Bolo Decorado Woodland (Opção 9)",
        "product_unique_name": "bolo-decorado-woodland-opcao-9",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Woodland_(Opção_9).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "18d704b7-3350-4f61-b339-1715936765eb",
                "path": "product\/Bolo_Decorado_Woodland_(Opção_9).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "3884b630-84fa-4a0c-9872-7e0a8ce52cdb",
        "product_description": "Doces Decorados Woodland (Opção 6)",
        "product_details": "Doces Decorados Woodland (Opção 6)",
        "product_unique_name": "doces-decorados-woodland-opcao-6",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Woodland_(Opção_6).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d2442dd5-8e65-45b9-b6e4-e6084d8d277e",
                "path": "product\/Doces_Decorados_Woodland_(Opção_6).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "5300cbcc-68d1-4d6f-9312-db3101fa928d",
        "product_description": "Bolo Decorado Woodland (Opção 10)",
        "product_details": "Bolo Decorado Woodland (Opção 10)",
        "product_unique_name": "bolo-decorado-woodland-opcao-10",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Woodland_(Opção_10).jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bb140430-2cdf-4feb-8358-f21e13bb3821",
                "path": "product\/Bolo_Decorado_Woodland_(Opção_10).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "b4d1eceb-8cef-4ee4-96c7-bbadab0c720b",
        "product_description": "Cupcakes Decorados Woodland (Opção 2)",
        "product_details": "Cupcakes Decorados Woodland (Opção 2)",
        "product_unique_name": "cupcakes-decorados-woodland-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Woodland.jpg",
        "category": [
            {
                "id": "88268516-fe4e-409a-92f8-e8303c4b2d8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Woodland",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8fb042bf-fff2-47a1-98a7-b88cea6fffbc",
                "path": "product\/Cupcakes_Decorados_Woodland.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "4ad34159-a7fb-4b9c-8d11-5e100e46d921",
        "product_description": "Bolo Decorado Ben 10",
        "product_details": "Bolo Decorado Ben 10",
        "product_unique_name": "bolo-decorado-ben-10",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Ben_10.jpg",
        "category": [
            {
                "id": "1648b5a5-8d1f-42c1-b0df-78393d0236b4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ben 10",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b0f019cd-c3a3-43d6-9fe6-c7c9c4f621aa",
                "path": "product\/Bolo_Ben_10.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "62a63155-bae9-475a-acbc-2cd3b831238e",
        "product_description": "Bolo Decorado Peter Rabbit",
        "product_details": "Bolo Decorado Peter Rabbit",
        "product_unique_name": "bolo-decorado-peter-rabbit",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Peter_Rabbit.jpg",
        "category": [
            {
                "id": "82485f1b-4607-4f37-8329-7e637a2a94eb",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Peter Rabbit",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9b8d88dd-873c-49e4-9415-3a8730f25521",
                "path": "product\/Bolo_Peter_Rabbit.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "73446e67-c42c-4087-b7a1-bf7d8a72149b",
        "product_description": "Brigadeiro Decorado Futebol (Internacional) ",
        "product_details": "Brigadeiro Decorado Futebol (Internacional) ",
        "product_unique_name": "brigadeiro-decorado-futebol-internacional",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Brigadeiro_decorado_Futebol_Inter.jpg",
        "category": [
            {
                "id": "91756096-5f2e-42ce-90be-69223d4163a8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Futebol",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a7b831f7-c8f0-4aaa-8662-8d31fa4f46f2",
                "path": "product\/Brigadeiro_decorado_Futebol_Inter.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "b5b1fdf9-5ece-4451-b241-577715099085",
        "product_description": "Bolo Decorado Futebol",
        "product_details": "Bolo Decorado Futebol",
        "product_unique_name": "bolo-decorado-futebol",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Futebol.jpg",
        "category": [
            {
                "id": "91756096-5f2e-42ce-90be-69223d4163a8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Futebol",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "dec72b0b-6a41-4c44-ab81-74be2a647d95",
                "path": "product\/Bolo_Futebol.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "0948577d-b83d-43c2-b87d-3a95fc96f992",
        "product_description": "Bolo Decorado Grêmio ",
        "product_details": "Bolo Decorado Grêmio ",
        "product_unique_name": "bolo-decorado-gremio",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Grêmio.jpg",
        "category": [
            {
                "id": "91756096-5f2e-42ce-90be-69223d4163a8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Futebol",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8b1e3ee0-449b-46a8-b978-b48bd29ff4df",
                "path": "product\/Bolo_Decorado_Grêmio.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "e1edaff2-5fa5-4ad5-863a-7c5660175e49",
        "product_description": "Bolo Decorado Esportes ",
        "product_details": "Bolo Decorado Esportes ",
        "product_unique_name": "bolo-decorado-esportes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Esportes.jpg",
        "category": [
            {
                "id": "91756096-5f2e-42ce-90be-69223d4163a8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Futebol",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "76222492-9622-4316-9636-0b81222e825a",
                "path": "product\/Bolo_Decorado_Esportes.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "951cca72-3c93-48a3-b94d-6f3695ce71e1",
        "product_description": "Bolo Decorado Grêmio (Opção 2)",
        "product_details": "Bolo Decorado Grêmio (Opção 2)",
        "product_unique_name": "bolo-decorado-gremio-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Grêmio_(Opção_2).jpg",
        "category": [
            {
                "id": "91756096-5f2e-42ce-90be-69223d4163a8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Futebol",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1669730b-6abe-42eb-8d1b-53a2f4c619ae",
                "path": "product\/Bolo_Decorado_Grêmio_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "17425502-60d7-494f-94f0-c71832d1757b",
        "product_description": "Cupcakes Decorados Grêmio",
        "product_details": "Cupcakes Decorados Grêmio",
        "product_unique_name": "cupcakes-decorados-gremio",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Grêmio.jpg",
        "category": [
            {
                "id": "91756096-5f2e-42ce-90be-69223d4163a8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Futebol",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5c04a06e-6317-49a0-a7aa-2d2ea6db20d3",
                "path": "product\/Cupcakes_Decorados_Grêmio.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "1b8304f0-7e1a-4d32-b370-2c1a6b4eaf48",
        "product_description": "Doces Decorados Grêmio",
        "product_details": "Doces Decorados Grêmio",
        "product_unique_name": "doces-decorados-gremio",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Brigadeiro_decorado_Futebol_Grêmio.jpg",
        "category": [
            {
                "id": "91756096-5f2e-42ce-90be-69223d4163a8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Futebol",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c02abbe2-c129-4ac1-8f6c-7578d48ad53a",
                "path": "product\/Brigadeiro_decorado_Futebol_Grêmio.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "4c4c3be8-4dd7-4579-bd44-bbb44c61a144",
        "product_description": "Bolo Decorado Grêmio (Opção 3)",
        "product_details": "Bolo Decorado Grêmio (Opção 3)",
        "product_unique_name": "bolo-decorado-gremio-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Grêmio_(Opção_3).jpg",
        "category": [
            {
                "id": "91756096-5f2e-42ce-90be-69223d4163a8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Futebol",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7846805c-5919-4a33-bdd4-a8089b62a87f",
                "path": "product\/Bolo_Decorado_Grêmio_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "6f724892-e9bc-4fd6-9613-2c48759eba5c",
        "product_description": "Doces Decorados Grêmio (Opção 2)",
        "product_details": "Doces Decorados Grêmio (Opção 2)",
        "product_unique_name": "doces-decorados-gremio-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Grêmio.jpg",
        "category": [
            {
                "id": "91756096-5f2e-42ce-90be-69223d4163a8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Futebol",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "337aa854-0ba7-495d-8b51-ae4904bd440d",
                "path": "product\/Doces_Decorados_Grêmio.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "51f2e238-4028-4289-a1cc-97a4033caa63",
        "product_description": "Bolo Decorado Futebol (Opção 2)",
        "product_details": "Bolo Decorado Futebol (Opção 2)",
        "product_unique_name": "bolo-decorado-futebol-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Futebol.jpg",
        "category": [
            {
                "id": "91756096-5f2e-42ce-90be-69223d4163a8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Futebol",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f9440ba8-a1d2-48d0-a53a-21bd9f81d003",
                "path": "product\/Bolo_Decorado_Futebol.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "1ad40ab9-6bee-4740-82c9-71849c0a7acb",
        "product_description": "Bolo Decorado Festa Junina",
        "product_details": "Bolo Decorado Festa Junina",
        "product_unique_name": "bolo-decorado-festa-junina",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Festa_Junina.jpg",
        "category": [
            {
                "id": "2c9495d8-8e7d-4989-a305-6424e4ee9154",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bolos de Festa Junina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "66368038-b31d-41fc-a4ee-0c856125c220",
                "path": "product\/Bolo_Festa_Junina.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "5d9b3d69-fcd8-407d-a564-4e762993f409",
        "product_description": "Bolo Decorado Festa Junina (Baunilha com creme de ninho e nutella)",
        "product_details": "Bolo Decorado Festa Junina (Baunilha com creme de ninho e nutella)",
        "product_unique_name": "bolo-decorado-festa-junina-baunilha-com-creme-de-ninho-e-nutella",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_São_João_Antonio_11.jpg",
        "category": [
            {
                "id": "2c9495d8-8e7d-4989-a305-6424e4ee9154",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bolos de Festa Junina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7e4eb3f3-164a-48d7-91c0-4449bcd58477",
                "path": "product\/Bolo_São_João_Antonio_11.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "ad9ae56a-1c66-423e-8b7a-d8fcaea09346",
        "product_description": "Doces Decorados Festa Junina ",
        "product_details": "Doces Decorados Festa Junina ",
        "product_unique_name": "doces-decorados-festa-junina",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Doces_Sao_Joao_Antonio_1.jpg",
        "category": [
            {
                "id": "4f945388-a245-4313-8255-1a355020e52c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Doces de Festa Junina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a660e4bc-e806-42da-9e81-fa4d8b9402a9",
                "path": "product\/Doces_Sao_Joao_Antonio_1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "47a9ac47-209d-4768-bf6c-84a64dd48688",
        "product_description": "Fudges Decorados Festa Junina",
        "product_details": "Fudges Decorados Festa Junina",
        "product_unique_name": "fudges-decorados-festa-junina",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Fudges_sao_joao_2_antonio_1.jpg",
        "category": [
            {
                "id": "4f945388-a245-4313-8255-1a355020e52c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Doces de Festa Junina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ab68d2f8-b519-4be9-840c-09eb6a13b88b",
                "path": "product\/Fudges_sao_joao_2_antonio_1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "5bbd4bdc-32d9-4ba8-9f8b-94089fa678f2",
        "product_description": "Fudges São João (Opção 2)",
        "product_details": "Fudges São João (Opção 2)",
        "product_unique_name": "fudges-sao-joao-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Fudges_Sao_Joao_Antonio_1_ano.jpg",
        "category": [
            {
                "id": "4f945388-a245-4313-8255-1a355020e52c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Doces de Festa Junina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1a1d67e7-c371-41cb-9813-4ed98ec42277",
                "path": "product\/Fudges_Sao_Joao_Antonio_1_ano.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "4703b942-3013-452a-b252-2410c47cd7e5",
        "product_description": "Bolo Decorado Dia das Mães",
        "product_details": "Bolo Decorado Dia das Mães",
        "product_unique_name": "bolo-decorado-dia-das-maes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-02-02T21:47:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_dia_das_Mães.jpg",
        "category": [
            {
                "id": "1d724543-7ad8-47b2-9436-b80a5ee1e0d9",
                "theme_id": "1b131c45-363f-497d-a69a-e44df260e40e",
                "category_description": "Bolos Dia das Mães",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "6ce5991c-7202-4771-8d5e-a53c9b277164",
                "event_description": "Dia das Mães",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "9e2dcfcf-2a72-4b66-988f-1a3fb1c7ffcf",
                "path": "product\/Bolo_dia_das_Mães.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "e6cd7d9f-48a5-4d85-92de-d0771bd7e2c1",
        "product_description": "Bolo Decorado Toy Story",
        "product_details": "Bolo Decorado Toy Story",
        "product_unique_name": "bolo-decorado-toy-story",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Toy_Story.jpg",
        "category": [
            {
                "id": "15e76d51-f3c5-4549-90d7-7cf348ffb694",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Toy Story",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "719f65c4-6f8c-4486-8750-e18974853499",
                "path": "product\/Bolo_Toy_Story.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "a48133d7-e629-4f33-9f6d-c9856d47538a",
        "product_description": "Bolo Decorado Toy Story (Opção 2)",
        "product_details": "Bolo Decorado Toy Story (Opção 2)",
        "product_unique_name": "bolo-decorado-toy-story-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Toy_Story.jpg",
        "category": [
            {
                "id": "15e76d51-f3c5-4549-90d7-7cf348ffb694",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Toy Story",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "34c37b5b-a73b-40e7-954e-60974d349187",
                "path": "product\/Bolo_Decorado_Toy_Story.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "7e8ca826-517b-43ce-9a3f-0d2324e54488",
        "product_description": "Bolo Decorado Toy Story (Opção 3)",
        "product_details": "Bolo Decorado Toy Story (Opção 3)",
        "product_unique_name": "bolo-decorado-toy-story-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Toy_Story_(Opção_2).jpg",
        "category": [
            {
                "id": "15e76d51-f3c5-4549-90d7-7cf348ffb694",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Toy Story",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "092df911-5caa-41cb-a1f8-d35b6d1ec677",
                "path": "product\/Bolo_Decorado_Toy_Story_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "80287e4c-167a-4a9c-a9a3-337fe56ed6b2",
        "product_description": "Cupcakes Decorados Toy Story",
        "product_details": "Cupcakes Decorados Toy Story",
        "product_unique_name": "cupcakes-decorados-toy-story",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Toy_Story.jpg",
        "category": [
            {
                "id": "15e76d51-f3c5-4549-90d7-7cf348ffb694",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Toy Story",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "337bdaf8-7791-4848-b85e-531c72fcdbbe",
                "path": "product\/Cupcakes_Decorados_Toy_Story.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "20f6e957-9e41-4da9-b093-54933665356e",
        "product_description": "Bolo Decorado Toy Story (Opção 4)",
        "product_details": "Bolo Decorado Toy Story (Opção 4)",
        "product_unique_name": "bolo-decorado-toy-story-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Toy_Story_(Opção_4).jpg",
        "category": [
            {
                "id": "15e76d51-f3c5-4549-90d7-7cf348ffb694",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Toy Story",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "aef4d4ec-5240-4ccb-9640-e1d82d5a769b",
                "path": "product\/Bolo_Decorado_Toy_Story_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "e339cf01-aa07-4335-a468-421d4a949b83",
        "product_description": "Bolo Decorado Star Wars",
        "product_details": "Bolo Decorado Star Wars",
        "product_unique_name": "bolo-decorado-star-wars",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Star_Wars.jpg",
        "category": [
            {
                "id": "cec75408-e311-476b-8d93-16d33899620d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Star Wars",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bfe8f00e-a878-424c-a533-8a974b7bbe2e",
                "path": "product\/Bolo_Decorado_Star_Wars.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "2468acfc-3573-4397-99a4-391229d1acdb",
        "product_description": "Bolo Decorado Star Wars (Opção 2)",
        "product_details": "Bolo Decorado Star Wars (Opção 2)",
        "product_unique_name": "bolo-decorado-star-wars-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Star_Wars_(Opção_2).jpg",
        "category": [
            {
                "id": "cec75408-e311-476b-8d93-16d33899620d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Star Wars",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7c699f7e-ede8-4fae-a8f7-cb6386645e14",
                "path": "product\/Bolo_Decorado_Star_Wars_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "637305db-bba0-4c05-8585-38200ae124f8",
        "product_description": "Bolo Decorado Ursinho Pooh",
        "product_details": "Bolo Decorado Ursinho Pooh",
        "product_unique_name": "bolo-decorado-ursinho-pooh",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Ursinho_Pooh.jpg",
        "category": [
            {
                "id": "c7cad0da-b635-4b61-8ef6-a1106d88c4aa",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinho Pooh",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "806e2105-408d-4a65-bb0e-374110f31aae",
                "path": "product\/Bolo_Decorado_Ursinho_Pooh.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "7dd7d174-2d2c-45d8-ae4f-9e5c7667643f",
        "product_description": "Bolo Decorado Ursinho Pooh (Opção 2)",
        "product_details": "Bolo Decorado Ursinho Pooh (Opção 2)",
        "product_unique_name": "bolo-decorado-ursinho-pooh-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Ursinho_Pooh.jpg",
        "category": [
            {
                "id": "c7cad0da-b635-4b61-8ef6-a1106d88c4aa",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinho Pooh",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3c882544-32d3-40d7-b1fc-d941325df937",
                "path": "product\/Bolo_Decorado_Ursinho_Pooh.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "c9e9c253-e0d5-4ef0-b524-3f5ae66359a6",
        "product_description": "Bolo Decorado Ursinho Pooh (Opção 3)",
        "product_details": "Bolo Decorado Ursinho Pooh (Opção 3)",
        "product_unique_name": "bolo-decorado-ursinho-pooh-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Ursinho_Pooh_(Opção_2).jpg",
        "category": [
            {
                "id": "c7cad0da-b635-4b61-8ef6-a1106d88c4aa",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinho Pooh",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e8761fb1-0ff6-4a22-8beb-8086c7a67f4d",
                "path": "product\/Bolo_Decorado_Ursinho_Pooh_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "4eba55ec-a19b-4e57-a97c-22c59eabdd0a",
        "product_description": "Bolo Decorado Ursinho Pooh (Opção 4)",
        "product_details": "Bolo Decorado Ursinho Pooh (Opção 4)",
        "product_unique_name": "bolo-decorado-ursinho-pooh-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Ursinho_Pooh_(Opção_3).jpg",
        "category": [
            {
                "id": "c7cad0da-b635-4b61-8ef6-a1106d88c4aa",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinho Pooh",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9d47c307-47f3-43a7-a787-710fc4b53e5b",
                "path": "product\/Bolo_Decorado_Ursinho_Pooh_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "2a178f2b-33ae-4d39-91e5-24d77cfa5c39",
        "product_description": "Bolo Decorado Baby Shark",
        "product_details": "Bolo Decorado Baby Shark",
        "product_unique_name": "bolo-decorado-baby-shark",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark.jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "243ad34f-bc8a-4275-88ef-76e41764c3a1",
                "path": "product\/Bolo_Decorado_Baby_Shark.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "ecf5b9e0-9f57-47fa-83b5-e12a47528b5d",
        "product_description": "Pirulitos Decorados Baby Shark",
        "product_details": "Pirulitos Decorados Baby Shark",
        "product_unique_name": "pirulitos-decorados-baby-shark",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Baby_Shark.jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2f8de169-65a0-4874-9c05-f8b13761879c",
                "path": "product\/Pirulitos_Decorados_Baby_Shark.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "40694fe0-d510-4577-8ebc-4956a1d65a8d",
        "product_description": "Doces Decorados Baby Shark",
        "product_details": "Doces Decorados Baby Shark",
        "product_unique_name": "doces-decorados-baby-shark",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Baby_Shark.jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "efd87584-91eb-4197-b8cd-5b9119efe6ab",
                "path": "product\/Doces_Decorados_Baby_Shark.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "3b2ef597-78fb-4334-9251-382c9974ebfa",
        "product_description": "Bolo Decorado Baby Shark (Opção 2) ",
        "product_details": "Bolo Decorado Baby Shark (Opção 2) ",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_2)1.jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c2318c7b-d675-4986-85b9-211944815bfb",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_2)1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "c2a80ffa-e29c-4e06-95f9-8c31e0981e55",
        "product_description": "Bolo Decorado Baby Shark (Opção 3)",
        "product_details": "Bolo Decorado Baby Shark (Opção 3)",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_3).jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "fe670f77-86d4-430e-8bf4-c79009bae2b7",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "ee45a5e0-6320-4144-9c85-1e63ad24c0e4",
        "product_description": "Bolo Decorado Baby Shark (Opção 4)",
        "product_details": "Bolo Decorado Baby Shark (Opção 4)",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_4).jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1e6052c8-9b70-4a69-aa60-121f474912a9",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "952e4448-ef51-4d38-8fe9-b724363dc6e2",
        "product_description": "Cupcakes Decorados Baby Shark ",
        "product_details": "Cupcakes Decorados Baby Shark ",
        "product_unique_name": "cupcakes-decorados-baby-shark",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Baby_Shark.jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e835e7ee-dc3f-4415-be58-7737f4fab593",
                "path": "product\/Cupcakes_Decorados_Baby_Shark.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "d574d145-a25f-4532-8298-b8625e58ca51",
        "product_description": "Cake Pops Decorados Baby Shark",
        "product_details": "Cake Pops Decorados Baby Shark",
        "product_unique_name": "cake-pops-decorados-baby-shark",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Cake_Pops_Decorados_Baby_Shark.jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b0c66c9d-01ad-4dcb-b9f6-b4eeb3c56ac4",
                "path": "product\/Cake_Pops_Decorados_Baby_Shark.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "fab83355-46ee-4105-95fc-3c37d3768838",
        "product_description": "Bolo Decorado Baby Shark (Opção 5)",
        "product_details": "Bolo Decorado Baby Shark (Opção 5)",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_5).jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "09616d7d-286d-42cc-8a8b-097caa0d3446",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_5).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "ef164944-2ea6-4055-9c8f-ede03d1b4321",
        "product_description": "Bolo Decorado Baby Shark (Opção 6)",
        "product_details": "Bolo Decorado Baby Shark (Opção 6)",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-6",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-01-26T14:23:37.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_6).jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2c00af18-4d5e-41a0-b80d-4dec3a4c1362",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_6).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "c8ae3999-0f35-4998-a167-e8ea05109235",
        "product_description": "Bolo Decorado Baby Shark (Opção 7)",
        "product_details": "Bolo Decorado Baby Shark (Opção 7)",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-7",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_7).jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3724ebd8-4d04-43c9-83ac-faa11ae15c7d",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_7).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "69885864-ed31-42df-8814-19fcb175f7c4",
        "product_description": "Bolo Decorado Baby Shark (Opção 8)",
        "product_details": "Bolo Decorado Baby Shark (Opção 8)",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-8",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_8).jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "048653e9-1522-4108-a200-c7950455944c",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_8).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "47916f9b-e9fd-47c2-8458-f08414f47aad",
        "product_description": "Bolo Decorado Baby Shark (Opção 9)",
        "product_details": "Bolo Decorado Baby Shark (Opção 9)",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-9",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_9).jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "85ffcefb-9b04-4933-b4b3-fb32eb59e818",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_9).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "036a5d08-06aa-4947-b6d5-7adc3fa088b1",
        "product_description": "Bolo Decorado Baby Shark (Opção 10)",
        "product_details": "Bolo Decorado Baby Shark (Opção 10)",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-10",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_10).jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6fed28e5-7683-415d-a317-7f07241e868b",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_10).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "5334f9b8-b856-4800-940a-22b48f18c3ee",
        "product_description": "Bolo Decorado Baby Shark (Opção 11)",
        "product_details": "Bolo Decorado Baby Shark (Opção 11)",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-11",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_11).jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f43e7ebf-fb6a-42a7-9aca-723933d8e79c",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_11).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "f7ad4c10-d53e-48f5-a302-b2fded6ee642",
        "product_description": "Bolo Decorado Baby Shark (Opção 12) ",
        "product_details": "Bolo Decorado Baby Shark (Opção 12) ",
        "product_unique_name": "bolo-decorado-baby-shark-opcao-12",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Baby_Shark_(Opção_12).jpg",
        "category": [
            {
                "id": "fe7befbc-771c-4fa6-891a-2bc50df1cbd1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Baby Shark",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "530cc234-0e14-4443-bbd5-c73a14b70cee",
                "path": "product\/Bolo_Decorado_Baby_Shark_(Opção_12).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "76125184-470f-4d9c-b285-834bdc6cb987",
        "product_description": "Bolo Decorado LOL",
        "product_details": "Bolo Decorado LOL",
        "product_unique_name": "bolo-decorado-lol",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_LOL.jpg",
        "category": [
            {
                "id": "6e0cbaf7-3a06-4a6f-9d0d-5fa79e61c3a9",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "LOL",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0dbe99ca-12c7-4ed7-b938-42e11897875b",
                "path": "product\/Bolo_Decorado_LOL.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "486a207b-e582-4a9b-9850-2ee48c4a8441",
        "product_description": "Bolo Decorado LOL (Opção 2)",
        "product_details": "Bolo Decorado LOL (Opção 2)",
        "product_unique_name": "bolo-decorado-lol-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_LOL_(Opção_2).jpg",
        "category": [
            {
                "id": "6e0cbaf7-3a06-4a6f-9d0d-5fa79e61c3a9",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "LOL",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "472edf3f-3147-4898-9e84-dcc0c832496d",
                "path": "product\/Bolo_Decorado_LOL_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "839bf400-9186-4700-b113-3a171ddaa3ab",
        "product_description": "Cupcakes Decorados Asterix e Obelix ",
        "product_details": "Cupcakes Decorados Asterix e Obelix ",
        "product_unique_name": "cupcakes-decorados-asterix-e-obelix",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Asterix_e_Obelix_cupcakes_decorados.jpg",
        "category": [
            {
                "id": "8c03740c-b774-43f0-b3f2-c27c6ef71e92",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Asterix e Obelix",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b5ca0530-a4fc-4fcc-a481-9f99c3fc27eb",
                "path": "product\/Asterix_e_Obelix_cupcakes_decorados.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "d45720e7-c451-40c6-b599-01e997df8c77",
        "product_description": "Doces Decorados Asterix e Obelix",
        "product_details": "Doces Decorados Asterix e Obelix",
        "product_unique_name": "doces-decorados-asterix-e-obelix",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Asterix_e_Obelix_doces_decorados.jpg",
        "category": [
            {
                "id": "8c03740c-b774-43f0-b3f2-c27c6ef71e92",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Asterix e Obelix",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ef6ce90d-3722-4052-b883-41604f66c6a9",
                "path": "product\/Asterix_e_Obelix_doces_decorados.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "31925387-3206-433d-9681-719a55b733ec",
        "product_description": "Bolo Decorado Asterix e Obelix ",
        "product_details": "Bolo Decorado Asterix e Obelix ",
        "product_unique_name": "bolo-decorado-asterix-e-obelix",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Asterix_e_Obelix_Bolo_Decorado.jpg",
        "category": [
            {
                "id": "8c03740c-b774-43f0-b3f2-c27c6ef71e92",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Asterix e Obelix",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bf6d590f-8cc4-4933-acf0-cf9621e7c6dc",
                "path": "product\/Asterix_e_Obelix_Bolo_Decorado.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "148777a1-267d-468c-823e-5a05eaa997eb",
        "product_description": "Pirulitos Decorados Cry Baby",
        "product_details": "Pirulitos Decorados Cry Baby",
        "product_unique_name": "pirulitos-decorados-cry-baby",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Cry_Baby.jpg",
        "category": [
            {
                "id": "8a2a2972-fbfb-4a4f-b880-61e9c3afb543",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Cry Baby",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "fa1537aa-506d-4f04-a560-948a52f304c0",
                "path": "product\/Pirulitos_Decorados_Cry_Baby.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "3977e801-a3e0-4c2a-880c-ab789b0a0492",
        "product_description": "Bolo Decorado Cry Baby",
        "product_details": "Bolo Decorado Cry Baby",
        "product_unique_name": "bolo-decorado-cry-baby",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Cry_Baby.jpg",
        "category": [
            {
                "id": "8a2a2972-fbfb-4a4f-b880-61e9c3afb543",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Cry Baby",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cd567a66-2604-4fc4-a82b-4d915ad0cfb8",
                "path": "product\/Bolo_Decorado_Cry_Baby.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "992328cd-4378-4e21-bf14-628fe0434e01",
        "product_description": "Doces Decorados Sylvaninan Families",
        "product_details": "Doces Decorados Sylvaninan Families",
        "product_unique_name": "doces-decorados-sylvaninan-families",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Sylvanian_Families.jpg",
        "category": [
            {
                "id": "8b304ecf-41dd-4e2d-acf9-24fae464534f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Sylvanian Families",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ca291431-832e-4ed8-9df5-0d7a4fa4df5f",
                "path": "product\/Doces_Decorados_Sylvanian_Families.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "05a0e0a3-43c4-47f6-8e79-bcc596b6c7b1",
        "product_description": "Cupcakes Decorados Sylvaninan Families",
        "product_details": "Cupcakes Decorados Sylvaninan Families",
        "product_unique_name": "cupcakes-decorados-sylvaninan-families",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Sylvaninan_Famillies.jpg",
        "category": [
            {
                "id": "8b304ecf-41dd-4e2d-acf9-24fae464534f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Sylvanian Families",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5479c0c7-30b9-4324-9810-b4dd031a5583",
                "path": "product\/Cupcakes_Decorados_Sylvaninan_Famillies.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "fe73ff91-75b6-4ef0-9257-8ad474318f82",
        "product_description": "Bolo Decorado Sylvaninan Families",
        "product_details": "Bolo Decorado Sylvaninan Families",
        "product_unique_name": "bolo-decorado-sylvaninan-families",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Sylvanian_Famillies.jpg",
        "category": [
            {
                "id": "8b304ecf-41dd-4e2d-acf9-24fae464534f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Sylvanian Families",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6498a8f9-e1b9-4cdb-987c-a1ce2412a08c",
                "path": "product\/Bolo_Sylvanian_Famillies.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "3f3f344a-3853-4e64-bdf8-24d1ddd38c79",
        "product_description": "Bolo Decorado Bob Esponja Sundae",
        "product_details": "Bolo Decorado Bob Esponja Sundae",
        "product_unique_name": "bolo-decorado-bob-esponja-sundae",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Sundae_Bob_Esponja.jpg",
        "category": [
            {
                "id": "51b82fda-bed5-4cf6-adac-149cbb1917a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bob Esponja",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8cdbbcda-4d7e-408c-b8d9-0c8a30ecdd3c",
                "path": "product\/Bolo_Decorado_Sundae_Bob_Esponja.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "f8183100-7bcb-4a11-8096-da51abe4f8be",
        "product_description": "Bolo Decorado Bob Esponja",
        "product_details": "Bolo Decorado Bob Esponja",
        "product_unique_name": "bolo-decorado-bob-esponja",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Bob_Esponja.jpg",
        "category": [
            {
                "id": "51b82fda-bed5-4cf6-adac-149cbb1917a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bob Esponja",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b6f2c5ef-008d-48f6-bcfc-ae851aab2c15",
                "path": "product\/Bolo_Decorado_Bob_Esponja.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "f858e189-c1e6-4e75-84f2-ccadb4d89f21",
        "product_description": "Cupcakes Decorados Bob Esponja",
        "product_details": "Cupcakes Decorados Bob Esponja",
        "product_unique_name": "cupcakes-decorados-bob-esponja",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Bob_Esponja.jpg",
        "category": [
            {
                "id": "51b82fda-bed5-4cf6-adac-149cbb1917a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bob Esponja",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "019ac41b-6749-4af3-9a9d-0a56acea13fd",
                "path": "product\/Cupcakes_Decorados_Bob_Esponja.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "6a3cd267-3acf-4f9a-b007-e53380c133df",
        "product_description": "Cupcakes Decorados Pequena Sereia",
        "product_details": "Cupcakes Decorados Pequena Sereia",
        "product_unique_name": "cupcakes-decorados-pequena-sereia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Pequena_Sereia.jpg",
        "category": [
            {
                "id": "f9fee4cf-160e-4600-8a10-27cd3fef175c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pequena Sereia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "00d2085a-d746-4ba1-a39b-a17c6f2d18d2",
                "path": "product\/Cupcakes_Decorados_Pequena_Sereia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "c246e42d-b79f-474d-ab39-5172a89e6994",
        "product_description": "Pirulitos Decorados Pequena Sereia",
        "product_details": "Pirulitos Decorados Pequena Sereia",
        "product_unique_name": "pirulitos-decorados-pequena-sereia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Pequena_Sereia.jpg",
        "category": [
            {
                "id": "f9fee4cf-160e-4600-8a10-27cd3fef175c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pequena Sereia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "094e855e-dc2f-4c8d-b855-51ce13fb5532",
                "path": "product\/Pirulitos_Decorados_Pequena_Sereia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "3aeff741-13db-4729-9183-f6f96961a1fe",
        "product_description": "Doces Decorados Pequena Sereia",
        "product_details": "Doces Decorados Pequena Sereia",
        "product_unique_name": "doces-decorados-pequena-sereia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Pequena_Sereia.jpg",
        "category": [
            {
                "id": "f9fee4cf-160e-4600-8a10-27cd3fef175c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pequena Sereia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bcd5088b-d047-4d4d-8d45-c4324cc2640b",
                "path": "product\/Doces_Decorados_Pequena_Sereia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "c6693da4-9b0f-4f45-8c57-3890f6ec1d9c",
        "product_description": "Bolo Decorado Pequena Sereia",
        "product_details": "Bolo Decorado Pequena Sereia",
        "product_unique_name": "bolo-decorado-pequena-sereia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Pequena_Sereia.jpg",
        "category": [
            {
                "id": "f9fee4cf-160e-4600-8a10-27cd3fef175c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pequena Sereia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9bf1c393-944d-4416-b8e0-b236f5914eda",
                "path": "product\/Bolo_Decorado_Pequena_Sereia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "f33dac6b-b58b-41e8-8778-8c16cfaf7b68",
        "product_description": "Bolo Decorado Pequena Sereia (Opção 2)",
        "product_details": "Bolo Decorado Pequena Sereia (Opção 2)",
        "product_unique_name": "bolo-decorado-pequena-sereia-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Pequena_Sereia_(Opção_2).jpg",
        "category": [
            {
                "id": "f9fee4cf-160e-4600-8a10-27cd3fef175c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pequena Sereia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f50e7ba8-3bbd-4fd8-97bd-0a381afeabd5",
                "path": "product\/Bolo_Decorado_Pequena_Sereia_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "7bd287e3-0bbc-4358-a955-a0e117998cb9",
        "product_description": "Fudges Decorados Pequena Sereia",
        "product_details": "Fudges Decorados Pequena Sereia",
        "product_unique_name": "fudges-decorados-pequena-sereia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Fudges_Decorados_Pequena_Sereia.jpg",
        "category": [
            {
                "id": "f9fee4cf-160e-4600-8a10-27cd3fef175c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pequena Sereia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "159677e2-8b76-4712-a6e7-29f5e4889c55",
                "path": "product\/Fudges_Decorados_Pequena_Sereia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "dc8d1908-10d8-4622-9242-ebaa5bd1be71",
        "product_description": "Cupcakes Decorados Pequena Sereia (Opção 2)",
        "product_details": "Cupcakes Decorados Pequena Sereia (Opção 2)",
        "product_unique_name": "cupcakes-decorados-pequena-sereia-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Pequena_Sereia1.jpg",
        "category": [
            {
                "id": "f9fee4cf-160e-4600-8a10-27cd3fef175c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pequena Sereia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c39a8ae0-f105-4f7b-9589-d1b63b0d7497",
                "path": "product\/Cupcakes_Decorados_Pequena_Sereia1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "eed53623-8c4a-4a9d-84c1-e47c0d6a0743",
        "product_description": "Bolo Decorado Bita ",
        "product_details": "Bolo Decorado Bita ",
        "product_unique_name": "bolo-decorado-bita",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bita_Opção_2.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7c20987b-5dcb-4d5f-9b46-97b089e8daf6",
                "path": "product\/Bolo_Bita_Opção_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "f7a5dce9-26c4-4ab1-9201-70206ba31966",
        "product_description": "Bolo Decorado Bita (Opção 3)",
        "product_details": "Bolo Decorado Bita (Opção 3)",
        "product_unique_name": "bolo-decorado-bita-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bita_opção_3.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6a77fd38-eb39-4952-b575-21307fb5d2ed",
                "path": "product\/Bolo_Bita_opção_3.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "7a127d62-dc28-4ceb-a953-f2b51f02d1d4",
        "product_description": "Bolo Decorado Bita (Opção 4)",
        "product_details": "Bolo Decorado Bita (Opção 4)",
        "product_unique_name": "bolo-decorado-bita-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bita_Opção_4.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2405dd06-aa4a-427f-9372-8dfbd0be3b58",
                "path": "product\/Bolo_Bita_Opção_4.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "32a04bd5-eac4-4d6c-a582-a40ba7f3c988",
        "product_description": "Doces Decorados Bita ",
        "product_details": "Doces Decorados Bita ",
        "product_unique_name": "doces-decorados-bita",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Bita.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b27ae807-72a3-4722-9f45-d25fef677131",
                "path": "product\/Doces_Decorados_Bita.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "62699ddb-dc06-4b32-8287-a5ddcfe4aa56",
        "product_description": "Bolo Decorado Bita (Opção 5)",
        "product_details": "Bolo Decorado Bita (Opção 5)",
        "product_unique_name": "bolo-decorado-bita-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bita_Opção_5.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ccc65dc5-42bb-40bd-9372-c877f6bc305d",
                "path": "product\/Bolo_Bita_Opção_5.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "ea99322a-4832-4f3f-a7e5-25c011c435f9",
        "product_description": "Cupcakes Decorados Bita",
        "product_details": "Cupcakes Decorados Bita",
        "product_unique_name": "cupcakes-decorados-bita",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Bita.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "46af55c9-987c-449b-bbc6-73dc2f8cbb8b",
                "path": "product\/Cupcakes_Decorados_Bita.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "83f8bc1a-4c96-486a-ab95-de9f181ef817",
        "product_description": "Fudges Decorados Bita",
        "product_details": "Fudges Decorados Bita",
        "product_unique_name": "fudges-decorados-bita",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Fudges_Decorados_Bita.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a4c21483-6a80-4de7-a763-d649dacd0f42",
                "path": "product\/Fudges_Decorados_Bita.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "0e12c849-9a63-4f31-a3b0-026c21997b18",
        "product_description": "Doces Decorados Bita (Opção 2)",
        "product_details": "Doces Decorados Bita (Opção 2)",
        "product_unique_name": "doces-decorados-bita-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Bita2.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "48f0ebda-86b4-43df-81f9-80790fb5ae24",
                "path": "product\/Doces_Decorados_Bita2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "50e074da-0681-4824-ba20-4d1ba5d1297b",
        "product_description": "Bolo Decorado Bita (Opção 6)",
        "product_details": "Bolo Decorado Bita (Opção 6)",
        "product_unique_name": "bolo-decorado-bita-opcao-6",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bita_Opção_6.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7dfb3e8e-8cc9-40a5-b575-b3387a4bd19c",
                "path": "product\/Bolo_Bita_Opção_6.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "940d4891-eb42-41aa-af62-d3931fa7b0d3",
        "product_description": "Cupcakes Decorados Bita (Opção 2)",
        "product_details": "Cupcakes Decorados Bita (Opção 2)",
        "product_unique_name": "cupcakes-decorados-bita-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Bita_(Opção_2).jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "53783acb-ed03-4be4-bb22-a8e521f10646",
                "path": "product\/Cupcakes_Decorados_Bita_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "0fcab4ed-3ad8-4ab2-9070-128da3a17523",
        "product_description": "Bolo Decorado Bita (Opção 7)",
        "product_details": "Bolo Decorado Bita (Opção 7)",
        "product_unique_name": "bolo-decorado-bita-opcao-7",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bita_Opção_7.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "453ca6f2-4732-4665-b523-87e900f89d62",
                "path": "product\/Bolo_Bita_Opção_7.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "fed45099-eb9e-4f20-a2d0-5c3693165777",
        "product_description": "Bolo Decorado Bita (Opção 8)",
        "product_details": "Bolo Decorado Bita (Opção 8)",
        "product_unique_name": "bolo-decorado-bita-opcao-8",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Bita_Opção_8.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "18e9e7c5-c44e-4361-9a14-3997f12d2abb",
                "path": "product\/Bolo_Decorado_Bita_Opção_8.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "79c20e17-e9be-48d5-ba9f-7eb67981dfe1",
        "product_description": "Bolo Decorado Bita (Opção 9)",
        "product_details": "Bolo Decorado Bita (Opção 9)",
        "product_unique_name": "bolo-decorado-bita-opcao-9",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bita_Opção_9.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "50ef335c-2539-4cc2-a7b2-68b3ea7c11bf",
                "path": "product\/Bolo_Bita_Opção_9.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "f01b4566-760f-40f5-b583-c2c6c588700a",
        "product_description": "Bolo Decorado Bita (Opção 10)",
        "product_details": "Bolo Decorado Bita (Opção 10)",
        "product_unique_name": "bolo-decorado-bita-opcao-10",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bita_Opção_10.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c133ca6b-ae30-48cd-a811-545ebb6b0da3",
                "path": "product\/Bolo_Bita_Opção_10.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "ae1a7d74-95de-45e3-ac6c-cc1a725e683e",
        "product_description": "Bolo Decorado Bita (Opção 11)",
        "product_details": "Bolo Decorado Bita (Opção 11)",
        "product_unique_name": "bolo-decorado-bita-opcao-11",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bita_Opção_11.jpg",
        "category": [
            {
                "id": "ca0349de-8210-4689-93c8-457d1c4ad3be",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2167880d-592d-41d9-bd2b-4dbf73897615",
                "path": "product\/Bolo_Bita_Opção_11.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "81570fe1-1e53-4e44-875a-4d00bd60089c",
        "product_description": "Cupcakes Decorados Branca de Neve",
        "product_details": "Cupcakes Decorados Branca de Neve",
        "product_unique_name": "cupcakes-decorados-branca-de-neve",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Branca_de_Neve.jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "19eabea4-3a85-427b-bcec-4a6d3e1f0c56",
                "path": "product\/Cupcakes_Decorados_Branca_de_Neve.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "429ee5bd-b534-4d96-a244-c1c703662fcb",
        "product_description": "Doces Decorados Branca de Neve",
        "product_details": "Doces Decorados Branca de Neve",
        "product_unique_name": "doces-decorados-branca-de-neve",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Branca_de_Neve.jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9e8b62e5-d732-4677-b8c9-1600ebacd975",
                "path": "product\/Doces_Decorados_Branca_de_Neve.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "ffd05643-f2e8-4fb5-ae74-f3c59cafcf79",
        "product_description": "Bolo Decorado Branca de Neve",
        "product_details": "Bolo Decorado Branca de Neve",
        "product_unique_name": "bolo-decorado-branca-de-neve",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Branca_de_Neve.jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4d163bfc-d5a2-4980-b66c-8024513214e3",
                "path": "product\/Bolo_Decorado_Branca_de_Neve.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "a50dff63-d333-4edf-9067-3f709bea4ea7",
        "product_description": "Bolo Branca de Neve ",
        "product_details": "Bolo Branca de Neve ",
        "product_unique_name": "bolo-branca-de-neve",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Branca_de_Neve_(Opção_1).jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2eedd0cb-1f0a-479a-82a4-13451d84028f",
                "path": "product\/Bolo_Branca_de_Neve_(Opção_1).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "86239adc-7002-4006-a4c0-583332b139bb",
        "product_description": "Mini Cupcakes Decorados Branca de Neve ",
        "product_details": "Mini Cupcakes Decorados Branca de Neve ",
        "product_unique_name": "mini-cupcakes-decorados-branca-de-neve",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Mini_Cupcakes_Decorados_Branca_de_Neve.jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1c98d008-ba41-4af0-96f6-0121e79f7bbc",
                "path": "product\/Mini_Cupcakes_Decorados_Branca_de_Neve.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:38.000000Z",
                "updated_at": "2023-01-26T14:23:38.000000Z"
            }
        ]
    },
    {
        "id": "9e1ead79-863b-4cc1-8691-0d6500b4a61c",
        "product_description": "Cookie no Palito decorado Branca de Neve ",
        "product_details": "Cookie no Palito decorado Branca de Neve ",
        "product_unique_name": "cookie-no-palito-decorado-branca-de-neve",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:38.000000Z",
        "updated_at": "2023-01-26T14:23:38.000000Z",
        "imagePath": "\/storage\/product\/Cookie_no_palito_Decorado_Branca_de_Neve.jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "02c7954f-6e61-4d4c-a41c-7aa30fa423fb",
                "path": "product\/Cookie_no_palito_Decorado_Branca_de_Neve.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "032bdc51-2867-4ba2-9f57-fd24a7c8edb8",
        "product_description": "Fudges Decorados Branca de Neve",
        "product_details": "Fudges Decorados Branca de Neve",
        "product_unique_name": "fudges-decorados-branca-de-neve",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Fudges_Decorados_Branca_de_Nevr.jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0a83ab26-92ce-47cb-81e1-ea716add7504",
                "path": "product\/Fudges_Decorados_Branca_de_Nevr.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "db9cb404-116e-41c6-9cc0-a4a254233060",
        "product_description": "Cupcakes Decorados Branca de Neve (Opção 2)",
        "product_details": "Cupcakes Decorados Branca de Neve (Opção 2)",
        "product_unique_name": "cupcakes-decorados-branca-de-neve-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Branca_de_Neve1.jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7c6242ed-ee90-48e5-988e-b64acd77c3c8",
                "path": "product\/Cupcakes_Decorados_Branca_de_Neve1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "1b566b0f-547d-4bde-863c-ee16747f6701",
        "product_description": "Bolo Decorado Branca de Neve (Opção 2)",
        "product_details": "Bolo Decorado Branca de Neve (Opção 2)",
        "product_unique_name": "bolo-decorado-branca-de-neve-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Branca_de_Neve_(Opção_2).jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f20c99b0-fef5-4987-b019-e701af10ade2",
                "path": "product\/Bolo_Branca_de_Neve_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "6f21676a-77b8-40f4-8f26-e52c4f77bb95",
        "product_description": "Colher de Brigadeiro Decorada Branca de Neve",
        "product_details": "Colher de Brigadeiro Decorada Branca de Neve",
        "product_unique_name": "colher-de-brigadeiro-decorada-branca-de-neve",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Colher_de_brigadeiro_Decorada_Branca_de_Neve.jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6e10f0b8-4d8f-49bf-b758-0bbd41856692",
                "path": "product\/Colher_de_brigadeiro_Decorada_Branca_de_Neve.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "f540b235-d6a5-4350-95b8-f0894a86e2cc",
        "product_description": "Cupcakes Decorados Branca de Neve (Opção 3)",
        "product_details": "Cupcakes Decorados Branca de Neve (Opção 3)",
        "product_unique_name": "cupcakes-decorados-branca-de-neve-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Branca_de_Neve_(Opção_2).jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4cb979c7-7bb7-4faa-8811-2d91fcb0b2c8",
                "path": "product\/Cupcakes_Decorados_Branca_de_Neve_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "64773d7c-55b6-4d56-9c04-ba5438f28937",
        "product_description": "Bolo Decorado Branca de Neve (Opção 3)",
        "product_details": "Bolo Decorado Branca de Neve (Opção 3)",
        "product_unique_name": "bolo-decorado-branca-de-neve-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Branca_de_Neve_(Opção_3).jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bc8bde83-b951-4ec1-947a-919008f5d9c0",
                "path": "product\/Bolo_Branca_de_Neve_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "eea5a163-9fdc-430d-9453-b05e223852c1",
        "product_description": "Cupcakes Decorados Branca de Neve (Opção 4)",
        "product_details": "Cupcakes Decorados Branca de Neve (Opção 4)",
        "product_unique_name": "cupcakes-decorados-branca-de-neve-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Branca_de_Neve_(Opção_3).jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b2759ce1-b77e-4186-b42c-0d462e5aa3d4",
                "path": "product\/Cupcakes_Decorados_Branca_de_Neve_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "22f04c97-8b0f-4db2-afd3-f47d115a1431",
        "product_description": "Bolo Decorado Marie",
        "product_details": "Bolo Decorado Marie",
        "product_unique_name": "bolo-decorado-marie",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Marie.jpg",
        "category": [
            {
                "id": "8e1ce01e-67d4-493d-8ddc-69764e4aa30b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Marie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "692e0a96-5334-47fd-9afe-d6bb8b95b0b4",
                "path": "product\/Bolo_Decorado_Marie.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "df5ead10-a0e8-4e13-a4c1-f2366a4dd3cb",
        "product_description": "Doces Decorados Marie",
        "product_details": "Doces Decorados Marie",
        "product_unique_name": "doces-decorados-marie",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Marie.jpg",
        "category": [
            {
                "id": "8e1ce01e-67d4-493d-8ddc-69764e4aa30b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Marie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b1cd80c3-d072-4e6f-922e-3c74de0d08f9",
                "path": "product\/Doces_Decorados_Marie.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "704802bb-774a-4ce2-a719-5c1a591d2faa",
        "product_description": "Cupcakes Decorados Marie ",
        "product_details": "Cupcakes Decorados Marie ",
        "product_unique_name": "cupcakes-decorados-marie",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Marie.jpg",
        "category": [
            {
                "id": "8e1ce01e-67d4-493d-8ddc-69764e4aa30b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Marie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "83c6cb37-57ce-4702-9478-1903d253b30b",
                "path": "product\/Cupcakes_Decorados_Marie.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "7927b3f4-60e5-4a73-b518-bae68763464e",
        "product_description": "Pirulitos Decorados Marie",
        "product_details": "Pirulitos Decorados Marie",
        "product_unique_name": "pirulitos-decorados-marie",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Marie.jpg",
        "category": [
            {
                "id": "8e1ce01e-67d4-493d-8ddc-69764e4aa30b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Marie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "32844eb6-0016-4eab-84d6-8adb126d90a6",
                "path": "product\/Pirulitos_Decorados_Marie.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "d255a66a-a75a-494e-a3ab-3d6dd703df6d",
        "product_description": "Bolo Decorado Bob Zoom",
        "product_details": "Bolo Decorado Bob Zoom",
        "product_unique_name": "bolo-decorado-bob-zoom",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Bob_Zoom.jpg",
        "category": [
            {
                "id": "e9d9dfa3-3da3-49ab-a7d4-e569974b1bc3",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bob Zoom",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3fffb74b-a32a-4fe3-806d-dbe0d02dbadf",
                "path": "product\/Bolo_Decorado_Bob_Zoom.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "ed523336-e695-43f9-b720-91aa5bd170c8",
        "product_description": "Bolo Decorado Mickey Safari",
        "product_details": "Bolo Decorado Mickey Safari",
        "product_unique_name": "bolo-decorado-mickey-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Mickey_Safari.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c3a16bc8-a87c-446a-8d58-6adcf7bd6305",
                "path": "product\/Bolo_Decorado_Mickey_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "0cd9eb17-0c15-407f-a666-82ab6a15b70e",
        "product_description": "Cupcakes Decorados Mickey Safari",
        "product_details": "Cupcakes Decorados Mickey Safari",
        "product_unique_name": "cupcakes-decorados-mickey-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Mickey_Safari.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "caa05052-79bf-4677-9f10-60975a1ac950",
                "path": "product\/Cupcakes_Decorados_Mickey_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "425b5e25-4ae4-4396-b6a2-406619bea654",
        "product_description": "Doces Decorados Mickey Safari",
        "product_details": "Doces Decorados Mickey Safari",
        "product_unique_name": "doces-decorados-mickey-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Mickey_Safari.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b17ebec5-7b5a-405f-a92c-f36e21e6c2f3",
                "path": "product\/Doces_Decorados_Mickey_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "8f5a07cb-bf8c-42c4-8af3-bc52d223a220",
        "product_description": "Colheres de Brigadeiro Mickey Safari",
        "product_details": "Colheres de Brigadeiro Mickey Safari",
        "product_unique_name": "colheres-de-brigadeiro-mickey-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Colheres_de_Brigadeiro_Mickey_Safari.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6150c6ad-b0aa-455b-a9e2-d83c21f0b31d",
                "path": "product\/Colheres_de_Brigadeiro_Mickey_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "a1fe8134-fcf5-4677-896f-9cc42e61ea88",
        "product_description": "Bolo Mickey e Minnie Halloween",
        "product_details": "Bolo Mickey e Minnie Halloween",
        "product_unique_name": "bolo-mickey-e-minnie-halloween",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Mickey_e_Minnie_Halloween.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3c9766f6-5a52-4928-b3b1-f1d4592eb553",
                "path": "product\/Bolo_Mickey_e_Minnie_Halloween.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "7d0d0d97-ba7e-4873-b4cc-3066b433b502",
        "product_description": "Cupcakes Decorados Mickey e Minnie Halloween",
        "product_details": "Cupcakes Decorados Mickey e Minnie Halloween",
        "product_unique_name": "cupcakes-decorados-mickey-e-minnie-halloween",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_mickey_e_minnie_Halloween.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "88f6cb01-6ac3-467c-8839-9c94dbc9a944",
                "path": "product\/Cupcakes_Decorados_mickey_e_minnie_Halloween.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "f50c724f-1d60-4ad1-be7f-5618958eb352",
        "product_description": "Bolo Decorado Minnie",
        "product_details": "Bolo Decorado Minnie",
        "product_unique_name": "bolo-decorado-minnie",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Minnie.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e2de0dda-1811-415b-a72a-3a30782d5abe",
                "path": "product\/Bolo_Decorado_Minnie.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "9fd484fd-dcf7-4b4b-a712-21129ef6cece",
        "product_description": "Bolo Decorado Mickey e Minnie Safari",
        "product_details": "Bolo Decorado Mickey e Minnie Safari",
        "product_unique_name": "bolo-decorado-mickey-e-minnie-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Micky_e_Minnie_Safari.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e72c5d4b-2681-4be3-9cda-4409c349587a",
                "path": "product\/Bolo_Decorado_Micky_e_Minnie_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "8ae76e6b-ff50-4b04-88e1-0dc3179efa24",
        "product_description": "Cupcakes Decorados Mickey e Minnie Safari",
        "product_details": "Cupcakes Decorados Mickey e Minnie Safari",
        "product_unique_name": "cupcakes-decorados-mickey-e-minnie-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_mickey_e_minnie_Safari_2.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b497e1dd-3694-479d-aa94-3671ddbfb34a",
                "path": "product\/Cupcakes_Decorados_mickey_e_minnie_Safari_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "07b77f07-156b-49e3-a1f1-f9987b004227",
        "product_description": "Doces Decorados Mickey e Minnie Safari",
        "product_details": "Doces Decorados Mickey e Minnie Safari",
        "product_unique_name": "doces-decorados-mickey-e-minnie-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Mickey_e_Minnie_Safari.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c8aa1f50-37e2-410f-a703-c39d4df1b3bc",
                "path": "product\/Doces_Decorados_Mickey_e_Minnie_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "c85a3fc5-07da-474d-9126-22098b33e1db",
        "product_description": "Bolo Decorado Minnie (Opção 2)",
        "product_details": "Bolo Decorado Minnie (Opção 2)",
        "product_unique_name": "bolo-decorado-minnie-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Minnie_(Opção_2).jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bdfd6764-89f7-4780-92aa-d7f7fc300611",
                "path": "product\/Bolo_Decorado_Minnie_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "825aaf99-7fb0-4d79-82ed-2e4fd206436e",
        "product_description": "Maçãs Decoradas Minnie",
        "product_details": "Maçãs Decoradas Minnie",
        "product_unique_name": "macas-decoradas-minnie",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Maçãs_Decoradas_Minnie.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7807c989-ce99-4a70-b63c-f3c3c2f4c7ad",
                "path": "product\/Maçãs_Decoradas_Minnie.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "532f88ae-2c83-4ae7-a715-f0710ba06367",
        "product_description": "Cake Pop Decorado Minnie",
        "product_details": "Cake Pop Decorado Minnie",
        "product_unique_name": "cake-pop-decorado-minnie",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cake_Pops_Decorados_Minnie.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8eeceff9-a32f-44c4-9797-adf752b591ab",
                "path": "product\/Cake_Pops_Decorados_Minnie.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "89beae4e-dec0-411d-8360-1bb9299c3268",
        "product_description": "Bolo Decorado Mickey (Opção 2)",
        "product_details": "Bolo Decorado Mickey (Opção 2)",
        "product_unique_name": "bolo-decorado-mickey-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Mickey.jpg",
        "category": [
            {
                "id": "6fb5d31d-b952-4931-8951-b04aee935c0f",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Mickey e Minnie",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2b430357-711f-4bd9-87d4-e52dfba48db5",
                "path": "product\/Bolo_Decorado_Mickey.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "31d1fff7-35ea-4682-a154-60eb2fe18310",
        "product_description": "Bolo Decorado Timão e Pumba",
        "product_details": "Bolo Decorado Timão e Pumba",
        "product_unique_name": "bolo-decorado-timao-e-pumba",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Timão_e_Pumba.jpg",
        "category": [
            {
                "id": "9f40f05a-0b27-4242-ac62-f72768257148",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Timão e Pumba",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9383a938-07a4-4b89-8ce0-3a6123d7669a",
                "path": "product\/Bolo_Decorado_Timão_e_Pumba.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "3aeae661-04a1-4326-b396-34c7ef24f494",
        "product_description": "Bolo Decorado Timão e Pumba (Opção 2)",
        "product_details": "Bolo Decorado Timão e Pumba (Opção 2)",
        "product_unique_name": "bolo-decorado-timao-e-pumba-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Timão_e_Pumba_2.jpg",
        "category": [
            {
                "id": "9f40f05a-0b27-4242-ac62-f72768257148",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Timão e Pumba",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6399d7e6-7b9e-430f-a4e3-eb8b21a0375e",
                "path": "product\/Bolo_Decorado_Timão_e_Pumba_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "77c9efef-f641-442e-a2f8-5cb0afe98e30",
        "product_description": "Bolo Decorado Galinha Pintadinha",
        "product_details": "Bolo Decorado Galinha Pintadinha",
        "product_unique_name": "bolo-decorado-galinha-pintadinha",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Galinha_Pintadinha.jpg",
        "category": [
            {
                "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Galinha Pintadinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c7cbb10c-b129-4ed5-82c7-b7129aa377b4",
                "path": "product\/Bolo_Decorado_Galinha_Pintadinha.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "963c011b-e872-4eaf-8be1-60836e79707f",
        "product_description": "Bolo Decorado Galinha Pintadinha (Opção 2)",
        "product_details": "Bolo Decorado Galinha Pintadinha (Opção 2)",
        "product_unique_name": "bolo-decorado-galinha-pintadinha-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_2).jpg",
        "category": [
            {
                "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Galinha Pintadinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d22c0193-523b-46e2-a562-ef21dcd036af",
                "path": "product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "abdcef03-e8cb-4968-8846-f5385ed426dc",
        "product_description": "Bolo Decorado Galinha Pintadinha (Opção 3)",
        "product_details": "Bolo Decorado Galinha Pintadinha (Opção 3)",
        "product_unique_name": "bolo-decorado-galinha-pintadinha-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_3).jpg",
        "category": [
            {
                "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Galinha Pintadinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "28fbaeb1-15f9-4cf0-babc-68559308e1e3",
                "path": "product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "07a31a7c-f4ae-4304-89e3-2608607a7f76",
        "product_description": "Bolo Decorado Galinha Pintadinha (Opção 4)",
        "product_details": "Bolo Decorado Galinha Pintadinha (Opção 4)",
        "product_unique_name": "bolo-decorado-galinha-pintadinha-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_4).jpg",
        "category": [
            {
                "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Galinha Pintadinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d183bfae-97c4-4bb4-a7cf-b23c90a3172f",
                "path": "product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "b3151824-1308-46ce-b1cd-feb0da34a566",
        "product_description": "Bolo Decorado Galinha Pintadinha (Opção 5)",
        "product_details": "Bolo Decorado Galinha Pintadinha (Opção 5)",
        "product_unique_name": "bolo-decorado-galinha-pintadinha-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_5).jpg",
        "category": [
            {
                "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Galinha Pintadinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6a3daec6-da48-4e73-af19-9a8a255a2b98",
                "path": "product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_5).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "9e4502c7-dfdf-4ddd-959a-cf336e77d159",
        "product_description": "Doces Decorados Galinha Pintadinha",
        "product_details": "Doces Decorados Galinha Pintadinha",
        "product_unique_name": "doces-decorados-galinha-pintadinha",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Galinha_Pintadinha.jpg",
        "category": [
            {
                "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Galinha Pintadinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "fb046886-ea97-4036-9bca-b1f4b37fae04",
                "path": "product\/Doces_Decorados_Galinha_Pintadinha.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "4db4bc44-9c15-4cdb-878c-5c11b65c1f36",
        "product_description": "Cupcakes Decorados Galinha Pintadinha ",
        "product_details": "Cupcakes Decorados Galinha Pintadinha ",
        "product_unique_name": "cupcakes-decorados-galinha-pintadinha",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Galinha_Pintadinha.jpg",
        "category": [
            {
                "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Galinha Pintadinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2499ece3-6d61-4a9e-b5d1-b34bc2783bc8",
                "path": "product\/Cupcakes_Decorados_Galinha_Pintadinha.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "4e236d22-1006-4d0e-a5ca-8f92be2e703b",
        "product_description": "Bolo Decorado Galinha Pintadinha (Opção 6)",
        "product_details": "Bolo Decorado Galinha Pintadinha (Opção 6)",
        "product_unique_name": "bolo-decorado-galinha-pintadinha-opcao-6",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_6).jpg",
        "category": [
            {
                "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Galinha Pintadinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bd1454f1-e3ba-4e4b-9803-7d171089d417",
                "path": "product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_6).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "355316b5-05e2-40e2-b28f-a31ea5ca6ccc",
        "product_description": "Bolo Decorado Galinha Pintadinha (Opção 7)",
        "product_details": "Bolo Decorado Galinha Pintadinha (Opção 7)",
        "product_unique_name": "bolo-decorado-galinha-pintadinha-opcao-7",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_7).jpg",
        "category": [
            {
                "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Galinha Pintadinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d81c2583-eac7-4aa6-94cd-046113c3654c",
                "path": "product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_7).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "81b8198c-4f35-4686-b85d-42f280756288",
        "product_description": "Bolo Decorado Galinha Pintadinha (Opção 8)",
        "product_details": "Bolo Decorado Galinha Pintadinha (Opção 8)",
        "product_unique_name": "bolo-decorado-galinha-pintadinha-opcao-8",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_8).jpg",
        "category": [
            {
                "id": "37ef1ce7-4e95-4093-8af1-48c8efce9c3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Galinha Pintadinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5095b3ac-c7e5-4a11-92ac-b8927cede581",
                "path": "product\/Bolo_Decorado_Galinha_Pintadinha_(Opção_8).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "2386ad25-fa5a-41d0-a133-292ebb6b22a3",
        "product_description": "Bolo Decorado Show da Luna",
        "product_details": "Bolo Decorado Show da Luna",
        "product_unique_name": "bolo-decorado-show-da-luna",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Show_da_Luna.jpg",
        "category": [
            {
                "id": "a8e47f88-e15e-4d87-9429-8f0220cbb60d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Show da Luna",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "029e0d40-c197-43a1-beca-a67d0ab09eca",
                "path": "product\/Bolo_Decorado_Show_da_Luna.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "33ea830c-1e71-4202-8eaa-0af2d376d6b9",
        "product_description": "Cupcakes Decorados Show da Luna",
        "product_details": "Cupcakes Decorados Show da Luna",
        "product_unique_name": "cupcakes-decorados-show-da-luna",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Show_da_Luna.jpg",
        "category": [
            {
                "id": "a8e47f88-e15e-4d87-9429-8f0220cbb60d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Show da Luna",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7a952adf-60b3-43a1-afa1-cd637b809e24",
                "path": "product\/Cupcakes_Decorados_Show_da_Luna.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "f171d9f5-79be-40f2-b51a-19d67960f749",
        "product_description": "Doces Decorados Show da Luna",
        "product_details": "Doces Decorados Show da Luna",
        "product_unique_name": "doces-decorados-show-da-luna",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Show_da_Luna.jpg",
        "category": [
            {
                "id": "a8e47f88-e15e-4d87-9429-8f0220cbb60d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Show da Luna",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "439c0eb2-7caf-4c42-919e-eeebb4c94b97",
                "path": "product\/Doces_Decorados_Show_da_Luna.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "3a6b6099-fe5a-46c9-8604-d51fe278be02",
        "product_description": "Pirulitos Decorados Show da Luna",
        "product_details": "Pirulitos Decorados Show da Luna",
        "product_unique_name": "pirulitos-decorados-show-da-luna",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Show_da_Luna.jpg",
        "category": [
            {
                "id": "a8e47f88-e15e-4d87-9429-8f0220cbb60d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Show da Luna",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "621d81c1-3909-47dd-94bc-6cfca852a418",
                "path": "product\/Pirulitos_Decorados_Show_da_Luna.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "8b537123-417b-484f-900c-6ad069994296",
        "product_description": "Bolo Decorado Alladin",
        "product_details": "Bolo Decorado Alladin",
        "product_unique_name": "bolo-decorado-alladin",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Alladin.jpg",
        "category": [
            {
                "id": "4ed79a24-a396-4200-b462-9c127ff57ab0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Alladin",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4b4459c1-4b5c-4611-93e1-5a9dcf32cedd",
                "path": "product\/Bolo_Decorado_Alladin.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "789ceba4-c549-416c-a66d-deca995de8b0",
        "product_description": "Cupcakes Decorados Alladin",
        "product_details": "Cupcakes Decorados Alladin",
        "product_unique_name": "cupcakes-decorados-alladin",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Alladin.jpg",
        "category": [
            {
                "id": "4ed79a24-a396-4200-b462-9c127ff57ab0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Alladin",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8235029a-d14f-48d1-8274-06ae02796d18",
                "path": "product\/Cupcakes_Decorados_Alladin.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "ce2a9e66-8399-45ed-ac7c-b7638da70396",
        "product_description": "Colheres de Brigadeiro Decoradas Alladin",
        "product_details": "Colheres de Brigadeiro Decoradas Alladin",
        "product_unique_name": "colheres-de-brigadeiro-decoradas-alladin",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Colher_de_Brigadeiro_Alladin.jpg",
        "category": [
            {
                "id": "4ed79a24-a396-4200-b462-9c127ff57ab0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Alladin",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0a036813-f6b3-4f5d-811d-aa733b99b91c",
                "path": "product\/Colher_de_Brigadeiro_Alladin.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "c21bb174-bb0b-4d3c-8f7c-5f933b5e8af4",
        "product_description": "Bolo Decorado Halloween",
        "product_details": "Bolo Decorado Halloween",
        "product_unique_name": "bolo-decorado-halloween",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Halloween1.jpg",
        "category": [
            {
                "id": "a90178df-392b-41d4-bcfa-49e4fb34d460",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Halloween",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a41477ca-38a6-4945-8cb1-1017e616daf7",
                "path": "product\/Bolo_Decorado_Halloween1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "69044b13-de3b-40a6-8acd-358a1ffbd74a",
        "product_description": "Bolo Decorado Halloween (Opção 2)",
        "product_details": "Bolo Decorado Halloween (Opção 2)",
        "product_unique_name": "bolo-decorado-halloween-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Halloween_(Opção_2).jpg",
        "category": [
            {
                "id": "a90178df-392b-41d4-bcfa-49e4fb34d460",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Halloween",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2285600f-e6d4-4de7-97c2-7d509be6a512",
                "path": "product\/Bolo_Decorado_Halloween_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "fd3217d3-fd60-437b-843d-0e87bcbd3b63",
        "product_description": "Cupcakes Decorados Halloween",
        "product_details": "Cupcakes Decorados Halloween",
        "product_unique_name": "cupcakes-decorados-halloween",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Halloween.jpg",
        "category": [
            {
                "id": "a90178df-392b-41d4-bcfa-49e4fb34d460",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Halloween",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8ffc0393-b8cc-4a48-adb6-cf63043c6f3f",
                "path": "product\/Cupcakes_Halloween.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "c9f6cc6f-7031-44ff-8201-1bc8e23fbee5",
        "product_description": "Doces Decorados Halloween",
        "product_details": "Doces Decorados Halloween",
        "product_unique_name": "doces-decorados-halloween",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Halloween.jpg",
        "category": [
            {
                "id": "a90178df-392b-41d4-bcfa-49e4fb34d460",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Halloween",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7fb72adb-88b1-4a4c-be7e-d4a547a80406",
                "path": "product\/Doces_Decorados_Halloween.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "8ee3e0df-4339-4abd-8182-e1be34c280ab",
        "product_description": "Pirulitos Decorados Halloween",
        "product_details": "Pirulitos Decorados Halloween",
        "product_unique_name": "pirulitos-decorados-halloween",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Halloween.jpg",
        "category": [
            {
                "id": "a90178df-392b-41d4-bcfa-49e4fb34d460",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Halloween",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c0dbaea7-4161-4f0a-abac-64b80edfa671",
                "path": "product\/Pirulitos_Decorados_Halloween.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "fdb29bc2-1d64-4eeb-a6ed-3f268fc67c49",
        "product_description": "Bolo Decorado Daniel Tigre",
        "product_details": "Bolo Decorado Daniel Tigre",
        "product_unique_name": "bolo-decorado-daniel-tigre",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Daniel_Tigre.jpg",
        "category": [
            {
                "id": "9a9653c7-0d64-42ee-b5c9-5bd07dfb7973",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Daniel Tigre",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a47c81ad-9072-4e6f-9e6e-2c81e2226bac",
                "path": "product\/Bolo_Decorado_Daniel_Tigre.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "59fa8827-2074-464a-84d2-321b268d27f4",
        "product_description": "Cupcakes Decorados Daniel Tigre",
        "product_details": "Cupcakes Decorados Daniel Tigre",
        "product_unique_name": "cupcakes-decorados-daniel-tigre",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcake_Decorado_Daniel_Tigre.jpg",
        "category": [
            {
                "id": "9a9653c7-0d64-42ee-b5c9-5bd07dfb7973",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Daniel Tigre",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cfb1466f-3bf7-4fb5-9f36-6d685f0ce475",
                "path": "product\/Cupcake_Decorado_Daniel_Tigre.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "d4618470-2ff9-4dd5-9e6f-12902e8b27f3",
        "product_description": "Pirulitos Decorados Daniel Tigre",
        "product_details": "Pirulitos Decorados Daniel Tigre",
        "product_unique_name": "pirulitos-decorados-daniel-tigre",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Daniel_Tigre.jpg",
        "category": [
            {
                "id": "9a9653c7-0d64-42ee-b5c9-5bd07dfb7973",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Daniel Tigre",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "366f24e5-c8ff-4e71-b3b0-26a84ceed9e2",
                "path": "product\/Pirulitos_Decorados_Daniel_Tigre.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "fef25096-1df4-44ef-9cf8-aedd9be99a1c",
        "product_description": "Bolo Decorado Daniel Tigre (Opção 2)",
        "product_details": "Bolo Decorado Daniel Tigre (Opção 2)",
        "product_unique_name": "bolo-decorado-daniel-tigre-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Daniel_Tigre_(Opção_2).jpg",
        "category": [
            {
                "id": "9a9653c7-0d64-42ee-b5c9-5bd07dfb7973",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Daniel Tigre",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "54fe6424-db7b-4d9e-96c1-6c9b2773ff4d",
                "path": "product\/Bolo_Decorado_Daniel_Tigre_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "9878e307-c617-4dea-9574-3e5c98814854",
        "product_description": "Bolo Decorado Jovens Titãs ",
        "product_details": "Bolo Decorado Jovens Titãs ",
        "product_unique_name": "bolo-decorado-jovens-titas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Teen_Titans.jpg",
        "category": [
            {
                "id": "273df079-0bb3-45ce-a192-67360177ed29",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jovens Titãs",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "70b5f4ae-8333-415c-930f-fa2bfc059e1f",
                "path": "product\/Bolo_Decorado_Teen_Titans.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:39.000000Z",
                "updated_at": "2023-01-26T14:23:39.000000Z"
            }
        ]
    },
    {
        "id": "d6e8d611-8b28-405d-8352-922e7b4f2be4",
        "product_description": "Cupcakes Decorados Jovens Titãs ",
        "product_details": "Cupcakes Decorados Jovens Titãs ",
        "product_unique_name": "cupcakes-decorados-jovens-titas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:39.000000Z",
        "updated_at": "2023-01-26T14:23:39.000000Z",
        "imagePath": "\/storage\/product\/Cupcake_Decorado_Teen_Titans.jpg",
        "category": [
            {
                "id": "273df079-0bb3-45ce-a192-67360177ed29",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Jovens Titãs",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6ada6776-2820-49a8-8473-da0faf07265c",
                "path": "product\/Cupcake_Decorado_Teen_Titans.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "a939c63d-8921-4ac5-b552-520eab42d142",
        "product_description": "Bolo Decorado Carros",
        "product_details": "Bolo Decorado Carros",
        "product_unique_name": "bolo-decorado-carros",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Carros.jpg",
        "category": [
            {
                "id": "81a232fd-0104-49a7-a669-dea3d5852750",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Carros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4d8d38c0-7b64-4717-b755-5de46fadea17",
                "path": "product\/Bolo_Decorado_Carros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "cb77a126-84fa-4625-be1b-17513112e647",
        "product_description": "Doces Decorados Carros",
        "product_details": "Doces Decorados Carros",
        "product_unique_name": "doces-decorados-carros",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Carros.jpg",
        "category": [
            {
                "id": "81a232fd-0104-49a7-a669-dea3d5852750",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Carros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bcac663c-da97-4855-af84-7238ac02242c",
                "path": "product\/Doces_Decorados_Carros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "8ca6b8bd-47ac-41be-a866-00d5982b75a1",
        "product_description": "Bolo Decorado Carros (Opção 2)",
        "product_details": "Bolo Decorado Carros (Opção 2)",
        "product_unique_name": "bolo-decorado-carros-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Carros_(Opções_2).jpg",
        "category": [
            {
                "id": "81a232fd-0104-49a7-a669-dea3d5852750",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Carros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "61643f30-c8ae-4ce4-8a09-7ca57ff04766",
                "path": "product\/Bolo_Decorado_Carros_(Opções_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "558890c5-e12f-4460-ae93-1eb4a8300ba5",
        "product_description": "Bolo Decorado Carros (Opção 3)",
        "product_details": "Bolo Decorado Carros (Opção 3)",
        "product_unique_name": "bolo-decorado-carros-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Carros_(Opção_3).jpg",
        "category": [
            {
                "id": "81a232fd-0104-49a7-a669-dea3d5852750",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Carros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "90821698-3443-450f-be4c-52d01a43987c",
                "path": "product\/Bolo_Decorado_Carros_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "0bba7c1d-894a-4069-9bdb-b12942d111fe",
        "product_description": "Cupcakes Decorados Carros",
        "product_details": "Cupcakes Decorados Carros",
        "product_unique_name": "cupcakes-decorados-carros",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Carros.jpg",
        "category": [
            {
                "id": "81a232fd-0104-49a7-a669-dea3d5852750",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Carros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0b3c33b7-3159-41f1-8e31-354511ffc626",
                "path": "product\/Cupcakes_Decorados_Carros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "a5a4d54e-db3f-4d9e-b314-09f84368c9e3",
        "product_description": "Mini Cupcakes Decorados Carros ",
        "product_details": "Mini Cupcakes Decorados Carros ",
        "product_unique_name": "mini-cupcakes-decorados-carros",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Mini_Cupcakes_Carros.jpg",
        "category": [
            {
                "id": "81a232fd-0104-49a7-a669-dea3d5852750",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Carros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a5b8b7fe-e8a3-4f6d-8142-6f229e93e2de",
                "path": "product\/Mini_Cupcakes_Carros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "16a5c3cf-4889-43c6-b5ab-e896dff35b68",
        "product_description": "Doces Decorados Carros (Opção 2)",
        "product_details": "Doces Decorados Carros (Opção 2)",
        "product_unique_name": "doces-decorados-carros-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Carros_(Opção_2).jpg",
        "category": [
            {
                "id": "81a232fd-0104-49a7-a669-dea3d5852750",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Carros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3025483f-bd7d-4fb5-9560-34ce4eea08e9",
                "path": "product\/Doces_Decorados_Carros_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "938b9d64-aa0f-48d1-b10d-b980bd337000",
        "product_description": "Bolo Disney Mini",
        "product_details": "Bolo Disney Mini",
        "product_unique_name": "bolo-disney-mini",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Mickey_e_Minnie_Disney_mini.jpg",
        "category": [
            {
                "id": "f43f11cc-24af-46ea-b250-2c81cba4ddd4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Disney Mini",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c5cd01c9-af9a-4fc7-ad08-8161223567fb",
                "path": "product\/Bolo_Decorado_Mickey_e_Minnie_Disney_mini.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "7e502d9e-e087-4ea1-bce5-55790151eb8c",
        "product_description": "Bolo Decorado PJ Masks",
        "product_details": "Bolo Decorado PJ Masks",
        "product_unique_name": "bolo-decorado-pj-masks",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_PJ_Masks.jpg",
        "category": [
            {
                "id": "c9a9cc22-2853-40cb-8bbc-2316a2edf04c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pj Masks",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "63f07e5d-ca77-44e7-a49b-3323ff5a1b26",
                "path": "product\/Bolo_Decorado_PJ_Masks.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "dd671fba-41f2-41f1-9dd8-e6df77bd49e9",
        "product_description": "Bolo Decorado Kokeshi",
        "product_details": "Bolo Decorado Kokeshi",
        "product_unique_name": "bolo-decorado-kokeshi",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Kokeshi.jpg",
        "category": [
            {
                "id": "e9bc4d0f-e4fe-4632-a524-5134275131d0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Kokeshi",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f09be329-8344-41b8-a3b2-08bb95fd22df",
                "path": "product\/Bolo_Decorado_Kokeshi.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "beed0f66-e637-4dd7-af87-575cce5a3bf7",
        "product_description": "Cupcakes Decorados Kokeshi",
        "product_details": "Cupcakes Decorados Kokeshi",
        "product_unique_name": "cupcakes-decorados-kokeshi",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Kokeshi.jpg",
        "category": [
            {
                "id": "e9bc4d0f-e4fe-4632-a524-5134275131d0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Kokeshi",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "343a716a-744a-41f9-b1a6-2ab0648279a1",
                "path": "product\/Cupcakes_Decorados_Kokeshi.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "a9e95bb9-e774-4eeb-9ba9-a635a4a03cbf",
        "product_description": "Doces Decorados Kokeshi",
        "product_details": "Doces Decorados Kokeshi",
        "product_unique_name": "doces-decorados-kokeshi",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Kokeshi.jpg",
        "category": [
            {
                "id": "e9bc4d0f-e4fe-4632-a524-5134275131d0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Kokeshi",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6e3aa745-4719-446a-8444-138d2b0c60cd",
                "path": "product\/Doces_Decorados_Kokeshi.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "21f17ec8-2236-44fc-90dc-9950391c0265",
        "product_description": "Bolo Decorado Dragões Equipe de Resgate",
        "product_details": "Bolo Decorado Dragões Equipe de Resgate",
        "product_unique_name": "bolo-decorado-dragoes-equipe-de-resgate",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dragões_Equipe_de_Resgate.jpg",
        "category": [
            {
                "id": "c29b1c96-2970-4ead-9449-1e3d69bba995",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dragões Equipe de Resgate",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "06cacdef-9b1a-4a1f-b134-478d2ff58bc5",
                "path": "product\/Bolo_Decorado_Dragões_Equipe_de_Resgate.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "6606dc53-812c-473b-9906-b9d58f8c7438",
        "product_description": "Bolo Decorado Dinossauro",
        "product_details": "Bolo Decorado Dinossauro",
        "product_unique_name": "bolo-decorado-dinossauro",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dinossauro.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a1a2c048-54d1-4a8a-8ca2-2ea464c385ed",
                "path": "product\/Bolo_Decorado_Dinossauro.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "ec1c2d0a-1712-4a29-abbb-7a9a0c8fb346",
        "product_description": "Bolo Decorado Dinossauros",
        "product_details": "Bolo Decorado Dinossauros",
        "product_unique_name": "bolo-decorado-dinossauros",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dinossauros.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4e8c8705-ffbc-43c1-866d-4633b99e8121",
                "path": "product\/Bolo_Decorado_Dinossauros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "083818e0-9540-4acd-b502-84af51b83842",
        "product_description": "Doces Decorados Dinossauros",
        "product_details": "Doces Decorados Dinossauros",
        "product_unique_name": "doces-decorados-dinossauros",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Dinossauros.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "89f512c3-a8b9-4688-98cf-db508c6274d9",
                "path": "product\/Doces_Decorados_Dinossauros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "d19a46a0-e1a0-4bb0-9490-111e8b663af1",
        "product_description": "Pirulitos Decorados Dinossauros",
        "product_details": "Pirulitos Decorados Dinossauros",
        "product_unique_name": "pirulitos-decorados-dinossauros",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Dinossauros.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "99d7f1f1-b574-4163-b6fb-efcabd7cf1cc",
                "path": "product\/Pirulitos_Decorados_Dinossauros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "99089736-8206-460f-9fcd-14e1a0c88c2e",
        "product_description": "Bolo Decorado Dinossauros (Opção 2)",
        "product_details": "Bolo Decorado Dinossauros (Opção 2)",
        "product_unique_name": "bolo-decorado-dinossauros-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dinossauros2.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "518b1e3d-b190-4c10-bfe0-1ae135c6fa7e",
                "path": "product\/Bolo_Decorado_Dinossauros2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "6f4ba0a7-9bb7-4f55-bf3c-0b7e4884fbd6",
        "product_description": "Cupcakes Decorados Dinossauros",
        "product_details": "Cupcakes Decorados Dinossauros",
        "product_unique_name": "cupcakes-decorados-dinossauros",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Dinossauros.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3cf877d2-02c3-4f55-9952-887d238e2b35",
                "path": "product\/Cupcakes_Decorados_Dinossauros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "35292993-0588-40b7-b136-3ec237a473c3",
        "product_description": "Doces Decorados Dinossauros (Opção 2)",
        "product_details": "Doces Decorados Dinossauros (Opção 2)",
        "product_unique_name": "doces-decorados-dinossauros-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Dinossauros1.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d0420b72-6022-4c06-bf42-51a2e348abc2",
                "path": "product\/Cupcakes_Decorados_Dinossauros1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "9b301639-8446-49c9-be27-73ebf98f7817",
        "product_description": "Pirulitos Decorados Dinossauros (Opções 2)",
        "product_details": "Pirulitos Decorados Dinossauros (Opções 2)",
        "product_unique_name": "pirulitos-decorados-dinossauros-opcoes-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_de_chocolate_dinossauros.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e26d39cf-77c7-44fa-81e4-14d988906689",
                "path": "product\/Pirulitos_de_chocolate_dinossauros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "ffe829b6-493e-4a54-9a23-d727a7e8907f",
        "product_description": "Bolo Decorado Dinossauros (Opção 3)",
        "product_details": "Bolo Decorado Dinossauros (Opção 3)",
        "product_unique_name": "bolo-decorado-dinossauros-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dinossauros_(Opçaõ_2).jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2c6d9b41-8713-4d55-b376-c077109982bd",
                "path": "product\/Bolo_Decorado_Dinossauros_(Opçaõ_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "3b0eb3fd-7a34-447f-b970-55c3ca700826",
        "product_description": "Bolo Decorado Dinossauros (Opção 4)",
        "product_details": "Bolo Decorado Dinossauros (Opção 4)",
        "product_unique_name": "bolo-decorado-dinossauros-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dinossauros3.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "12c94300-257f-4fce-af1b-2e85ea1d0a79",
                "path": "product\/Bolo_Decorado_Dinossauros3.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "6b054c6f-dcc8-4704-b187-c5898aea8b6a",
        "product_description": "Bolo Decorado Dinossauros (Opção 5)",
        "product_details": "Bolo Decorado Dinossauros (Opção 5)",
        "product_unique_name": "bolo-decorado-dinossauros-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dinossauros_(Opção_2).jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "77f1d6fa-9426-4f66-bba6-64d9cb2071ea",
                "path": "product\/Bolo_Decorado_Dinossauros_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "394b0aaa-3f75-4aa6-ad78-1dbde4f76456",
        "product_description": "Bolo Decorado Dinossauros (Opção 11)",
        "product_details": "Bolo Decorado Dinossauros (Opção 11)",
        "product_unique_name": "bolo-decorado-dinossauros-opcao-11",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dinossauros_(Opção_3)1.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "25c0479b-07ea-448c-b9b0-93c31b8a245d",
                "path": "product\/Bolo_Decorado_Dinossauros_(Opção_3)1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "bd404615-f655-4a57-becf-758243cc4c06",
        "product_description": "Cupcakes Decorados Dinossauros (Opção 2)",
        "product_details": "Cupcakes Decorados Dinossauros (Opção 2)",
        "product_unique_name": "cupcakes-decorados-dinossauros-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Dinossauros.jpg",
        "category": [
            {
                "id": "09d4dd9a-b418-459c-9784-6028c194136b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dinossauros",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ec704ef0-03d5-4921-872c-f91fb5fb20af",
                "path": "product\/Cupcakes_Dinossauros.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "0f307601-a563-4201-8712-ac30b9b13c85",
        "product_description": "Bolo Decorado Arca de Noé",
        "product_details": "Bolo Decorado Arca de Noé",
        "product_unique_name": "bolo-decorado-arca-de-noe",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Arca_de_Noé.jpg",
        "category": [
            {
                "id": "a4e5cea0-05a3-4465-9498-4a911df83f50",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Arca de Noé",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8dd8da5a-f91e-4728-baec-73d1d5544668",
                "path": "product\/Bolo_Decorado_Arca_de_Noé.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "bb982fb2-6da0-463a-950a-8e47d9c60035",
        "product_description": "Fudges Decorados Arca de Noé",
        "product_details": "Fudges Decorados Arca de Noé",
        "product_unique_name": "fudges-decorados-arca-de-noe",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Fudges_Arca_de_Noé.jpg",
        "category": [
            {
                "id": "a4e5cea0-05a3-4465-9498-4a911df83f50",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Arca de Noé",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a3468d9a-270a-4002-948f-fdc51f92cd4b",
                "path": "product\/Fudges_Arca_de_Noé.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "272c2717-965a-499b-b1bf-ebfd092f5574",
        "product_description": "Cupcakes Decorados Arca de Noé",
        "product_details": "Cupcakes Decorados Arca de Noé",
        "product_unique_name": "cupcakes-decorados-arca-de-noe",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Arca_de_Noé.jpg",
        "category": [
            {
                "id": "a4e5cea0-05a3-4465-9498-4a911df83f50",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Arca de Noé",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f4ba63b9-32db-435f-a42d-a0be0ad4d107",
                "path": "product\/Cupcakes_Arca_de_Noé.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "e6d072de-5337-44ef-aae6-59421b82df40",
        "product_description": "Doces Decorados Arca de Noé",
        "product_details": "Doces Decorados Arca de Noé",
        "product_unique_name": "doces-decorados-arca-de-noe",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Arca_de_Noé.jpg",
        "category": [
            {
                "id": "a4e5cea0-05a3-4465-9498-4a911df83f50",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Arca de Noé",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "62915e7e-66cc-4897-8a43-a6ab82f3a81b",
                "path": "product\/Doces_Decorados_Arca_de_Noé.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "085d382d-aa11-4b6a-9968-f05b20718a69",
        "product_description": "Bolo Decorado (Menina e Urso)",
        "product_details": "Bolo Decorado (Menina e Urso)",
        "product_unique_name": "bolo-decorado-menina-e-urso",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/bolo_urso_e_menina_isabele.jpg",
        "category": [
            {
                "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f7c7b54c-3b02-4f31-820f-ff5004cfc3b7",
                "path": "product\/bolo_urso_e_menina_isabele.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "adcba1a0-5862-4be9-8904-52365f48620c",
        "product_description": "Cupakes Decorados  (Menina e Urso)",
        "product_details": "Cupakes Decorados  (Menina e Urso)",
        "product_unique_name": "cupakes-decorados-menina-e-urso",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Urso_e_Menina.jpg",
        "category": [
            {
                "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "92ec7bb1-b50e-4627-9557-5661383b9f30",
                "path": "product\/Cupcakes_Urso_e_Menina.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "ec6019a4-3a9d-477e-b093-f78676e90690",
        "product_description": "Doces Decorados (Menina e Urso)",
        "product_details": "Doces Decorados (Menina e Urso)",
        "product_unique_name": "doces-decorados-menina-e-urso",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Doces_Urso_e_Menina.jpg",
        "category": [
            {
                "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ab83a059-af27-4340-b8ae-b4e4121623a1",
                "path": "product\/Doces_Urso_e_Menina.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "7169644f-ad95-48ea-a625-e577d2c6aaf6",
        "product_description": "Doces Decorados (Menina e Urso) Opção 2",
        "product_details": "Doces Decorados (Menina e Urso) Opção 2",
        "product_unique_name": "doces-decorados-menina-e-urso-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Docinhos_Urso_e_Menina.jpg",
        "category": [
            {
                "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2ec81a9e-9a59-406b-af90-b131e3852b7b",
                "path": "product\/Docinhos_Urso_e_Menina.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "2698dae6-4625-41e8-8717-a41ee21404b7",
        "product_description": "Bolo Decorado Ursinhos (Opção 2)",
        "product_details": "Bolo Decorado Ursinhos (Opção 2)",
        "product_unique_name": "bolo-decorado-ursinhos-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Ursinhos.jpg",
        "category": [
            {
                "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "118f72eb-6a57-45ae-a0f9-b536f272ecfd",
                "path": "product\/Bolo_Ursinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "0237d624-4d2c-4008-a1b2-3f8425bb7ad2",
        "product_description": "Cupcakes Decorados Ursinhos ",
        "product_details": "Cupcakes Decorados Ursinhos ",
        "product_unique_name": "cupcakes-decorados-ursinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Ursinhos.jpg",
        "category": [
            {
                "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cce9ad27-2a99-412c-a89c-897b41c3311b",
                "path": "product\/Cupcakes_Ursinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "74523e37-8e84-4a75-a2e0-c55d16d8cb69",
        "product_description": "Bolo Decorado Ursinhos (Opção 4) ",
        "product_details": "Bolo Decorado Ursinhos (Opção 4) ",
        "product_unique_name": "bolo-decorado-ursinhos-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Ursinhos_2.jpg",
        "category": [
            {
                "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "756bb6b8-8789-42ef-bf31-3068d782622b",
                "path": "product\/Bolo_Ursinhos_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "08f3e0eb-6e56-4a0e-9666-868a6884bcd1",
        "product_description": "Doces Decorados Ursinhos",
        "product_details": "Doces Decorados Ursinhos",
        "product_unique_name": "doces-decorados-ursinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Ursinhos2.jpg",
        "category": [
            {
                "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e571ee3b-aa76-4eeb-b1b7-51e72a319087",
                "path": "product\/Doces_Decorados_Ursinhos2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "211236b6-8175-4e22-8295-c091ad7602b8",
        "product_description": "Cupcakes Decorados Ursinhos (Opção 2)",
        "product_details": "Cupcakes Decorados Ursinhos (Opção 2)",
        "product_unique_name": "cupcakes-decorados-ursinhos-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Ursinhos.jpg",
        "category": [
            {
                "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ef76d1ff-0949-4ca1-9737-915fb80b4aed",
                "path": "product\/Cupcakes_Ursinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "7ab994ce-42cf-4099-8241-f5721a1400be",
        "product_description": "Bolo Decorado Ursinhos (Opção 5)",
        "product_details": "Bolo Decorado Ursinhos (Opção 5)",
        "product_unique_name": "bolo-decorado-ursinhos-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Ursinhos.jpg",
        "category": [
            {
                "id": "f84afd6f-09e4-4def-a049-82343d53a7a4",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b7f0d29a-47f7-47f7-b5a9-fb4edd30fb18",
                "path": "product\/Bolo_Decorado_Ursinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "9e51e11c-9f0f-4d4b-afcb-793501676e0c",
        "product_description": "Doces Decorados Fundo do mar ",
        "product_details": "Doces Decorados Fundo do mar ",
        "product_unique_name": "doces-decorados-fundo-do-mar",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/doces_fundo_do_mar.jpg",
        "category": [
            {
                "id": "3e8f1535-55aa-4f1b-8ab3-69930d4b8afe",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fundo do Mar",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0d91e180-9743-4d23-b842-278643ae91f0",
                "path": "product\/doces_fundo_do_mar.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "ac140dda-9614-40b7-8b1f-04c28ebaba24",
        "product_description": "Bolo Decorado Fundo do Mar (Opção 2) ",
        "product_details": "Bolo Decorado Fundo do Mar (Opção 2) ",
        "product_unique_name": "bolo-decorado-fundo-do-mar-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/doces_fundo_do_mar.jpg",
        "category": [
            {
                "id": "3e8f1535-55aa-4f1b-8ab3-69930d4b8afe",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fundo do Mar",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "61bbe751-41a8-402e-b6f2-ca6b45b75e69",
                "path": "product\/doces_fundo_do_mar.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "877f2a69-26fb-4b60-8e6c-8464f7718535",
        "product_description": "Cupcakes Decorados Fundo do Mar",
        "product_details": "Cupcakes Decorados Fundo do Mar",
        "product_unique_name": "cupcakes-decorados-fundo-do-mar",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Cupcake_Decorado_Fundo_do_mar_(opção_2).jpg",
        "category": [
            {
                "id": "3e8f1535-55aa-4f1b-8ab3-69930d4b8afe",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fundo do Mar",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6adc1a12-bbc4-43db-9f69-cda9a317b522",
                "path": "product\/Cupcake_Decorado_Fundo_do_mar_(opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "2ad27f1c-a0b9-4bf4-a65d-050f3551a9ee",
        "product_description": "Bolo Decorado Fundo do Mar (Opção 3)",
        "product_details": "Bolo Decorado Fundo do Mar (Opção 3)",
        "product_unique_name": "bolo-decorado-fundo-do-mar-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Fundo_do_mar_(opção_3).jpg",
        "category": [
            {
                "id": "3e8f1535-55aa-4f1b-8ab3-69930d4b8afe",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fundo do Mar",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3eb48308-e222-4bf8-9761-3bb0c7296295",
                "path": "product\/Bolo_Fundo_do_mar_(opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "c343e2fb-e692-4677-b735-1b264fef91f1",
        "product_description": "Bolo Decorado de Bichinhos ",
        "product_details": "Bolo Decorado de Bichinhos ",
        "product_unique_name": "bolo-decorado-de-bichinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/bolo_de_bichinhos.jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bc014945-338a-4d35-8d81-53efa1c8cb01",
                "path": "product\/bolo_de_bichinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "c1dac6b8-2d5b-4e98-a01c-02101392c5bf",
        "product_description": "Bolo Decorado Safari",
        "product_details": "Bolo Decorado Safari",
        "product_unique_name": "bolo-decorado-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Safari.jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f8bf91c7-ec78-4891-9482-77571f14aca9",
                "path": "product\/Bolo_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "ccd51964-cd0d-4e7d-8e2a-09fd0d9a4416",
        "product_description": "Bolo Decorado Floresta",
        "product_details": "Bolo Decorado Floresta",
        "product_unique_name": "bolo-decorado-floresta",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Floresta.jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7bc2f23e-abc4-40ad-b57b-a7dc977bfd63",
                "path": "product\/Bolo_Decorado_Floresta.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "828a1724-14e4-4df3-941d-35329e47578e",
        "product_description": "Bolo Decorado Animais da Selva",
        "product_details": "Bolo Decorado Animais da Selva",
        "product_unique_name": "bolo-decorado-animais-da-selva",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Animais_Selva.jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "53a6c411-5874-4172-bf30-e6fcd47529c8",
                "path": "product\/Bolo_Animais_Selva.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "9cd9ac45-4c31-4c09-a89e-ea7ab5900f47",
        "product_description": "Bolo Decorado Safari (Opção 2)",
        "product_details": "Bolo Decorado Safari (Opção 2)",
        "product_unique_name": "bolo-decorado-safari-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Safari.jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "912e33c5-99ec-4bf0-b3cb-e809d36ba412",
                "path": "product\/Bolo_Decorado_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "0fe1eaf2-f631-4b98-926a-98d211764c61",
        "product_description": "Bolo Decorado Safari (Opção 3)",
        "product_details": "Bolo Decorado Safari (Opção 3)",
        "product_unique_name": "bolo-decorado-safari-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:40.000000Z",
        "updated_at": "2023-01-26T14:23:40.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Safari_(Opção_2).jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "94a30373-d3e0-4fbf-a593-75422c7eb2f7",
                "path": "product\/Bolo_Decorado_Safari_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:40.000000Z",
                "updated_at": "2023-01-26T14:23:40.000000Z"
            }
        ]
    },
    {
        "id": "921103f3-902d-4973-a0ed-43c0a58eb349",
        "product_description": "Bolo Decorado Safari (Opção 4)",
        "product_details": "Bolo Decorado Safari (Opção 4)",
        "product_unique_name": "bolo-decorado-safari-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Safari_(Opção_3).jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "faf922a2-9cbc-4857-aa07-c45129e0160e",
                "path": "product\/Bolo_Decorado_Safari_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "0fd31d07-7c62-442b-a20c-101ebea5306f",
        "product_description": "Bolo Decorado Safari (Opção 5)",
        "product_details": "Bolo Decorado Safari (Opção 5)",
        "product_unique_name": "bolo-decorado-safari-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Safari1.jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c5c187e1-a763-44f2-8518-db9445584cfd",
                "path": "product\/Bolo_Decorado_Safari1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "4da9b984-3372-4842-bf15-331950ec42ea",
        "product_description": "Cupcakes Decorados Safari",
        "product_details": "Cupcakes Decorados Safari",
        "product_unique_name": "cupcakes-decorados-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Safari.jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "75049064-b2fd-4a25-a206-f9620f86f146",
                "path": "product\/Cupcakes_Decorados_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "27db7627-e5ac-432b-b493-4051a50184a9",
        "product_description": "Cone Trufado Safari",
        "product_details": "Cone Trufado Safari",
        "product_unique_name": "cone-trufado-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Cone_Trufado_Safari.jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3db07d96-59b2-4448-bdfe-3ce879983e5a",
                "path": "product\/Cone_Trufado_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "7e4bf007-ee42-4d9a-91ae-46e63d9705fb",
        "product_description": "Bolo Decorado Safari (Opção 6)",
        "product_details": "Bolo Decorado Safari (Opção 6)",
        "product_unique_name": "bolo-decorado-safari-opcao-6",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Safari_(Opção_5_).jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5bd71af0-c784-4d08-ad97-c26c83b0c3d0",
                "path": "product\/Bolo_Decorado_Safari_(Opção_5_).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "842b6bcd-6f0c-4797-b8cc-0383a6d8a9a6",
        "product_description": " Doces Decorados Safari (Opção 3)",
        "product_details": " Doces Decorados Safari (Opção 3)",
        "product_unique_name": "doces-decorados-safari-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Bichinhos_(Opção_3).jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "76458f49-5c9f-4571-9217-dd3e638963a6",
                "path": "product\/Doces_Decorados_Bichinhos_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "dc857345-3968-4bfc-980f-d3250a0c7902",
        "product_description": "Bolo Decorado Safari (Opção 7)",
        "product_details": "Bolo Decorado Safari (Opção 7)",
        "product_unique_name": "bolo-decorado-safari-opcao-7",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Safari_(Opção_7_).jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3302b4bb-8a30-4539-a312-cbc1aed67cda",
                "path": "product\/Bolo_Decorado_Safari_(Opção_7_).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "8dc50066-b6e8-4a93-bb41-f560dac1bd32",
        "product_description": "Mini Cupcakes Safari",
        "product_details": "Mini Cupcakes Safari",
        "product_unique_name": "mini-cupcakes-safari",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Mini_Cupcakes_Safari.jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f688b270-4aaa-4967-85d9-123ed1ced42b",
                "path": "product\/Mini_Cupcakes_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "9299e79c-9a97-41b2-ace8-660ebf443fb1",
        "product_description": "Cupcakes Decorados Safari (Opção 1)",
        "product_details": "Cupcakes Decorados Safari (Opção 1)",
        "product_unique_name": "cupcakes-decorados-safari-opcao-1",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_decorados_Safari.jpg",
        "category": [
            {
                "id": "19c7fe0b-0c62-4c7f-81da-2a068b24d576",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Safari",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9584e0e1-9d2e-4c05-8713-dff53d693b0d",
                "path": "product\/Cupcakes_decorados_Safari.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "4de7290f-a88d-493c-abdd-abdba0554a7a",
        "product_description": "Bolo Decorado Dálmatas",
        "product_details": "Bolo Decorado Dálmatas",
        "product_unique_name": "bolo-decorado-dalmatas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Dalmatas.jpg",
        "category": [
            {
                "id": "2e23b19a-1ef2-45a9-87f3-902d285e17dd",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dálmatas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ac533781-5575-4f6e-9dc1-9e5447678ab5",
                "path": "product\/Bolo_Dalmatas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "d5b1431c-00dc-4c6c-ad68-a92e5ad0f0a6",
        "product_description": "Cupcakes Decorados Dálmatas",
        "product_details": "Cupcakes Decorados Dálmatas",
        "product_unique_name": "cupcakes-decorados-dalmatas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Dalmatas.jpg",
        "category": [
            {
                "id": "2e23b19a-1ef2-45a9-87f3-902d285e17dd",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dálmatas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "705613e7-be69-4278-a5df-646db173badf",
                "path": "product\/Cupcakes_Dalmatas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "d978326a-c419-4ea6-aad4-e0bc104d908d",
        "product_description": "Doces Decorados Dálmatas",
        "product_details": "Doces Decorados Dálmatas",
        "product_unique_name": "doces-decorados-dalmatas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Dalmatas.jpg",
        "category": [
            {
                "id": "2e23b19a-1ef2-45a9-87f3-902d285e17dd",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Dálmatas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "636a3d1a-b232-466d-a7d7-6f2eee041cab",
                "path": "product\/Doces_Decorados_Dalmatas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "c68bf4db-4085-4405-86e4-14d6a490a35b",
        "product_description": "Bolo Decorado Passarinhos ",
        "product_details": "Bolo Decorado Passarinhos ",
        "product_unique_name": "bolo-decorado-passarinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Passarinhos.jpg",
        "category": [
            {
                "id": "bca3a878-d222-4c62-8edc-acb83838b282",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Passarinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a619f655-3e4d-4459-b03a-914d11cd6090",
                "path": "product\/Bolo_Passarinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "14906b6c-07b1-453b-9f90-d0f8fcab9e04",
        "product_description": "Cupcakes Decorados Passarinhos",
        "product_details": "Cupcakes Decorados Passarinhos",
        "product_unique_name": "cupcakes-decorados-passarinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Passarinhos.jpg",
        "category": [
            {
                "id": "bca3a878-d222-4c62-8edc-acb83838b282",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Passarinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a2143cbb-6e63-4574-871f-6ea6554c87db",
                "path": "product\/Cupcakes_Passarinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "34310b95-8b90-4d20-9c7f-abcd405797c3",
        "product_description": "Bolo Decorado Passarinhos (opção 2)",
        "product_details": "Bolo Decorado Passarinhos (opção 2)",
        "product_unique_name": "bolo-decorado-passarinhos-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Passarinhos1.jpg",
        "category": [
            {
                "id": "bca3a878-d222-4c62-8edc-acb83838b282",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Passarinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "29d686cf-fa27-4f47-8cae-d24908f557d1",
                "path": "product\/Bolo_Passarinhos1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "c4dbe792-9b48-45ec-8ecf-c305533797e9",
        "product_description": "Bolo Decorado Passarinhos (Opção 3)",
        "product_details": "Bolo Decorado Passarinhos (Opção 3)",
        "product_unique_name": "bolo-decorado-passarinhos-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Passarinhos.jpg",
        "category": [
            {
                "id": "bca3a878-d222-4c62-8edc-acb83838b282",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Passarinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7b304faa-6f20-4e2b-ad90-c9289a15f9ff",
                "path": "product\/Bolo_Decorado_Passarinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "6c986506-dcac-463e-8da1-393102be9ccb",
        "product_description": "Cupcakes Decorados Passarinhos (Opção 2)",
        "product_details": "Cupcakes Decorados Passarinhos (Opção 2)",
        "product_unique_name": "cupcakes-decorados-passarinhos-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Passarinhos.jpg",
        "category": [
            {
                "id": "bca3a878-d222-4c62-8edc-acb83838b282",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Passarinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7f585242-9701-449a-98c8-219931742bd5",
                "path": "product\/Cupcakes_Decorados_Passarinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "828bef6b-5fa8-4e3a-943c-4a2416eab333",
        "product_description": "Bolo Decorado Coelinhos",
        "product_details": "Bolo Decorado Coelinhos",
        "product_unique_name": "bolo-decorado-coelinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Coelinhos.jpg",
        "category": [
            {
                "id": "9e906056-5de1-4700-bb41-6125db703ee8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Coelhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0b7def55-10ce-4c80-b0c0-692d1260fb8e",
                "path": "product\/Bolo_Coelinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "0e2652b7-ca0e-4573-90ce-7c75fe642576",
        "product_description": "Bolo Decorado Coelinhos (Opção 2)",
        "product_details": "Bolo Decorado Coelinhos (Opção 2)",
        "product_unique_name": "bolo-decorado-coelinhos-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Páscoa_(Opção_2).jpg",
        "category": [
            {
                "id": "9e906056-5de1-4700-bb41-6125db703ee8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Coelhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0f70b2f9-4ef2-4c80-bf86-1dabcd6a3fb8",
                "path": "product\/Bolo_Páscoa_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "c60997b5-d577-477e-b1a0-ceec384eed4c",
        "product_description": "Bolo Decorado Coelinhos (Opção 3)",
        "product_details": "Bolo Decorado Coelinhos (Opção 3)",
        "product_unique_name": "bolo-decorado-coelinhos-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Páscoa_(Opção_3).jpg",
        "category": [
            {
                "id": "9e906056-5de1-4700-bb41-6125db703ee8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Coelhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f680065b-3680-4139-8f42-7c7249beeeb9",
                "path": "product\/Bolo_Páscoa_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "b5254407-f5e9-4706-98ac-1ef80ea8e9b4",
        "product_description": "Bolo Decorado Coelhinhos ",
        "product_details": "Bolo Decorado Coelhinhos ",
        "product_unique_name": "bolo-decorado-coelhinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Páscoa_(Opção_4).jpg",
        "category": [
            {
                "id": "9e906056-5de1-4700-bb41-6125db703ee8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Coelhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "dd4bd77d-4573-4eff-bbd0-005f40d42a28",
                "path": "product\/Bolo_Páscoa_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "32d68d3f-2b29-446f-a9ba-bd1f08b83207",
        "product_description": "Bolo Decorado Coelinho ",
        "product_details": "Bolo Decorado Coelinho ",
        "product_unique_name": "bolo-decorado-coelinho",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Coelinho.jpg",
        "category": [
            {
                "id": "9e906056-5de1-4700-bb41-6125db703ee8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Coelhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3a162147-a540-4a81-92f2-22443194701a",
                "path": "product\/Bolo_Decorado_Coelinho.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "7f68c428-2af9-4809-b693-4ac66e46e509",
        "product_description": "Bolo Decorado Coelhinhos (Páscoa)",
        "product_details": "Bolo Decorado Coelhinhos (Páscoa)",
        "product_unique_name": "bolo-decorado-coelhinhos-pascoa",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Coelinhos.jpg",
        "category": [
            {
                "id": "9e906056-5de1-4700-bb41-6125db703ee8",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Coelhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "89e3439f-b6e5-4f9f-8b62-3e4e4d423ebd",
                "path": "product\/Bolo_Decorado_Coelinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "ed666ae5-df1e-4300-8a21-038478252d01",
        "product_description": "Doces Decorados Páscoa",
        "product_details": "Doces Decorados Páscoa",
        "product_unique_name": "doces-decorados-pascoa",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Páscoa.jpg",
        "category": [
            {
                "id": "ffa291c1-b117-4d5d-82e4-a061c6181e12",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Páscoa",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "78c61067-3d14-452e-8e33-f03080a74197",
                "path": "product\/Doces_Decorados_Páscoa.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "bb9d33ab-73c2-41ca-8dc6-f2bfc51d5c87",
        "product_description": "Bolo Decorado Páscoa (Opção 5)",
        "product_details": "Bolo Decorado Páscoa (Opção 5)",
        "product_unique_name": "bolo-decorado-pascoa-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Páscoa_(Opção_5).jpg",
        "category": [
            {
                "id": "ffa291c1-b117-4d5d-82e4-a061c6181e12",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Páscoa",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c7c1440f-fd39-4336-a317-5af61f220f3a",
                "path": "product\/Bolo_Páscoa_(Opção_5).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "cb2b8f2c-9d92-4d0a-90f7-864ba3ec6e86",
        "product_description": "Bolo Decorado Fazendinha",
        "product_details": "Bolo Decorado Fazendinha",
        "product_unique_name": "bolo-decorado-fazendinha",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Fazendinha.jpg",
        "category": [
            {
                "id": "61634f0c-0733-44c2-8c61-a12486537569",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fazendinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "113c7e56-07f7-42ac-ab7e-34650c241553",
                "path": "product\/Bolo_Decorado_Fazendinha.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "9bf4477d-1795-4c69-8f25-5ee90ee89297",
        "product_description": "Bolo Decorado Fazendinha (Opção 2)",
        "product_details": "Bolo Decorado Fazendinha (Opção 2)",
        "product_unique_name": "bolo-decorado-fazendinha-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Fazendinha_(Opção_2).jpg",
        "category": [
            {
                "id": "61634f0c-0733-44c2-8c61-a12486537569",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fazendinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4e277ea1-a22f-4f31-89d4-159bb2c6db17",
                "path": "product\/Bolo_Decorado_Fazendinha_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "10ec8a59-9727-4e22-bc7a-85d0ff1ea58e",
        "product_description": "Pirulitos Decorados Fazendinha ",
        "product_details": "Pirulitos Decorados Fazendinha ",
        "product_unique_name": "pirulitos-decorados-fazendinha",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Fazendinha.jpg",
        "category": [
            {
                "id": "61634f0c-0733-44c2-8c61-a12486537569",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fazendinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1e7c2f52-acc8-4013-8aa3-fd443f123e28",
                "path": "product\/Pirulitos_Decorados_Fazendinha.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "1cecc384-6131-4832-aa54-2798a72f2ea5",
        "product_description": "Cake Pops Fazendinha ",
        "product_details": "Cake Pops Fazendinha ",
        "product_unique_name": "cake-pops-fazendinha",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Cake_pops_fazendinha.jpg",
        "category": [
            {
                "id": "61634f0c-0733-44c2-8c61-a12486537569",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fazendinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "23f6f872-8bda-463e-bc02-77f23d6d64a2",
                "path": "product\/Cake_pops_fazendinha.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "933b2d4b-15d4-4fcb-9a8d-9e8debd4af89",
        "product_description": "Bolo Decorado Fazendinha (Opção 3)",
        "product_details": "Bolo Decorado Fazendinha (Opção 3)",
        "product_unique_name": "bolo-decorado-fazendinha-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decoado_Fazendinha.jpg",
        "category": [
            {
                "id": "61634f0c-0733-44c2-8c61-a12486537569",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fazendinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "33f870ca-24ac-418f-b214-50b3e11688da",
                "path": "product\/Bolo_Decoado_Fazendinha.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "6bc5aba2-ae43-471e-bfcc-f344a3eab14d",
        "product_description": "Bolo Decorado Fazendinha (Opção 4)",
        "product_details": "Bolo Decorado Fazendinha (Opção 4)",
        "product_unique_name": "bolo-decorado-fazendinha-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Fazendinha1.jpg",
        "category": [
            {
                "id": "61634f0c-0733-44c2-8c61-a12486537569",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fazendinha",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b3381e1b-e05e-442f-ad9f-b137bc7fc4c8",
                "path": "product\/Bolo_Decorado_Fazendinha1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "295a1b2d-a005-424a-b0f3-4e6a7546110b",
        "product_description": "Bolo Decorado Elefantinhos",
        "product_details": "Bolo Decorado Elefantinhos",
        "product_unique_name": "bolo-decorado-elefantinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Elefantinhos.jpg",
        "category": [
            {
                "id": "8828f326-b50a-42aa-882e-32fe35cbaeed",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Elefantinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f595e8dc-89ca-49b2-b92e-d1b76495fe2a",
                "path": "product\/Bolo_Decorado_Elefantinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "87eab823-fe2c-40ca-8248-52bf25b44bc9",
        "product_description": "Cupcakes Decorados Elefantinhos ",
        "product_details": "Cupcakes Decorados Elefantinhos ",
        "product_unique_name": "cupcakes-decorados-elefantinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Elefantinhos.jpg",
        "category": [
            {
                "id": "8828f326-b50a-42aa-882e-32fe35cbaeed",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Elefantinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "802253c6-f492-44ce-9205-1f222e1199a6",
                "path": "product\/Cupcakes_Elefantinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "070227e6-9533-4ae0-9c99-ffe88f92750b",
        "product_description": "Doces Decorados Elefantinhos",
        "product_details": "Doces Decorados Elefantinhos",
        "product_unique_name": "doces-decorados-elefantinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Elefantinhos.jpg",
        "category": [
            {
                "id": "8828f326-b50a-42aa-882e-32fe35cbaeed",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Elefantinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "abe20e8e-431c-4479-b362-e5406cb47a50",
                "path": "product\/Doces_Decorados_Elefantinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "fabac9e2-ef99-4daf-8f17-5096b3dbf770",
        "product_description": "Pirulitos Decorados Elefantinhos",
        "product_details": "Pirulitos Decorados Elefantinhos",
        "product_unique_name": "pirulitos-decorados-elefantinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Elefantinhos.jpg",
        "category": [
            {
                "id": "8828f326-b50a-42aa-882e-32fe35cbaeed",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Elefantinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2c5a852f-cc12-4795-bfab-2b18d13125b1",
                "path": "product\/Pirulitos_Decorados_Elefantinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "b5c945e7-36ad-4200-baa4-683a8b36a0fc",
        "product_description": "Cupcakes Decorados Gatinhos",
        "product_details": "Cupcakes Decorados Gatinhos",
        "product_unique_name": "cupcakes-decorados-gatinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Gatinhos.jpg",
        "category": [
            {
                "id": "766e3f23-5ab7-4783-90b6-ca11e4659a9a",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pets",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bdefb83f-ac76-48c0-854e-37c1e60e9025",
                "path": "product\/Cupcakes_Gatinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "0cf79e17-4017-4f22-ad1b-3ef3a4eeffc6",
        "product_description": "Fudges Decorados Gatinhos",
        "product_details": "Fudges Decorados Gatinhos",
        "product_unique_name": "fudges-decorados-gatinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Fudges_Gatinhos.jpg",
        "category": [
            {
                "id": "766e3f23-5ab7-4783-90b6-ca11e4659a9a",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pets",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "85606d34-2078-4de3-a250-c31069f40ad6",
                "path": "product\/Fudges_Gatinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "f5c5b243-fd60-4b0c-bd9c-cbd0c3790560",
        "product_description": "Doces Decorados Gatinhos",
        "product_details": "Doces Decorados Gatinhos",
        "product_unique_name": "doces-decorados-gatinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Gatinhos.jpg",
        "category": [
            {
                "id": "766e3f23-5ab7-4783-90b6-ca11e4659a9a",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pets",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3bdaaa2c-62ea-40f4-96c3-311480e385bd",
                "path": "product\/Doces_Decorados_Gatinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "65764731-5505-48d1-9516-14ee225ffe82",
        "product_description": "Bolo Decorado Cachorros e Gatos",
        "product_details": "Bolo Decorado Cachorros e Gatos",
        "product_unique_name": "bolo-decorado-cachorros-e-gatos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Cachorros_e_Gatos.jpg",
        "category": [
            {
                "id": "766e3f23-5ab7-4783-90b6-ca11e4659a9a",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pets",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2329c763-189a-4b98-b8cf-cb749aeac0fa",
                "path": "product\/Bolo_Decorado_Cachorros_e_Gatos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "e37fffe2-bd76-4a9c-ad9a-4e2b1c6da844",
        "product_description": "Bolo Decorado Gatinhos",
        "product_details": "Bolo Decorado Gatinhos",
        "product_unique_name": "bolo-decorado-gatinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Gatinhos.jpg",
        "category": [
            {
                "id": "766e3f23-5ab7-4783-90b6-ca11e4659a9a",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pets",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2f85696c-3f98-48c5-a1c1-62be3193b81d",
                "path": "product\/Bolo_Gatinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "c9e6d948-f64a-4384-aacd-58e5c9b4104f",
        "product_description": "Bolo Decorado Tubarão",
        "product_details": "Bolo Decorado Tubarão",
        "product_unique_name": "bolo-decorado-tubarao",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Tubarão.jpg",
        "category": [
            {
                "id": "2eb50d53-32c0-4778-bf69-70483f92bc3a",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Tubarões",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3e2944b7-09da-4bcc-bf13-1ba31c89fb9d",
                "path": "product\/Bolo_Decorado_Tubarão.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "2f6df4e6-8661-4ff4-aeb3-879c7fc0704b",
        "product_description": "Cupcakes Decorados Tubarões",
        "product_details": "Cupcakes Decorados Tubarões",
        "product_unique_name": "cupcakes-decorados-tubaroes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Tubarão.jpg",
        "category": [
            {
                "id": "2eb50d53-32c0-4778-bf69-70483f92bc3a",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Tubarões",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d39a2005-1c52-4cbc-9fa6-5e8f9139d3b8",
                "path": "product\/Cupcakes_Decorados_Tubarão.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "8ba39611-3009-4c75-9873-27a2cdb94d2e",
        "product_description": "Doces Decorados Tubarões",
        "product_details": "Doces Decorados Tubarões",
        "product_unique_name": "doces-decorados-tubaroes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Tubarão.jpg",
        "category": [
            {
                "id": "2eb50d53-32c0-4778-bf69-70483f92bc3a",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Tubarões",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3dcf483f-57ec-4946-b756-90101211171b",
                "path": "product\/Doces_Decorados_Tubarão.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "30c57dba-c8a0-4292-870c-647ba53544fe",
        "product_description": "Bolo Decorado Abelinhas ",
        "product_details": "Bolo Decorado Abelinhas ",
        "product_unique_name": "bolo-decorado-abelinhas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Abelinhas.jpg",
        "category": [
            {
                "id": "fe4643aa-2208-4f22-8121-fdd7b44e9e3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Abelinhas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0c6b938c-06d9-44b9-92b4-9ac18beb04d5",
                "path": "product\/Bolo_Decorado_Abelinhas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "830e58c7-74b4-486f-a946-3110a3230e1c",
        "product_description": "Bolo Decorado Abelinhas (Opção 2)",
        "product_details": "Bolo Decorado Abelinhas (Opção 2)",
        "product_unique_name": "bolo-decorado-abelinhas-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Abelinhas_(Opção_2).jpg",
        "category": [
            {
                "id": "fe4643aa-2208-4f22-8121-fdd7b44e9e3d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Abelinhas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ce12ce6e-e54e-43e1-b779-7b659f890786",
                "path": "product\/Bolo_Decorado_Abelinhas_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "03181cc5-608a-4640-9d02-29ce5509a7eb",
        "product_description": "Bolo Decorados Bichinhos ",
        "product_details": "Bolo Decorados Bichinhos ",
        "product_unique_name": "bolo-decorados-bichinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bichinhos_piquenique_do_felipe.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5215f4d5-772f-4914-ab14-f4ac53457d6e",
                "path": "product\/Bolo_Bichinhos_piquenique_do_felipe.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "b8d24df8-8fd7-4d19-96df-b7aa4f31ce04",
        "product_description": "Doces Decorados Bichinhos",
        "product_details": "Doces Decorados Bichinhos",
        "product_unique_name": "doces-decorados-bichinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-01-26T14:23:41.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Piquenique_do_Felipe.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e1bf480a-b5f0-4393-aeb2-527cbd885e8f",
                "path": "product\/Doces_Decorados_Piquenique_do_Felipe.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "59921cfe-9970-4eba-8f9e-4a93832b9360",
        "product_description": "Cupcakes Decorados Bichinhos ",
        "product_details": "Cupcakes Decorados Bichinhos ",
        "product_unique_name": "cupcakes-decorados-bichinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Piquenique_do_Felipe.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d7707942-5176-467c-ad45-78eee82d2448",
                "path": "product\/Cupcakes_Piquenique_do_Felipe.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "0880fb47-31c0-4218-b876-a433a96295c3",
        "product_description": "Cake Pops Bichinhos",
        "product_details": "Cake Pops Bichinhos",
        "product_unique_name": "cake-pops-bichinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cakepops_Piquenique_do_Felipe.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "82b0d3e4-4cad-4a9b-adc5-7a4450ebae61",
                "path": "product\/Cakepops_Piquenique_do_Felipe.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "65db421f-082e-4c2f-bea9-2b734978a9fe",
        "product_description": "Doces Decorados Bichinhos (Opção 2)",
        "product_details": "Doces Decorados Bichinhos (Opção 2)",
        "product_unique_name": "doces-decorados-bichinhos-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Bichinhos.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "461d6c67-7c7e-412b-888b-bd1810460f19",
                "path": "product\/Doces_Decorados_Bichinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "1c8ffb64-e1cc-49b5-8359-d974a0d14813",
        "product_description": "Bolo Decorado Pandas",
        "product_details": "Bolo Decorado Pandas",
        "product_unique_name": "bolo-decorado-pandas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Pandas.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3a5ca7b2-a6ec-4563-b422-9cb8180839ef",
                "path": "product\/Bolo_Decorado_Pandas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "a9b774a6-06d3-4a7c-8085-681d74aa6b6f",
        "product_description": "Cupcakes Decorados Bichinhos (Opção 2)",
        "product_details": "Cupcakes Decorados Bichinhos (Opção 2)",
        "product_unique_name": "cupcakes-decorados-bichinhos-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Bichinhos.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3115b020-d1ce-40d3-9d4c-1afe19305890",
                "path": "product\/Cupcakes_Decorados_Bichinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "462890a9-d9c8-415d-9b69-a78ebf2b46ab",
        "product_description": "Bolo Decorado Bichinhos",
        "product_details": "Bolo Decorado Bichinhos",
        "product_unique_name": "bolo-decorado-bichinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bichinhos_isabela_2_anos.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "df1b4d1c-038a-4e4a-a390-00f1dadf3200",
                "path": "product\/Bolo_Bichinhos_isabela_2_anos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "8f52c1ac-8839-4a14-83c7-caf9a3ebadf2",
        "product_description": "Bolo Decorado Bichinhos e Flores",
        "product_details": "Bolo Decorado Bichinhos e Flores",
        "product_unique_name": "bolo-decorado-bichinhos-e-flores",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Bichinhos.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "852f91d2-444e-4fa0-8c21-e08629372553",
                "path": "product\/Bolo_Bichinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "c5637407-6ac2-464a-9717-fc4f7b3852b6",
        "product_description": "Doces Decorados Bichinhos (Opção 3)",
        "product_details": "Doces Decorados Bichinhos (Opção 3)",
        "product_unique_name": "doces-decorados-bichinhos-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Bichinhos_(Opção_2).jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b1e1cd89-6874-49e4-a230-9129eb069db2",
                "path": "product\/Doces_Decorados_Bichinhos_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "6492c816-a4ec-4a54-9a05-910fd2f5fedc",
        "product_description": "Bolo Decorado Bichinhos (Opção 2)",
        "product_details": "Bolo Decorado Bichinhos (Opção 2)",
        "product_unique_name": "bolo-decorado-bichinhos-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Bichinhos.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d24b5e3d-72cc-41a0-9f3e-5f2a4f84420a",
                "path": "product\/Bolo_Decorado_Bichinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "996cca60-317c-4415-be29-730711606da0",
        "product_description": "Bolo Elefante Personalizado",
        "product_details": "Bolo Elefante Personalizado",
        "product_unique_name": "bolo-elefante-personalizado",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Elefantinho_Personalizado.jpg",
        "category": [
            {
                "id": "c6cf6942-ab0b-40bb-9ec4-9a253c8f79d5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bichinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b1ca4ea5-7202-4aa3-b0d4-5fe79f489918",
                "path": "product\/Bolo_Elefantinho_Personalizado.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "7d4d4f3f-933a-438f-954a-96adb8e0edf4",
        "product_description": "Bolo Decorado PK XD ",
        "product_details": "Bolo Decorado PK XD ",
        "product_unique_name": "bolo-decorado-pk-xd",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_PK_XD.jpg",
        "category": [
            {
                "id": "1abe4f26-017c-4214-aa88-c4b4d18bff8d",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "PK XD",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bb434c35-ddfb-4363-86ca-f510665485ed",
                "path": "product\/Bolo_Decorado_PK_XD.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "8bec2d15-3b37-4af4-8bee-0bec61a01517",
        "product_description": "Bolo Decorado Dia dos Pais (Opção 2)",
        "product_details": "Bolo Decorado Dia dos Pais (Opção 2)",
        "product_unique_name": "bolo-decorado-dia-dos-pais-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dia_dos_Pais_(Opção_2).jpg",
        "category": [
            {
                "id": "d9f56627-a44d-4999-9030-4df2ba279169",
                "theme_id": "db592ea3-4e7a-4598-b792-09767ccec5be",
                "category_description": "Bolos Dia dos Pais",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cd22b867-d7f0-4740-8083-086cbdc06cb9",
                "path": "product\/Bolo_Decorado_Dia_dos_Pais_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "804078aa-ea89-41ed-886e-e133241d00ea",
        "product_description": "Bolo Decorado Dia dos Pais (Opção 3)",
        "product_details": "Bolo Decorado Dia dos Pais (Opção 3)",
        "product_unique_name": "bolo-decorado-dia-dos-pais-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dia_dos_Pais_(Opção_3).jpg",
        "category": [
            {
                "id": "d9f56627-a44d-4999-9030-4df2ba279169",
                "theme_id": "db592ea3-4e7a-4598-b792-09767ccec5be",
                "category_description": "Bolos Dia dos Pais",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d0752f10-21df-4d2d-bfce-ac0f0130f12a",
                "path": "product\/Bolo_Decorado_Dia_dos_Pais_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "c8460589-e3c1-4db7-b3b1-e225df39f2f4",
        "product_description": "Bolo Decorado Dia dos Pais",
        "product_details": "Bolo Decorado Dia dos Pais",
        "product_unique_name": "bolo-decorado-dia-dos-pais",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dia_dos_Pais_(Opção_4).jpg",
        "category": [
            {
                "id": "d9f56627-a44d-4999-9030-4df2ba279169",
                "theme_id": "db592ea3-4e7a-4598-b792-09767ccec5be",
                "category_description": "Bolos Dia dos Pais",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "34625f50-39d8-45ec-a506-73d8e34ee474",
                "path": "product\/Bolo_Decorado_Dia_dos_Pais_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "72fff3b1-a313-4f1b-8350-5ec0bcb61c8c",
        "product_description": "Bolo Decorado Dia dos Pais (Opção 5)",
        "product_details": "Bolo Decorado Dia dos Pais (Opção 5)",
        "product_unique_name": "bolo-decorado-dia-dos-pais-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dia_dos_Pais_(Opção_5).jpg",
        "category": [
            {
                "id": "d9f56627-a44d-4999-9030-4df2ba279169",
                "theme_id": "db592ea3-4e7a-4598-b792-09767ccec5be",
                "category_description": "Bolos Dia dos Pais",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2d73c4d5-891b-4406-8288-17d3301a931e",
                "path": "product\/Bolo_Decorado_Dia_dos_Pais_(Opção_5).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "cd04b01d-564e-4414-a5d8-ad6eb8403665",
        "product_description": "Bolo Decorado Alice no País das Maravilhas",
        "product_details": "Bolo Decorado Alice no País das Maravilhas",
        "product_unique_name": "bolo-decorado-alice-no-pais-das-maravilhas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_decorado_Alice_no_País_das_Maravilhas.jpg",
        "category": [
            {
                "id": "2b996ce1-a060-4fdd-882f-eb5030a8d450",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Alice no País das Maravilhas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d7d9da1c-b54d-4eda-96ff-5f6f34652feb",
                "path": "product\/Bolo_decorado_Alice_no_País_das_Maravilhas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "8b29eada-15de-448a-8c8d-68cd03b0b126",
        "product_description": "Cupcakes Decorados Alice no País das Maravilhas",
        "product_details": "Cupcakes Decorados Alice no País das Maravilhas",
        "product_unique_name": "cupcakes-decorados-alice-no-pais-das-maravilhas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Alice_no_País_das_Maravilhas.jpg",
        "category": [
            {
                "id": "2b996ce1-a060-4fdd-882f-eb5030a8d450",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Alice no País das Maravilhas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d9b5b641-7b1c-4390-929c-dbf5d2ad6b9b",
                "path": "product\/Cupcakes_Decorados_Alice_no_País_das_Maravilhas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "fe7fa3bc-8808-4311-8a77-c5f4824b0ca6",
        "product_description": "Doces Decorados Alice no País das Maravilhas",
        "product_details": "Doces Decorados Alice no País das Maravilhas",
        "product_unique_name": "doces-decorados-alice-no-pais-das-maravilhas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Alice_no_País_das_Maravilhas.jpg",
        "category": [
            {
                "id": "2b996ce1-a060-4fdd-882f-eb5030a8d450",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Alice no País das Maravilhas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "68a85f5a-eed2-4048-979a-37f80df70365",
                "path": "product\/Doces_Decorados_Alice_no_País_das_Maravilhas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "6892aa91-0b97-4b93-a476-4c79f20ad102",
        "product_description": "Bolo Decorado Lhamas",
        "product_details": "Bolo Decorado Lhamas",
        "product_unique_name": "bolo-decorado-lhamas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Lhama.jpg",
        "category": [
            {
                "id": "899b3dc1-8739-4cab-b576-7a173b143e7c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lhamas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "38807e61-2c0a-4b19-9564-7d6cd2a81936",
                "path": "product\/Bolo_Decorado_Lhama.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "0caae48d-7cb3-430d-ac1c-eb76cd3fd31a",
        "product_description": "Cupcakes Decorados Lhamas",
        "product_details": "Cupcakes Decorados Lhamas",
        "product_unique_name": "cupcakes-decorados-lhamas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Lhamas.jpg",
        "category": [
            {
                "id": "899b3dc1-8739-4cab-b576-7a173b143e7c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lhamas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9618f0e2-7eeb-472d-8dc5-0848937c2dae",
                "path": "product\/Cupcakes_Decorados_Lhamas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "41f6a9fe-d3b8-4809-af02-abe632ed3838",
        "product_description": "Pirulitos Decorados Lhamas",
        "product_details": "Pirulitos Decorados Lhamas",
        "product_unique_name": "pirulitos-decorados-lhamas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_de_Chocolate,_Lhamas.jpg",
        "category": [
            {
                "id": "899b3dc1-8739-4cab-b576-7a173b143e7c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lhamas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0805e1f9-e582-4426-a969-26e6adf905ed",
                "path": "product\/Pirulitos_de_Chocolate,_Lhamas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "17d991c0-2c11-4ef5-a373-2dfc60c0ccc1",
        "product_description": "Doces Decorados Lhamas",
        "product_details": "Doces Decorados Lhamas",
        "product_unique_name": "doces-decorados-lhamas",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Lhamas.jpg",
        "category": [
            {
                "id": "899b3dc1-8739-4cab-b576-7a173b143e7c",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Lhamas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6d1bbce8-fa09-4576-a2ca-4eb180e52136",
                "path": "product\/Doces_Decorados_Lhamas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "dd574eb8-ec96-459e-b263-1fb8e8b7e55c",
        "product_description": "Bolo Decorado Rei Leão",
        "product_details": "Bolo Decorado Rei Leão",
        "product_unique_name": "bolo-decorado-rei-leao",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Rei_Leão.jpg",
        "category": [
            {
                "id": "de04767c-acfe-4556-b38b-b42a023fd121",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Rei Leão",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "573b3932-f3f4-4669-bf15-97a7d429b8c6",
                "path": "product\/Bolo_Decorado_Rei_Leão.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "1d718087-d58b-410d-a818-fb417c5c294e",
        "product_description": "Doces Decorados Rei Leão",
        "product_details": "Doces Decorados Rei Leão",
        "product_unique_name": "doces-decorados-rei-leao",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Rei_Leão.jpg",
        "category": [
            {
                "id": "de04767c-acfe-4556-b38b-b42a023fd121",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Rei Leão",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "607ea511-fb94-478c-a894-b1e7140781d6",
                "path": "product\/Doces_Decorados_Rei_Leão.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "8a72476e-cc8f-42f4-a453-3bbb69f85345",
        "product_description": "Doces Decorados Esquilos de Natal",
        "product_details": "Doces Decorados Esquilos de Natal",
        "product_unique_name": "doces-decorados-esquilos-de-natal",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-02-02T21:45:29.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Esquilos_de_Natal.jpg",
        "category": [
            {
                "id": "617dc314-4f71-429e-80d1-c0422d5e7503",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Esquilos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "a3ac87b5-46f7-49b4-983f-b1809415b9b3",
                "event_description": "Xmas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "0be03c2f-e3bd-40f2-889e-e590b2d3722f",
                "path": "product\/Doces_Decorados_Esquilos_de_Natal.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "f31b62aa-e73d-4296-9cfa-abd9f69e625b",
        "product_description": "Bolo Naked Dia dos Pais",
        "product_details": "Bolo Naked Dia dos Pais",
        "product_unique_name": "bolo-naked-dia-dos-pais",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-02-02T21:48:18.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Naked_Dia_dos_Pais.jpg",
        "category": [
            {
                "id": "d9f56627-a44d-4999-9030-4df2ba279169",
                "theme_id": "db592ea3-4e7a-4598-b792-09767ccec5be",
                "category_description": "Bolos Dia dos Pais",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "301614d5-5bb5-453c-9c3c-c4a55a2f9fdb",
                "event_description": "Dia dos Pais",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "39125798-4ec4-46b2-85cf-68b1a47f6150",
                "path": "product\/Bolo_Naked_Dia_dos_Pais.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "eedf7566-56a1-4f73-ac6f-de6cbdcd11c8",
        "product_description": "Bolo Dia dos Pais",
        "product_details": "Bolo Dia dos Pais",
        "product_unique_name": "bolo-dia-dos-pais",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-02-02T21:48:39.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Dia_dos_Pais.jpg",
        "category": [
            {
                "id": "d9f56627-a44d-4999-9030-4df2ba279169",
                "theme_id": "db592ea3-4e7a-4598-b792-09767ccec5be",
                "category_description": "Bolos Dia dos Pais",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "301614d5-5bb5-453c-9c3c-c4a55a2f9fdb",
                "event_description": "Dia dos Pais",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "194f6faa-31cb-456d-a383-4e9952eabc0c",
                "path": "product\/Bolo_Decorado_Dia_dos_Pais.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "461ab9cf-1c2d-451d-88ce-aad164d893eb",
        "product_description": "Cupcakes Decorados Rei Leão",
        "product_details": "Cupcakes Decorados Rei Leão",
        "product_unique_name": "cupcakes-decorados-rei-leao",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Rei_Leão.jpg",
        "category": [
            {
                "id": "de04767c-acfe-4556-b38b-b42a023fd121",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Rei Leão",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "98758d51-c61c-4075-9d21-866da8e74c3d",
                "path": "product\/Cupcakes_Decorados_Rei_Leão.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "9fcf1ea0-cc24-45f3-b73c-5d61937547cf",
        "product_description": "Bolo Decorado Rei Leão (Opção 2)",
        "product_details": "Bolo Decorado Rei Leão (Opção 2)",
        "product_unique_name": "bolo-decorado-rei-leao-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Rei_Leão_(Opção_2).jpg",
        "category": [
            {
                "id": "de04767c-acfe-4556-b38b-b42a023fd121",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Rei Leão",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e95b6443-ec2a-4f4e-9f78-cfa3469e7d06",
                "path": "product\/Bolo_Decorado_Rei_Leão_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "3cdf4271-c78e-40bf-8ce2-9d42ef9513f4",
        "product_description": "Bolo Rei Leão ",
        "product_details": "Bolo Rei Leão ",
        "product_unique_name": "bolo-rei-leao",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Rei_Leão_(Opção_3).jpg",
        "category": [
            {
                "id": "de04767c-acfe-4556-b38b-b42a023fd121",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Rei Leão",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e8702b23-0ad6-4dcc-97cb-195b29972f13",
                "path": "product\/Bolo_Decorado_Rei_Leão_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "b8267d57-3198-4ef5-8fdf-34c5dc9422cf",
        "product_description": "Cupcakes Decorados Rei Leão (Opção 3)",
        "product_details": "Cupcakes Decorados Rei Leão (Opção 3)",
        "product_unique_name": "cupcakes-decorados-rei-leao-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Rei_Leão_(Opção_3).jpg",
        "category": [
            {
                "id": "de04767c-acfe-4556-b38b-b42a023fd121",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Rei Leão",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4ff545da-2706-4252-878a-3b611c96727f",
                "path": "product\/Cupcakes_Decorados_Rei_Leão_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "f1108fa7-4384-411d-a0d4-944809a9766f",
        "product_description": "Bolo Decorado Pequeno Principe",
        "product_details": "Bolo Decorado Pequeno Principe",
        "product_unique_name": "bolo-decorado-pequeno-principe",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Pequeno_Principe.jpg",
        "category": [
            {
                "id": "43f33064-d669-4041-bcdb-4c322b6a1fa6",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pequeno Principe",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "84f14eb1-a605-4464-9f6d-ac5e44cada11",
                "path": "product\/Bolo_Decorado_Pequeno_Principe.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "b3f572c4-add7-4110-ac0d-abe5f8f7daf9",
        "product_description": "Cupcake Decorado Pequeno Principe",
        "product_details": "Cupcake Decorado Pequeno Principe",
        "product_unique_name": "cupcake-decorado-pequeno-principe",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Pequeno_Principe.jpg",
        "category": [
            {
                "id": "43f33064-d669-4041-bcdb-4c322b6a1fa6",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pequeno Principe",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c4fb208c-0f63-4a4b-943b-c02cf5cbb75b",
                "path": "product\/Cupcakes_Decorados_Pequeno_Principe.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "8fc3deee-65e0-4028-af73-e5d9f0a10323",
        "product_description": "Bolo Decorado Fortinite",
        "product_details": "Bolo Decorado Fortinite",
        "product_unique_name": "bolo-decorado-fortinite",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Fortinite.jpg",
        "category": [
            {
                "id": "5cdadf00-03d6-4f7d-b359-ee0a46955223",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fortinite",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3cf99cc6-312e-43c6-8073-0cef777765a3",
                "path": "product\/Bolo_Decorado_Fortinite.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "0252ad20-a753-47e8-a001-a42323973240",
        "product_description": "Cupcakes Decorados Fortinite",
        "product_details": "Cupcakes Decorados Fortinite",
        "product_unique_name": "cupcakes-decorados-fortinite",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorado_Fortinite.jpg",
        "category": [
            {
                "id": "5cdadf00-03d6-4f7d-b359-ee0a46955223",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fortinite",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "98a2dc24-009c-46d2-a3e9-8b8924d1fb27",
                "path": "product\/Cupcakes_Decorado_Fortinite.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "d236e574-8118-4a64-83ec-598932365238",
        "product_description": "Bolo Decorado Peppa",
        "product_details": "Bolo Decorado Peppa",
        "product_unique_name": "bolo-decorado-peppa",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Peppa.jpg",
        "category": [
            {
                "id": "1f115945-4a7a-468c-8b68-19f01dd76d00",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Peppa",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b241023a-3105-4b80-bee3-f90ef83bfb3d",
                "path": "product\/Bolo_Decorado_Peppa.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "d849d4f5-8a59-4b08-86c4-5ed9cbdb2e95",
        "product_description": "Bolo Decorado Guardiões da Galáxia ",
        "product_details": "Bolo Decorado Guardiões da Galáxia ",
        "product_unique_name": "bolo-decorado-guardioes-da-galaxia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Guardiões_da_Galáxia.jpg",
        "category": [
            {
                "id": "339271a7-ac92-450a-976a-e89156792d38",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Guardiões da Galáxia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ced8d19f-43b8-4528-9854-0db0e88fdb7a",
                "path": "product\/Bolo_Decorado_Guardiões_da_Galáxia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "1d0684d4-4bb2-459a-8d63-1e36c8f81b85",
        "product_description": "Cupcakes Decorados Guardiões da Galáxia",
        "product_details": "Cupcakes Decorados Guardiões da Galáxia",
        "product_unique_name": "cupcakes-decorados-guardioes-da-galaxia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Guardiões_da_Galáxia.jpg",
        "category": [
            {
                "id": "339271a7-ac92-450a-976a-e89156792d38",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Guardiões da Galáxia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9f5da00d-e981-4e97-b0c9-0f255dca8b6a",
                "path": "product\/Cupcakes_Decorados_Guardiões_da_Galáxia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "008c8362-caec-4f52-86d9-da73376044d1",
        "product_description": "Doces Decorados Guardiões da Galáxia",
        "product_details": "Doces Decorados Guardiões da Galáxia",
        "product_unique_name": "doces-decorados-guardioes-da-galaxia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Guardiões_da_Galáxia.jpg",
        "category": [
            {
                "id": "339271a7-ac92-450a-976a-e89156792d38",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Guardiões da Galáxia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "60407d9d-bb1c-4850-bc0c-0dbe68d53b39",
                "path": "product\/Doces_Decorados_Guardiões_da_Galáxia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "72bbf47f-e361-4f14-af39-7d785c346e52",
        "product_description": "Pirulitos Decorados Guardiões da Galáxia",
        "product_details": "Pirulitos Decorados Guardiões da Galáxia",
        "product_unique_name": "pirulitos-decorados-guardioes-da-galaxia",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Guardiões_da_Galáxia.jpg",
        "category": [
            {
                "id": "339271a7-ac92-450a-976a-e89156792d38",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Guardiões da Galáxia",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ed2a3b57-20ff-4fd9-8f3f-f7d835700deb",
                "path": "product\/Pirulitos_Decorados_Guardiões_da_Galáxia.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "164792e0-60aa-4ec5-b2f9-434974258f19",
        "product_description": "Bolo Decorado DPA ",
        "product_details": "Bolo Decorado DPA ",
        "product_unique_name": "bolo-decorado-dpa",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_DPA.jpg",
        "category": [
            {
                "id": "8371a25b-988c-4bfd-ac47-a7d217e13bf2",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "DPA",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e514574c-064e-4655-874b-08c9cd2f8bb8",
                "path": "product\/Bolo_Decorado_DPA.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "5e0b0cc4-8736-4dd0-bc25-25fffa5ac4cd",
        "product_description": "Pirulitos Decorados DPA",
        "product_details": "Pirulitos Decorados DPA",
        "product_unique_name": "pirulitos-decorados-dpa",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_DPA.jpg",
        "category": [
            {
                "id": "8371a25b-988c-4bfd-ac47-a7d217e13bf2",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "DPA",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bb70b984-c052-4cb8-8e0c-10d8e27d803d",
                "path": "product\/Pirulitos_Decorados_DPA.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "fcbcba45-d74d-450a-8330-b781b3024e5f",
        "product_description": "Bolo Decorado Rapunzel",
        "product_details": "Bolo Decorado Rapunzel",
        "product_unique_name": "bolo-decorado-rapunzel",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Rapunzel.jpg",
        "category": [
            {
                "id": "3c80d271-b6af-46b2-8d31-4bf344120269",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Rapunzel",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cc7dac7d-ccf1-4747-b81d-4a6d16165737",
                "path": "product\/Bolo_Decorado_Rapunzel.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "b4d83a74-73e7-4d93-b696-52684fa86c1a",
        "product_description": "Bolo Decorado Pintinhos",
        "product_details": "Bolo Decorado Pintinhos",
        "product_unique_name": "bolo-decorado-pintinhos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Pintinhos.jpg",
        "category": [
            {
                "id": "b8e61d47-00e8-4a45-9f8d-9d33fc84eb72",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pintinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "69fc1399-76c0-4b74-999e-b228c301eb3d",
                "path": "product\/Bolo_Decorado_Pintinhos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "013d9cc5-4791-4934-9faa-6feed0cc669c",
        "product_description": "Bolo Decorado Rayman",
        "product_details": "Bolo Decorado Rayman",
        "product_unique_name": "bolo-decorado-rayman",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Rayman.jpg",
        "category": [
            {
                "id": "b2a2732a-7c3b-474b-8706-76415559517b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Rayman",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c83f8702-dbae-496a-afa3-fb3b0674d605",
                "path": "product\/Bolo_Decorado_Rayman.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "9f4fe0e4-0233-43cc-8d7c-6721adbd9ca1",
        "product_description": "Fudges Decorados Rayman ",
        "product_details": "Fudges Decorados Rayman ",
        "product_unique_name": "fudges-decorados-rayman",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Fudge_Rayman.jpg",
        "category": [
            {
                "id": "b2a2732a-7c3b-474b-8706-76415559517b",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Rayman",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "912377eb-a774-4e28-a4ac-215c40e0caa6",
                "path": "product\/Fudge_Rayman.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "bf9f3cf7-95ef-4129-9350-950c13221c0c",
        "product_description": "Bolo Decorado Scooby Doo",
        "product_details": "Bolo Decorado Scooby Doo",
        "product_unique_name": "bolo-decorado-scooby-doo",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Scooby_Doo.jpg",
        "category": [
            {
                "id": "4a87c165-ca3c-4de2-a40b-c18c74827fbd",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Scooby Doo",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "68715618-0898-4210-b203-dc7105907831",
                "path": "product\/Bolo_Decorado_Scooby_Doo.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "cf46e837-c9e1-4b7a-bd58-efe2e2350af2",
        "product_description": "Cupcakes Decorados Scooby Doo",
        "product_details": "Cupcakes Decorados Scooby Doo",
        "product_unique_name": "cupcakes-decorados-scooby-doo",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcake_Decorado_Scooby_Doo.jpg",
        "category": [
            {
                "id": "4a87c165-ca3c-4de2-a40b-c18c74827fbd",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Scooby Doo",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "32a61798-5fb3-4735-9a5e-c5a58db4c674",
                "path": "product\/Cupcake_Decorado_Scooby_Doo.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "65007724-eec2-4353-95d2-21536c3e89cd",
        "product_description": "Torta de Palha Italiana",
        "product_details": "Torta de Palha Italiana",
        "product_unique_name": "torta-de-palha-italiana",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/WhatsApp_Image_2021-07-31_at_09_52_30.jpeg",
        "category": [
            {
                "id": "72e63ebf-d97a-4ec4-96aa-34d0c6a2cb5a",
                "theme_id": "2a14ff8a-985e-4840-b5c2-dfd21aae81c8",
                "category_description": "Palha Italiana",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d9dbdc67-418e-4d73-88bb-a0e19173cbe2",
                "path": "product\/WhatsApp_Image_2021-07-31_at_09_52_30.jpeg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "0c5b83b7-c483-4130-821c-6585912051f2",
        "product_description": "Torta de Nozes com baba de moça",
        "product_details": "Torta de Nozes com baba de moça",
        "product_unique_name": "torta-de-nozes-com-baba-de-moca",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Torta_de_Nozes1.jpg",
        "category": [
            {
                "id": "b8c0a6ed-4bcc-4d15-b22c-95491523ff85",
                "theme_id": "2a14ff8a-985e-4840-b5c2-dfd21aae81c8",
                "category_description": "Torta de Nozes",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "79dd1dd5-ae74-495e-aa54-d4cb0724d7d5",
                "path": "product\/Torta_de_Nozes1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "f9df2974-24dd-4f74-ac4c-a5bbaed99fda",
        "product_description": "Torta Moranguita ",
        "product_details": "Torta Moranguita ",
        "product_unique_name": "torta-moranguita",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Moranguita.jpg",
        "category": [
            {
                "id": "f0d6ab9e-f948-43ce-b604-657ab3fa248e",
                "theme_id": "2a14ff8a-985e-4840-b5c2-dfd21aae81c8",
                "category_description": "Moranguita",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "eaf1ea48-5374-4b2d-a869-93636545a784",
                "path": "product\/Moranguita.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "b0f579ff-3a5e-47d8-ab24-c6b1b7a86d46",
        "product_description": "Bolo Decorado Guarda do Leão",
        "product_details": "Bolo Decorado Guarda do Leão",
        "product_unique_name": "bolo-decorado-guarda-do-leao",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Guarda_do_Leão.jpg",
        "category": [
            {
                "id": "474341a8-3056-4ddc-94c5-5152921620b5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Guarda do Leão",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "95d94bdc-a018-412c-bd99-91127095520b",
                "path": "product\/Bolo_Decorado_Guarda_do_Leão.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "bcc388da-e6e2-42e3-b77e-9ef9c4351170",
        "product_description": "Cupcakes Decorados Guarda do Leão",
        "product_details": "Cupcakes Decorados Guarda do Leão",
        "product_unique_name": "cupcakes-decorados-guarda-do-leao",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Guarda_do_Leão.jpg",
        "category": [
            {
                "id": "474341a8-3056-4ddc-94c5-5152921620b5",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Guarda do Leão",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e084bcf2-1137-42c0-9e31-b7069ba7ed56",
                "path": "product\/Cupcakes_Decorados_Guarda_do_Leão.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "f4de086a-650f-4ad5-bf1a-af9d4641e702",
        "product_description": "Bolo Decorado Fidget Toys",
        "product_details": "Bolo Decorado Fidget Toys",
        "product_unique_name": "bolo-decorado-fidget-toys",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Fidget_Toys.jpg",
        "category": [
            {
                "id": "518f7a6e-43d2-45c8-ad8b-489c5ca63074",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fidget Toys",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "86db774a-1c15-4590-9703-30eda3488ad9",
                "path": "product\/Bolo_Decorado_Fidget_Toys.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "980a9c1e-89a7-46bd-ac4d-41ec77c15cf6",
        "product_description": "Cupcakes Decorados Fidget Toys",
        "product_details": "Cupcakes Decorados Fidget Toys",
        "product_unique_name": "cupcakes-decorados-fidget-toys",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Fidget_Toys.jpg",
        "category": [
            {
                "id": "518f7a6e-43d2-45c8-ad8b-489c5ca63074",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fidget Toys",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "114b3f37-e792-4bea-8876-ee2fac14fa78",
                "path": "product\/Cupcakes_Decorados_Fidget_Toys.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "4ff674f8-c332-4d91-9831-a86a2961569b",
        "product_description": "Bolo Decorado Fidget Toys (Opção 2)",
        "product_details": "Bolo Decorado Fidget Toys (Opção 2)",
        "product_unique_name": "bolo-decorado-fidget-toys-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:42.000000Z",
        "updated_at": "2023-01-26T14:23:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Fidget_Toys_(Opção_2).jpg",
        "category": [
            {
                "id": "518f7a6e-43d2-45c8-ad8b-489c5ca63074",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fidget Toys",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a9fba5e2-bd62-41a4-950e-463b65b64522",
                "path": "product\/Bolo_Decorado_Fidget_Toys_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:42.000000Z",
                "updated_at": "2023-01-26T14:23:42.000000Z"
            }
        ]
    },
    {
        "id": "8ba02ac8-d3e2-4aae-a9d1-7c40214a7e05",
        "product_description": "Cupcakes Decorados Fidget Toys (Opção 2)",
        "product_details": "Cupcakes Decorados Fidget Toys (Opção 2)",
        "product_unique_name": "cupcakes-decorados-fidget-toys-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Fidget_Toys_Opção_2.jpg",
        "category": [
            {
                "id": "518f7a6e-43d2-45c8-ad8b-489c5ca63074",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fidget Toys",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "62c9a365-7e50-4457-8f0b-25276422763f",
                "path": "product\/Cupcakes_Decorados_Fidget_Toys_Opção_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "3d395b74-49c2-41d0-8436-33558e0e6423",
        "product_description": "Bolo Decorado Fidget Toys (Opção 3)",
        "product_details": "Bolo Decorado Fidget Toys (Opção 3)",
        "product_unique_name": "bolo-decorado-fidget-toys-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Fidget_Toys_(Opção_3).jpg",
        "category": [
            {
                "id": "518f7a6e-43d2-45c8-ad8b-489c5ca63074",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Fidget Toys",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "896537fb-adc7-4f12-a06d-32e1dc0c8b63",
                "path": "product\/Bolo_Decorado_Fidget_Toys_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "79987951-0689-486a-af6f-9eba7f0b5e63",
        "product_description": "Bolo Decorado Naruto",
        "product_details": "Bolo Decorado Naruto",
        "product_unique_name": "bolo-decorado-naruto",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Narurto.jpg",
        "category": [
            {
                "id": "b959f243-b8c7-40e5-8499-3e056a902eb6",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Naruto",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "93ba64af-7f2d-4e3a-8a72-e5f900701c25",
                "path": "product\/Bolo_Decorado_Narurto.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "305c133e-e7a1-438b-a6d7-767697eee2dc",
        "product_description": "Cookies Decorados Naruto",
        "product_details": "Cookies Decorados Naruto",
        "product_unique_name": "cookies-decorados-naruto",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Cookies_Decorados_Naruto.jpg",
        "category": [
            {
                "id": "b959f243-b8c7-40e5-8499-3e056a902eb6",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Naruto",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7b629af2-f242-401d-a123-6972cf9bcd55",
                "path": "product\/Cookies_Decorados_Naruto.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "8b07baaf-193f-4d5d-a517-7cd7639043af",
        "product_description": "Bolo Decorado Piñata ",
        "product_details": "Bolo Decorado Piñata ",
        "product_unique_name": "bolo-decorado-piñata",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Piñata.jpg",
        "category": [
            {
                "id": "f3e26eb6-115e-4e79-8959-1c129d7abfc1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Piñata",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a0f547cb-9951-40d9-94ec-c66d04fdb0fa",
                "path": "product\/Bolo_Decorado_Piñata.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "86a2cbea-b16b-443a-8b5c-3a050163f0b6",
        "product_description": "Pirulitos Decorados Piñata ",
        "product_details": "Pirulitos Decorados Piñata ",
        "product_unique_name": "pirulitos-decorados-piñata",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Decorados_Piñata.jpg",
        "category": [
            {
                "id": "f3e26eb6-115e-4e79-8959-1c129d7abfc1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Piñata",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6110e984-ed16-4de2-8d07-a4bd557be050",
                "path": "product\/Pirulitos_Decorados_Piñata.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "941e4b03-8f58-4dc3-b194-ca99134f5081",
        "product_description": "Bolo Decorado Chapeuzinho Vermelho (Opção 2)",
        "product_details": "Bolo Decorado Chapeuzinho Vermelho (Opção 2)",
        "product_unique_name": "bolo-decorado-chapeuzinho-vermelho-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Chapeuzinho_Vermelho.jpg",
        "category": [
            {
                "id": "f2565eb2-a667-4750-bda1-7d32a41baa9e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Chapeuzinho Vermelho",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5ffa1873-815d-40e7-ba62-aae6786e46d2",
                "path": "product\/Cupcakes_Decorados_Chapeuzinho_Vermelho.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "2151b6e0-6f92-4cae-b837-837ef4abddf9",
        "product_description": "Cupcakes Decorados Chapeuzinho Vermelho",
        "product_details": "Cupcakes Decorados Chapeuzinho Vermelho",
        "product_unique_name": "cupcakes-decorados-chapeuzinho-vermelho",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Chapeuzinho_Vermelho1.jpg",
        "category": [
            {
                "id": "f2565eb2-a667-4750-bda1-7d32a41baa9e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Chapeuzinho Vermelho",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "00004557-d05e-4445-a4d8-1c73e12d5423",
                "path": "product\/Cupcakes_Decorados_Chapeuzinho_Vermelho1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "5e21be06-947d-4cb3-82b2-ce41caf1b4a6",
        "product_description": "Bolo Decorado Construção ",
        "product_details": "Bolo Decorado Construção ",
        "product_unique_name": "bolo-decorado-construcao",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Máquinas.jpg",
        "category": [
            {
                "id": "32b72d9e-2940-4b74-a955-a254d72f77d3",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Construção",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cc2d7c74-3fc4-4ab2-95f4-63b550318c82",
                "path": "product\/Bolo_Decorado_Máquinas.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "eb4bff55-72a7-432c-b764-6c5dfcda5363",
        "product_description": "Bolo De Flores (Opção 1)",
        "product_details": "Bolo De Flores (Opção 1)",
        "product_unique_name": "bolo-de-flores-opcao-1",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_1).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8d326a4f-6613-4f85-b1cf-2fb33dd0b115",
                "path": "product\/Bolo_de_Flores_(Opção_1).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "36f85d64-1b48-4e62-9b25-3bcdbc38deea",
        "product_description": "Bolo de Flores (Opção 2)",
        "product_details": "Bolo de Flores (Opção 2)",
        "product_unique_name": "bolo-de-flores-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_2).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a0c9866d-3949-4f5d-96f6-60a2c58d137d",
                "path": "product\/Bolo_de_Flores_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "2d9571df-891e-436b-8681-5c65c56ab32d",
        "product_description": "Bolo de Flores (Opção 3)",
        "product_details": "Bolo de Flores (Opção 3)",
        "product_unique_name": "bolo-de-flores-opcao-3",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_3).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f067a556-4386-44d2-b045-ad68c0c641f3",
                "path": "product\/Bolo_de_Flores_(Opção_3).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "2b94eeae-ef3b-44d2-809e-a00226a8f71a",
        "product_description": "Bolo de Flores (Opção 4) ",
        "product_details": "Bolo de Flores (Opção 4) ",
        "product_unique_name": "bolo-de-flores-opcao-4",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_4).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "080aead4-fbfb-49fd-9501-ea8e6210e4a4",
                "path": "product\/Bolo_de_Flores_(Opção_4).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "a377f3b1-139a-4be4-ac6a-eaba8b919b01",
        "product_description": "Bolo de Flores (Opção 5) ",
        "product_details": "Bolo de Flores (Opção 5) ",
        "product_unique_name": "bolo-de-flores-opcao-5",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_5).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4b8e3d5f-b741-4ba9-bf3c-6602d1ab85ab",
                "path": "product\/Bolo_de_Flores_(Opção_5).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "aa8ada6a-5c6b-4efa-92ea-11679ece7ecd",
        "product_description": "Bolo de Flores (Opção 6)",
        "product_details": "Bolo de Flores (Opção 6)",
        "product_unique_name": "bolo-de-flores-opcao-6",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_6)1.jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4d1cdd4c-8e80-4655-ac8d-849657f8ba80",
                "path": "product\/Bolo_de_Flores_(Opção_6)1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "f952d99c-4b05-45fb-aaf4-622bdb30d1e8",
        "product_description": "Bolo de Flores (Opção 7)",
        "product_details": "Bolo de Flores (Opção 7)",
        "product_unique_name": "bolo-de-flores-opcao-7",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_7).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "46a4c515-f6c2-4d59-ba0b-cee726e19108",
                "path": "product\/Bolo_de_Flores_(Opção_7).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "fca473db-9dee-4d66-85b6-a4868fff482c",
        "product_description": "Bolo de Flores (Opção 8)",
        "product_details": "Bolo de Flores (Opção 8)",
        "product_unique_name": "bolo-de-flores-opcao-8",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_8).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e68ee72b-044a-4cbf-9016-f0b87fa9a7e2",
                "path": "product\/Bolo_de_Flores_(Opção_8).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "0c48d7b1-2ead-42fb-985b-56c41f4c9ce9",
        "product_description": "Bolo de Flores (Opção 9)",
        "product_details": "Bolo de Flores (Opção 9)",
        "product_unique_name": "bolo-de-flores-opcao-9",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_9).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "150bf664-f07d-4a0b-a917-9df2926e54f6",
                "path": "product\/Bolo_de_Flores_(Opção_9).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "0b07d826-63b8-4e28-9a8e-31dd43585c09",
        "product_description": "Bolo de Flores (Opção 10)",
        "product_details": "Bolo de Flores (Opção 10)",
        "product_unique_name": "bolo-de-flores-opcao-10",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_10).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "357959eb-a938-4b0a-a086-c6fe8c2d9854",
                "path": "product\/Bolo_de_Flores_(Opção_10).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "e3a5bca7-1422-407a-8522-d5c652387b1e",
        "product_description": "Bolos de Flores (Opção 11)",
        "product_details": "Bolos de Flores (Opção 11)",
        "product_unique_name": "bolos-de-flores-opcao-11",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_11)1.jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7acbb4e2-a990-4087-9092-52313ed86b41",
                "path": "product\/Bolo_de_Flores_(Opção_11)1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "c87c769e-82f4-4c7a-aa99-01d5742588ac",
        "product_description": "Bolo de Flores (Opção 12)",
        "product_details": "Bolo de Flores (Opção 12)",
        "product_unique_name": "bolo-de-flores-opcao-12",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_12).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "085601bc-7c2b-4968-8bce-e4d9550e71d8",
                "path": "product\/Bolo_de_Flores_(Opção_12).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "2785df81-2b09-4276-b489-180903f22ba2",
        "product_description": "Bolo de Flores (Opção 13)",
        "product_details": "Bolo de Flores (Opção 13)",
        "product_unique_name": "bolo-de-flores-opcao-13",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_13).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a61b4493-d3d7-4810-b877-993c2cf83ace",
                "path": "product\/Bolo_de_Flores_(Opção_13).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "26e0de79-b21e-4178-9816-30b42db15588",
        "product_description": "Bolo de Flores (Opção 15)",
        "product_details": "Bolo de Flores (Opção 15)",
        "product_unique_name": "bolo-de-flores-opcao-15",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_15)1.jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ea9b4d2f-82e5-4a11-ab34-304780aff0ee",
                "path": "product\/Bolo_de_Flores_(Opção_15)1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "29e60b5e-8ee6-4fb1-834d-73cfd953fc07",
        "product_description": "Bolo de Flores (Opção 16)",
        "product_details": "Bolo de Flores (Opção 16)",
        "product_unique_name": "bolo-de-flores-opcao-16",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_16).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "59d6f496-fb5d-413c-a9f1-7dfdb9b1e59e",
                "path": "product\/Bolo_de_Flores_(Opção_16).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "408c702e-1279-46cb-876d-f431ef886ad1",
        "product_description": "Bolo de Flores (Opção 17)",
        "product_details": "Bolo de Flores (Opção 17)",
        "product_unique_name": "bolo-de-flores-opcao-17",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_17).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7a848cbe-11ef-4d2b-8b2c-9c323a590718",
                "path": "product\/Bolo_de_Flores_(Opção_17).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "44b97385-52dc-4d03-89c5-6524b721a2e3",
        "product_description": "Bolo de Flores (Opção 18)",
        "product_details": "Bolo de Flores (Opção 18)",
        "product_unique_name": "bolo-de-flores-opcao-18",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_18).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "38acd436-48fe-48ce-acf9-2c8cae5986f8",
                "path": "product\/Bolo_de_Flores_(Opção_18).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "5f8c823e-b9e0-4a0e-a76c-dd045f7301a1",
        "product_description": "Bolo de Flores (Opção 19)",
        "product_details": "Bolo de Flores (Opção 19)",
        "product_unique_name": "bolo-de-flores-opcao-19",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_19).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e4fa1c7e-b16f-4512-b58d-c86fd0eaa81b",
                "path": "product\/Bolo_de_Flores_(Opção_19).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "69f32192-caa3-4425-8c53-d87f20e1e5fe",
        "product_description": "Bolo de Flores (Opção 20)",
        "product_details": "Bolo de Flores (Opção 20)",
        "product_unique_name": "bolo-de-flores-opcao-20",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_20).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "190db280-3feb-42e1-afc0-68f477966053",
                "path": "product\/Bolo_de_Flores_(Opção_20).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "5259c23b-79ce-484f-a59a-0b14e9a61608",
        "product_description": "Bolo de Flores (Opção 21)",
        "product_details": "Bolo de Flores (Opção 21)",
        "product_unique_name": "bolo-de-flores-opcao-21",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_21).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c47918b0-b952-425a-ab59-023a5ca6105e",
                "path": "product\/Bolo_de_Flores_(Opção_21).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "6451a5df-2059-4b44-bb9f-80bfb30888ea",
        "product_description": "Bolo de Flores (Opção 22)",
        "product_details": "Bolo de Flores (Opção 22)",
        "product_unique_name": "bolo-de-flores-opcao-22",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_22)1.jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "21d00d79-0e2c-47a7-ae09-aac2c964ceff",
                "path": "product\/Bolo_de_Flores_(Opção_22)1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "27e0108f-a9a6-47a6-86c5-d2c3ef8b0371",
        "product_description": "Bolo de Flores (Opção 23)",
        "product_details": "Bolo de Flores (Opção 23)",
        "product_unique_name": "bolo-de-flores-opcao-23",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_23).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b79a135f-d196-4dd7-ae83-837556c3567b",
                "path": "product\/Bolo_de_Flores_(Opção_23).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "18366252-1538-42e0-8ffc-cfc804d3b7ac",
        "product_description": "Bolo de Flores (Opção 24)",
        "product_details": "Bolo de Flores (Opção 24)",
        "product_unique_name": "bolo-de-flores-opcao-24",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_24).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d642ec04-005c-452f-a76f-a710262a4b72",
                "path": "product\/Bolo_de_Flores_(Opção_24).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "c1ca4e21-6717-40e4-a4a1-913f141da9e3",
        "product_description": "Bolo de Flores (Opção 25)",
        "product_details": "Bolo de Flores (Opção 25)",
        "product_unique_name": "bolo-de-flores-opcao-25",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_25).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "40a7d795-b6f5-48a7-8b6c-81ea2d0c1a0b",
                "path": "product\/Bolo_de_Flores_(Opção_25).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "a0ebd6a1-b5ec-456a-a33f-720866e049f3",
        "product_description": "Bolo de Flores (Opção 26)",
        "product_details": "Bolo de Flores (Opção 26)",
        "product_unique_name": "bolo-de-flores-opcao-26",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_26).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d30ea639-7b9a-4784-bd46-4bb0cd5812d2",
                "path": "product\/Bolo_de_Flores_(Opção_26).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "ce20a129-cfa9-492c-8ebf-67f002ca8797",
        "product_description": "Bolo de Flores (Opção 27)",
        "product_details": "Bolo de Flores (Opção 27)",
        "product_unique_name": "bolo-de-flores-opcao-27",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_27).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3b0a4bb7-65e0-47f0-aba6-e33f5aa4583a",
                "path": "product\/Bolo_de_Flores_(Opção_27).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "6cff0af6-91d9-461e-a29a-ddf2d358c474",
        "product_description": "Bolo de Flores (Opção 28)",
        "product_details": "Bolo de Flores (Opção 28)",
        "product_unique_name": "bolo-de-flores-opcao-28",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_28).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2b0125db-e56d-44cb-be88-aab80b445229",
                "path": "product\/Bolo_de_Flores_(Opção_28).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "f5db3966-0fba-4ea3-822d-13f29e00d956",
        "product_description": "Bolo de Flores (Opção 29)",
        "product_details": "Bolo de Flores (Opção 29)",
        "product_unique_name": "bolo-de-flores-opcao-29",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_29).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e2e40c1f-9347-4789-b5e7-1848638a55ed",
                "path": "product\/Bolo_de_Flores_(Opção_29).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "75cbdffb-aa14-4219-af52-71f3f19d8106",
        "product_description": "Bolo de Flores (Opção 30)",
        "product_details": "Bolo de Flores (Opção 30)",
        "product_unique_name": "bolo-de-flores-opcao-30",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_30).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3263664d-af65-436c-90e1-448f51eff13c",
                "path": "product\/Bolo_de_Flores_(Opção_30).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "9ef32aba-3aa5-4d97-84c1-5039f3fc1e1b",
        "product_description": "Bolo de Flores (Opção 31)",
        "product_details": "Bolo de Flores (Opção 31)",
        "product_unique_name": "bolo-de-flores-opcao-31",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_31).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f40a30ca-22a4-4b8a-ab0b-5683548a6862",
                "path": "product\/Bolo_de_Flores_(Opção_31).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "0aca7e0e-ee87-465d-aa31-eb70e1625562",
        "product_description": "Bolo de Flores (Opção 32)",
        "product_details": "Bolo de Flores (Opção 32)",
        "product_unique_name": "bolo-de-flores-opcao-32",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_32).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "835c4779-d321-4200-a006-2b815e09ef56",
                "path": "product\/Bolo_de_Flores_(Opção_32).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "afa21934-1b16-4b20-a08a-4792849d7543",
        "product_description": "Bolo de Flores (Opção 33)",
        "product_details": "Bolo de Flores (Opção 33)",
        "product_unique_name": "bolo-de-flores-opcao-33",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_33).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "db2c94ca-459b-4316-9ab2-90996613815b",
                "path": "product\/Bolo_de_Flores_(Opção_33).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "bf21ef0f-0632-429c-b47b-628d98f4eb43",
        "product_description": "Bolo de Flores (Opção 34)",
        "product_details": "Bolo de Flores (Opção 34)",
        "product_unique_name": "bolo-de-flores-opcao-34",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_34).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "1d076b9b-f17a-466b-acbe-004258fbd540",
                "path": "product\/Bolo_de_Flores_(Opção_34).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "b52180c1-4586-4d57-8a7b-9259c6799d17",
        "product_description": "Bolo de Flores (Opção 35)",
        "product_details": "Bolo de Flores (Opção 35)",
        "product_unique_name": "bolo-de-flores-opcao-35",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_35).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7d580780-1f16-4f70-b423-d2fec1292567",
                "path": "product\/Bolo_de_Flores_(Opção_35).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "3fba69b8-4cb0-4107-b3c0-5c2df03c093c",
        "product_description": "Bolo de Flores (Opção 36)",
        "product_details": "Bolo de Flores (Opção 36)",
        "product_unique_name": "bolo-de-flores-opcao-36",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_36).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "92fb4bd0-0450-42b3-967a-d5acdc70845b",
                "path": "product\/Bolo_de_Flores_(Opção_36).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "76b99621-1d2c-46db-af69-058266e00509",
        "product_description": "Bolo de Flores (Opção 37)",
        "product_details": "Bolo de Flores (Opção 37)",
        "product_unique_name": "bolo-de-flores-opcao-37",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_37).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8946ff1d-be59-46c3-baee-4d1d544f8ffb",
                "path": "product\/Bolo_de_Flores_(Opção_37).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "7c31cb8a-5573-40d4-91b3-a3cac5891fac",
        "product_description": "Bolo de Flores (Opção 38)",
        "product_details": "Bolo de Flores (Opção 38)",
        "product_unique_name": "bolo-de-flores-opcao-38",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_38).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e25a6bbc-ae0f-4618-b8ec-0cce1c1909be",
                "path": "product\/Bolo_de_Flores_(Opção_38).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "3e1db2a0-1962-4d09-a8d1-4a8848af2f2c",
        "product_description": "Bolo de Flores (Opção 39)",
        "product_details": "Bolo de Flores (Opção 39)",
        "product_unique_name": "bolo-de-flores-opcao-39",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_39).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5bee8b89-4980-46c5-b205-41976339d1d9",
                "path": "product\/Bolo_de_Flores_(Opção_39).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "0633a6ed-cc1f-42c9-b29e-6adb37e17515",
        "product_description": "Bolo de Flores (Opção 40)",
        "product_details": "Bolo de Flores (Opção 40)",
        "product_unique_name": "bolo-de-flores-opcao-40",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_40).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7159559f-77b3-4a99-bff0-b14476f65c64",
                "path": "product\/Bolo_de_Flores_(Opção_40).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "b47a556b-6f31-4e25-9116-b8ac5340c6af",
        "product_description": "Bolo de Flores (Opção 41)",
        "product_details": "Bolo de Flores (Opção 41)",
        "product_unique_name": "bolo-de-flores-opcao-41",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_41).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f9444df3-ff24-4fdf-9b6e-0055488dce07",
                "path": "product\/Bolo_de_Flores_(Opção_41).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "889bb5d8-d410-4053-ba97-b615ad49b855",
        "product_description": "Bolo de Flores (Opção 42)",
        "product_details": "Bolo de Flores (Opção 42)",
        "product_unique_name": "bolo-de-flores-opcao-42",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_42).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ca555ff1-ac82-4d8b-a631-04ee4a7e01a3",
                "path": "product\/Bolo_de_Flores_(Opção_42).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "9545aa4f-c6f2-4e03-be8f-63c4f150da75",
        "product_description": "Bolo de Flores (Opção 43)",
        "product_details": "Bolo de Flores (Opção 43)",
        "product_unique_name": "bolo-de-flores-opcao-43",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_43).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "472e5739-c2e6-4bb2-8240-da06abb338a5",
                "path": "product\/Bolo_de_Flores_(Opção_43).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "ed02ee51-be3a-4664-8311-8bf68ccf64b9",
        "product_description": "Bolo de Flores (Opção 44)",
        "product_details": "Bolo de Flores (Opção 44)",
        "product_unique_name": "bolo-de-flores-opcao-44",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_44).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "aa164759-6ab3-4c8b-8242-09fbbf111e0e",
                "path": "product\/Bolo_de_Flores_(Opção_44).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "62251c0b-7259-4573-805f-81fd0681f392",
        "product_description": "Bolo de Flores (Opção 45)",
        "product_details": "Bolo de Flores (Opção 45)",
        "product_unique_name": "bolo-de-flores-opcao-45",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_45).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "eb2b6df8-e862-42e5-9ffb-e38a28b65291",
                "path": "product\/Bolo_de_Flores_(Opção_45).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "13056021-297d-4672-bef7-4ee26d803e87",
        "product_description": "Bolo de Flores (Opção 46)",
        "product_details": "Bolo de Flores (Opção 46)",
        "product_unique_name": "bolo-de-flores-opcao-46",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_46).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "fa6f0b32-f0fb-4891-aaaf-6b8fe28cbca8",
                "path": "product\/Bolo_de_Flores_(Opção_46).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "378567e7-acf4-46b5-a7f9-ca8cb40bef62",
        "product_description": "Bolo de Flores (Opção 47)",
        "product_details": "Bolo de Flores (Opção 47)",
        "product_unique_name": "bolo-de-flores-opcao-47",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_47).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "09abaa66-e6c5-4346-8898-cb860d52a45b",
                "path": "product\/Bolo_de_Flores_(Opção_47).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "1de6bce6-83d0-4dc9-a06e-45be92ef0b56",
        "product_description": "Bolo de Flores (Opção 48)",
        "product_details": "Bolo de Flores (Opção 48)",
        "product_unique_name": "bolo-de-flores-opcao-48",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_48).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cb4041bd-f345-4a88-9886-324c46bb06f6",
                "path": "product\/Bolo_de_Flores_(Opção_48).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:43.000000Z",
                "updated_at": "2023-01-26T14:23:43.000000Z"
            }
        ]
    },
    {
        "id": "8812d8a1-bae8-4546-97b1-5b2efb875262",
        "product_description": "Bolo de Flores (Opção 49)",
        "product_details": "Bolo de Flores (Opção 49)",
        "product_unique_name": "bolo-de-flores-opcao-49",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:43.000000Z",
        "updated_at": "2023-01-26T14:23:43.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_49).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ab30db39-8708-476c-962d-d7b3ad0c0030",
                "path": "product\/Bolo_de_Flores_(Opção_49).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "454953a5-15ca-446b-a7ea-e2b7b3cba7e6",
        "product_description": "Bolo de Flores (Opção 50)",
        "product_details": "Bolo de Flores (Opção 50)",
        "product_unique_name": "bolo-de-flores-opcao-50",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_50).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bcb9ac22-7c3c-4d39-884d-3310b49f13f2",
                "path": "product\/Bolo_de_Flores_(Opção_50).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "59d3acb3-d07b-4915-97a2-6e18859eb13a",
        "product_description": "Bolo de Flores (Opção 51)",
        "product_details": "Bolo de Flores (Opção 51)",
        "product_unique_name": "bolo-de-flores-opcao-51",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_51).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c7773074-afd4-4994-901f-145ef18e69eb",
                "path": "product\/Bolo_de_Flores_(Opção_51).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "73c4c6a3-879e-4490-9d49-87874d8dd322",
        "product_description": "Bolo de Flores (Opção 52)",
        "product_details": "Bolo de Flores (Opção 52)",
        "product_unique_name": "bolo-de-flores-opcao-52",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_52).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "517455bd-1fe6-48a8-89c7-c8a12510f4cb",
                "path": "product\/Bolo_de_Flores_(Opção_52).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "850a7a45-5a56-4cd8-9519-615a9a6b7691",
        "product_description": "Bolo de Flores (Opção 53)",
        "product_details": "Bolo de Flores (Opção 53)",
        "product_unique_name": "bolo-de-flores-opcao-53",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_53).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "351fed30-5891-43ed-ae6d-8f9f81bcac65",
                "path": "product\/Bolo_de_Flores_(Opção_53).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "9839fb89-6699-44a5-83c4-23c69e977827",
        "product_description": "Bolo de Flores (Opção 54)",
        "product_details": "Bolo de Flores (Opção 54)",
        "product_unique_name": "bolo-de-flores-opcao-54",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_54).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "29f00932-b257-42b5-a2e4-f95a3571108f",
                "path": "product\/Bolo_de_Flores_(Opção_54).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "331f743d-715b-4b94-87eb-65a12b112546",
        "product_description": "Bolo de Flores (Opção 55)",
        "product_details": "Bolo de Flores (Opção 55)",
        "product_unique_name": "bolo-de-flores-opcao-55",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_55).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9767f5e0-721e-4ca8-81db-42b75d7100b8",
                "path": "product\/Bolo_de_Flores_(Opção_55).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "ada97e9b-525e-4226-b9e4-2cbdfba96d99",
        "product_description": "Bolo de Flores (Opção 56)",
        "product_details": "Bolo de Flores (Opção 56)",
        "product_unique_name": "bolo-de-flores-opcao-56",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_56).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cc93fa3c-7f6a-48e8-8b08-81538a1351cf",
                "path": "product\/Bolo_de_Flores_(Opção_56).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "adcb2b65-d769-4522-9cce-ee0240be9a42",
        "product_description": "Bolo de Flores (Opção 57)",
        "product_details": "Bolo de Flores (Opção 57)",
        "product_unique_name": "bolo-de-flores-opcao-57",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_57).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "466607e0-d872-4715-9efb-2815f331ca7f",
                "path": "product\/Bolo_de_Flores_(Opção_57).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "d2240cb2-5005-41a3-a187-394e3d86237c",
        "product_description": "Bolo de Flores (Opção 58)",
        "product_details": "Bolo de Flores (Opção 58)",
        "product_unique_name": "bolo-de-flores-opcao-58",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_58).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f2a86599-9aae-41f6-8932-b58be88df010",
                "path": "product\/Bolo_de_Flores_(Opção_58).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "49d28fa2-0547-4eeb-9dfd-72c4fb140539",
        "product_description": "Bolo de Flores (Opção 59)",
        "product_details": "Bolo de Flores (Opção 59)",
        "product_unique_name": "bolo-de-flores-opcao-59",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_59).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7125e2d7-77a8-406b-b651-526825fdc281",
                "path": "product\/Bolo_de_Flores_(Opção_59).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "807b72cb-f869-4200-ba60-66bfca43876a",
        "product_description": "Bolo de Flores (Opção 60)",
        "product_details": "Bolo de Flores (Opção 60)",
        "product_unique_name": "bolo-de-flores-opcao-60",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_60).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2f48a10d-3371-4e40-8f9a-8d01882a5b4d",
                "path": "product\/Bolo_de_Flores_(Opção_60).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "e27dc56d-b289-4c70-a669-8685072bdcd2",
        "product_description": "Bolo de Flores (Opção 61)",
        "product_details": "Bolo de Flores (Opção 61)",
        "product_unique_name": "bolo-de-flores-opcao-61",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_61).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "50fd8f11-ae62-4d7f-9afc-46bec4bfbb1c",
                "path": "product\/Bolo_de_Flores_(Opção_61).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "cd060159-f419-46d2-acb1-a633ef5d64a5",
        "product_description": "Bolo de Flores (Opção 62)",
        "product_details": "Bolo de Flores (Opção 62)",
        "product_unique_name": "bolo-de-flores-opcao-62",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_62).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7ec55d84-a505-4898-bd18-a41f436ef312",
                "path": "product\/Bolo_de_Flores_(Opção_62).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "f925e289-718b-4c8d-b1b0-f6cf82d6060c",
        "product_description": "Bolo de Flores (Opção 63)",
        "product_details": "Bolo de Flores (Opção 63)",
        "product_unique_name": "bolo-de-flores-opcao-63",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_63).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8ba2711a-0ba3-40e8-ba1a-7394d30a1363",
                "path": "product\/Bolo_de_Flores_(Opção_63).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "09327a64-f7d7-4e01-8e33-19ea4885882a",
        "product_description": "Bolo de Flores (Opção 64)",
        "product_details": "Bolo de Flores (Opção 64)",
        "product_unique_name": "bolo-de-flores-opcao-64",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_64).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0f3cf77b-0539-42b7-809f-8bfb9d0ca0e9",
                "path": "product\/Bolo_de_Flores_(Opção_64).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "69571e99-1050-4e80-bdf4-cc948227c77b",
        "product_description": "Bolo de Flores (Opção 65)",
        "product_details": "Bolo de Flores (Opção 65)",
        "product_unique_name": "bolo-de-flores-opcao-65",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_65).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "14a787fe-b35a-4168-9fde-c35d239557a2",
                "path": "product\/Bolo_de_Flores_(Opção_65).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "365d58dc-5979-479b-bf51-35819b3dd33f",
        "product_description": "Bolo de Flores (Opção 66)",
        "product_details": "Bolo de Flores (Opção 66)",
        "product_unique_name": "bolo-de-flores-opcao-66",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_66).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a091854b-bd92-4e7b-8259-4349be07603c",
                "path": "product\/Bolo_de_Flores_(Opção_66).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "0d9259ad-128d-4e18-92c2-5f5e7698105f",
        "product_description": "Bolo de Flores (Opção 67)",
        "product_details": "Bolo de Flores (Opção 67)",
        "product_unique_name": "bolo-de-flores-opcao-67",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_67).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "65adad96-5704-4805-b51f-8b26bd974d21",
                "path": "product\/Bolo_de_Flores_(Opção_67).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "9257e25d-f2db-47a3-b42e-831467349e33",
        "product_description": "Bolo de Flores (Opção 68)",
        "product_details": "Bolo de Flores (Opção 68)",
        "product_unique_name": "bolo-de-flores-opcao-68",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_68).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4b1d86a3-6bea-4ed5-bf64-e859c034a4a2",
                "path": "product\/Bolo_de_Flores_(Opção_68).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "ae324917-5cba-4180-9e3e-66e90eb762ae",
        "product_description": "Bolo de Flores (Opção 69)",
        "product_details": "Bolo de Flores (Opção 69)",
        "product_unique_name": "bolo-de-flores-opcao-69",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_69).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6f0d6c70-a187-40e4-a3b1-4c6e560db79b",
                "path": "product\/Bolo_de_Flores_(Opção_69).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "3b4c7a21-31d8-445f-af04-5c8b9f299d43",
        "product_description": "Bolo de Flores (Opção 70)",
        "product_details": "Bolo de Flores (Opção 70)",
        "product_unique_name": "bolo-de-flores-opcao-70",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_70).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5892f2ec-296f-49a7-ac6a-6a7f5a80a602",
                "path": "product\/Bolo_de_Flores_(Opção_70).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "3ef94104-4cc0-43b8-9816-e01b0e853742",
        "product_description": "Bolo de Flores (Opção 71)",
        "product_details": "Bolo de Flores (Opção 71)",
        "product_unique_name": "bolo-de-flores-opcao-71",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_71).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "2965568f-5086-4fea-8e39-e20598322255",
                "path": "product\/Bolo_de_Flores_(Opção_71).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "adf22d4f-e9a4-468b-bcaf-f0c34d60b6cb",
        "product_description": "Bolo de Flores (Opção 72)",
        "product_details": "Bolo de Flores (Opção 72)",
        "product_unique_name": "bolo-de-flores-opcao-72",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_72).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cc6a4182-c2c9-43a7-9f81-b2bbe8389d65",
                "path": "product\/Bolo_de_Flores_(Opção_72).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "2e633192-d5cb-4dda-b518-d806fad611ad",
        "product_description": "Bolo de Flores (Opção 73)",
        "product_details": "Bolo de Flores (Opção 73)",
        "product_unique_name": "bolo-de-flores-opcao-73",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_73).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3aebb029-2d3d-450b-8cda-021bf5659e02",
                "path": "product\/Bolo_de_Flores_(Opção_73).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "e3319960-9261-492c-b186-f5996d30c04b",
        "product_description": "Bolo de Flores (Opção 74)",
        "product_details": "Bolo de Flores (Opção 74)",
        "product_unique_name": "bolo-de-flores-opcao-74",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_74).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0c827d8d-4268-438b-84a0-823acb8171d6",
                "path": "product\/Bolo_de_Flores_(Opção_74).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "8b44f38c-127d-4ee7-98a7-916fc7d0922a",
        "product_description": "Bolo de Flores (Opção 75)",
        "product_details": "Bolo de Flores (Opção 75)",
        "product_unique_name": "bolo-de-flores-opcao-75",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_75).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6c9c9f9e-7602-4392-acce-612a0ad722cd",
                "path": "product\/Bolo_de_Flores_(Opção_75).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "612ed539-2ae2-4ac5-9b76-5c767e7369cd",
        "product_description": "Bolo de Flores (Opção 76)",
        "product_details": "Bolo de Flores (Opção 76)",
        "product_unique_name": "bolo-de-flores-opcao-76",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_76).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c890cbaa-8e4e-4dd7-bed9-e34d92014528",
                "path": "product\/Bolo_de_Flores_(Opção_76).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "12b21967-bcff-455a-8e29-ce22159567a1",
        "product_description": "Bolo de Flores (Opção 77)",
        "product_details": "Bolo de Flores (Opção 77)",
        "product_unique_name": "bolo-de-flores-opcao-77",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_77).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f66ced6f-58c4-4dfd-885c-5f34831576c3",
                "path": "product\/Bolo_de_Flores_(Opção_77).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "62fa1116-f1ea-4eab-af36-b10129a27c8e",
        "product_description": "Bolo de Flores (Opção 78)",
        "product_details": "Bolo de Flores (Opção 78)",
        "product_unique_name": "bolo-de-flores-opcao-78",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_78).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "dd0dadd2-df61-4794-af0c-014a2a501018",
                "path": "product\/Bolo_de_Flores_(Opção_78).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "aaed1ae6-9d2b-41f2-ad32-6addb65dd798",
        "product_description": "Bolo de Flores (Opção 79)",
        "product_details": "Bolo de Flores (Opção 79)",
        "product_unique_name": "bolo-de-flores-opcao-79",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_79).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e9ebcaff-ebd4-440d-9547-ecfcc6507cea",
                "path": "product\/Bolo_de_Flores_(Opção_79).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "be46dc09-8d04-4360-9264-bd306106e008",
        "product_description": "Bolo de Flores (Opção 80)",
        "product_details": "Bolo de Flores (Opção 80)",
        "product_unique_name": "bolo-de-flores-opcao-80",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_80).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0688520e-8444-474d-b622-3f9805136a2f",
                "path": "product\/Bolo_de_Flores_(Opção_80).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "48d1500c-964b-498c-b4f7-ad8f9814cdbb",
        "product_description": "Bolo de Flores (Opção 81)",
        "product_details": "Bolo de Flores (Opção 81)",
        "product_unique_name": "bolo-de-flores-opcao-81",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_81).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5683fb75-6b4d-4acc-88a3-4720f2924fb9",
                "path": "product\/Bolo_de_Flores_(Opção_81).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "ac443dc3-d440-4a27-8c73-597ad7887d0f",
        "product_description": "Bolo de Flores (Opção 82)",
        "product_details": "Bolo de Flores (Opção 82)",
        "product_unique_name": "bolo-de-flores-opcao-82",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_82).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "9fb0f65c-7be9-452f-adce-6b2987cb5bff",
                "path": "product\/Bolo_de_Flores_(Opção_82).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "6cef18bd-7d2f-4a53-a376-2b0cd2ea6f7f",
        "product_description": "Bolo de Flores (Opção 84)",
        "product_details": "Bolo de Flores (Opção 84)",
        "product_unique_name": "bolo-de-flores-opcao-84",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_84)1.jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0dd2b7d3-5c49-425c-838c-b7e9002ae3f6",
                "path": "product\/Bolo_de_Flores_(Opção_84)1.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "900cfebb-6e68-47c5-b981-1f920fecc8ef",
        "product_description": "Bolo de Flores (Opção 85)",
        "product_details": "Bolo de Flores (Opção 85)",
        "product_unique_name": "bolo-de-flores-opcao-85",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_85).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "66922458-6d5d-4912-93ef-9dc2d13f3530",
                "path": "product\/Bolo_de_Flores_(Opção_85).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "f9b919b7-5331-4c79-b387-afca5ee6ea88",
        "product_description": "Bolo de Flores (Opção 86)",
        "product_details": "Bolo de Flores (Opção 86)",
        "product_unique_name": "bolo-de-flores-opcao-86",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_86).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3fda6494-6ebb-40af-b6c3-2f19bfab38f6",
                "path": "product\/Bolo_de_Flores_(Opção_86).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "0732c26a-6237-4361-b60a-b9ee51370429",
        "product_description": "Bolo de Flores (Opção 88)",
        "product_details": "Bolo de Flores (Opção 88)",
        "product_unique_name": "bolo-de-flores-opcao-88",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_88).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "5f4f18a0-59f5-44ae-80ef-ed25e0b4ffca",
                "path": "product\/Bolo_de_Flores_(Opção_88).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "361e14eb-256d-4424-b9c0-d87ea219e5fb",
        "product_description": "Bolo de Flores (Opção 89)",
        "product_details": "Bolo de Flores (Opção 89)",
        "product_unique_name": "bolo-de-flores-opcao-89",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_89).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "b8152d1e-cc4d-4493-845a-fa4fbedea5d2",
                "path": "product\/Bolo_de_Flores_(Opção_89).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "52799f42-879c-4e2a-849e-e9d4c65fbe81",
        "product_description": "Bolo de Flores (Opção 90)",
        "product_details": "Bolo de Flores (Opção 90)",
        "product_unique_name": "bolo-de-flores-opcao-90",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_90).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c8e91577-ffa0-4875-8d14-1ac4acaddc6a",
                "path": "product\/Bolo_de_Flores_(Opção_90).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "afa6f10c-587c-41b6-982b-2ae76651adf1",
        "product_description": "Bolo de Flores (Opção 91)",
        "product_details": "Bolo de Flores (Opção 91)",
        "product_unique_name": "bolo-de-flores-opcao-91",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_91).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d3014c45-0c5d-4d14-b94f-36fc57078274",
                "path": "product\/Bolo_de_Flores_(Opção_91).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "09c38064-b53a-4bb7-99f6-44d4c783c965",
        "product_description": "Bolo de Flores (Opção 92)",
        "product_details": "Bolo de Flores (Opção 92)",
        "product_unique_name": "bolo-de-flores-opcao-92",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_92).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ece6f773-4cbc-4f15-921c-00eaeb004d4b",
                "path": "product\/Bolo_de_Flores_(Opção_92).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "59fa995f-9547-4bd4-82ca-537e8731bd08",
        "product_description": "Bolo de Flores (Opção 95)",
        "product_details": "Bolo de Flores (Opção 95)",
        "product_unique_name": "bolo-de-flores-opcao-95",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_95).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c5759ae2-ed66-4370-a583-bcdcad649d1d",
                "path": "product\/Bolo_de_Flores_(Opção_95).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "bce45d31-7a9e-44f0-9311-a8c2bc2f4041",
        "product_description": "Bolo de Flores (Opção 96)",
        "product_details": "Bolo de Flores (Opção 96)",
        "product_unique_name": "bolo-de-flores-opcao-96",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_96).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "06f4e4ef-3967-4e23-aa27-30bf84a65b43",
                "path": "product\/Bolo_de_Flores_(Opção_96).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "1f121bbf-7505-412a-96fb-789d9cc72c16",
        "product_description": "Bolo de Flores (Opção 98)",
        "product_details": "Bolo de Flores (Opção 98)",
        "product_unique_name": "bolo-de-flores-opcao-98",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_98).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "87efbe92-1947-4456-acbe-4a600c242719",
                "path": "product\/Bolo_de_Flores_(Opção_98).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "dcdd901d-7daf-4871-a70d-e7f9905e546d",
        "product_description": "Bolo De Flores (Opção 100)",
        "product_details": "Bolo De Flores (Opção 100)",
        "product_unique_name": "bolo-de-flores-opcao-100",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_100).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7128d9e5-be13-4476-9b23-03cfbf30db59",
                "path": "product\/Bolo_de_Flores_(Opção_100).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "95714184-131e-42e4-a7c5-7c5159f6eb44",
        "product_description": "Bolo de Flores (Opção 101)",
        "product_details": "Bolo de Flores (Opção 101)",
        "product_unique_name": "bolo-de-flores-opcao-101",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_101).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "6db59e4e-2efa-4ac9-97cf-2932287fc700",
                "path": "product\/Bolo_de_Flores_(Opção_101).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "d4aaeca2-9a1b-40c8-8097-1bbf320e6287",
        "product_description": "Bolo de Flores (Opção 102)",
        "product_details": "Bolo de Flores (Opção 102)",
        "product_unique_name": "bolo-de-flores-opcao-102",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_de_Flores_(Opção_102).jpg",
        "category": [
            {
                "id": "0a717b29-991b-4947-8eab-b003cca102bc",
                "theme_id": "196854a2-735b-4a17-8eb4-7583ef57f130",
                "category_description": "Bolos de Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3b346351-3bfc-42eb-bffb-5cb8fb0f569e",
                "path": "product\/Bolo_de_Flores_(Opção_102).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "d0c7137a-2112-4e3e-a5a9-6f5959ab2079",
        "product_description": "Bolo Decorado A Bela e a Fera (Opção 1)",
        "product_details": "Bolo Decorado A Bela e a Fera (Opção 1)",
        "product_unique_name": "bolo-decorado-a-bela-e-a-fera-opcao-1",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_A_Bela_e_a_Fera_(Opção_1).jpg",
        "category": [
            {
                "id": "864991ca-469e-4d02-9752-f9757b3c1490",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "A Bela e a Fera",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "8de7f140-4b7f-496f-9249-59d0b72f1cd2",
                "path": "product\/Bolo_Decorado_A_Bela_e_a_Fera_(Opção_1).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "a2c33539-e122-42ca-b73a-d5366cb12caf",
        "product_description": "Bolo Decorado A Bela e a Fera (Opção 2)",
        "product_details": "Bolo Decorado A Bela e a Fera (Opção 2)",
        "product_unique_name": "bolo-decorado-a-bela-e-a-fera-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_A_Bela_e_a_Fera_(Opção_2).jpg",
        "category": [
            {
                "id": "864991ca-469e-4d02-9752-f9757b3c1490",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "A Bela e a Fera",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "16c6b218-665a-4ee4-badc-a794ae614b3c",
                "path": "product\/Bolo_Decorado_A_Bela_e_a_Fera_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "7e6cd0ee-883d-4f33-a6ef-48194291dfd9",
        "product_description": "Cupcakes Decorados A Bela e a Fera",
        "product_details": "Cupcakes Decorados A Bela e a Fera",
        "product_unique_name": "cupcakes-decorados-a-bela-e-a-fera",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_A_Bela_e_a_Fera.jpg",
        "category": [
            {
                "id": "864991ca-469e-4d02-9752-f9757b3c1490",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "A Bela e a Fera",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cd7c7378-033d-4f78-85a4-e34df52440a6",
                "path": "product\/Cupcakes_Decorados_A_Bela_e_a_Fera.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "dd897607-1863-425f-93b4-c4dbd9d69485",
        "product_description": "Doces Decorados A Bela e a Fera ",
        "product_details": "Doces Decorados A Bela e a Fera ",
        "product_unique_name": "doces-decorados-a-bela-e-a-fera",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_A_Bela_e_a_Fera_(Opção_1).jpg",
        "category": [
            {
                "id": "864991ca-469e-4d02-9752-f9757b3c1490",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "A Bela e a Fera",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "88c6aaf0-e089-47fb-a6e0-8633d75d8231",
                "path": "product\/Doces_Decorados_A_Bela_e_a_Fera_(Opção_1).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "8128435a-37cd-4a49-8f3d-daf440fcc36d",
        "product_description": "Fudge no Palito Decorado A Bela e a Fera",
        "product_details": "Fudge no Palito Decorado A Bela e a Fera",
        "product_unique_name": "fudge-no-palito-decorado-a-bela-e-a-fera",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Fudge_no_Palito_Decorado_A_Bela_e_a_Fera.jpg",
        "category": [
            {
                "id": "864991ca-469e-4d02-9752-f9757b3c1490",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "A Bela e a Fera",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "532e4bdd-5f5b-4cab-b6c1-cfe47b525b5a",
                "path": "product\/Fudge_no_Palito_Decorado_A_Bela_e_a_Fera.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "838c7e6c-b173-4a83-803a-13bbe20bb4cf",
        "product_description": "Maçãs Decoradas A Bela e a Fera",
        "product_details": "Maçãs Decoradas A Bela e a Fera",
        "product_unique_name": "macas-decoradas-a-bela-e-a-fera",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Maçãs_Decoradas_A_Bela_e_a_Fera.jpg",
        "category": [
            {
                "id": "864991ca-469e-4d02-9752-f9757b3c1490",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "A Bela e a Fera",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0bff63b5-200c-4b59-88c7-e1fc460dea75",
                "path": "product\/Maçãs_Decoradas_A_Bela_e_a_Fera.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "a9f4758e-c16f-4c6c-a787-0038991af6ec",
        "product_description": "Bolo Decorado Balões ",
        "product_details": "Bolo Decorado Balões ",
        "product_unique_name": "bolo-decorado-baloes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Balões.jpg",
        "category": [
            {
                "id": "eedaf6db-ab07-4aa5-a4b2-00a191ea05ac",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Balões",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d12f3451-5f99-4990-81f8-03081807760b",
                "path": "product\/Bolo_Decorado_Balões.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "75065e7e-ad26-4fdd-937f-2e45b127b459",
        "product_description": "Cupcakes Decorados Balões",
        "product_details": "Cupcakes Decorados Balões",
        "product_unique_name": "cupcakes-decorados-baloes",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:44.000000Z",
        "updated_at": "2023-01-26T14:23:44.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Balões.jpg",
        "category": [
            {
                "id": "eedaf6db-ab07-4aa5-a4b2-00a191ea05ac",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Balões",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7cd15e6c-0526-4fe7-85de-142182596b53",
                "path": "product\/Cupcakes_Decorados_Balões.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:44.000000Z",
                "updated_at": "2023-01-26T14:23:44.000000Z"
            }
        ]
    },
    {
        "id": "8e46312e-c5d5-4f0b-a335-c3df14b18c9c",
        "product_description": "Bolo Decorado Tom e Jerry ",
        "product_details": "Bolo Decorado Tom e Jerry ",
        "product_unique_name": "bolo-decorado-tom-e-jerry",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Tom_e_Jerry.jpg",
        "category": [
            {
                "id": "aca2aa58-44ca-4242-bc1c-d157ce080f06",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Tom e Jerry",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "49c8333b-b0ac-4272-a1fd-006adeaff6a1",
                "path": "product\/Bolo_Decorado_Tom_e_Jerry.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "16d289aa-7496-4ce9-8022-ec7c537cc926",
        "product_description": "Fudges Decorados Tom e Jerry ",
        "product_details": "Fudges Decorados Tom e Jerry ",
        "product_unique_name": "fudges-decorados-tom-e-jerry",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Fudges_Decorados_Tom_e_Jerry.jpg",
        "category": [
            {
                "id": "aca2aa58-44ca-4242-bc1c-d157ce080f06",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Tom e Jerry",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "3d9da96f-4ade-41ed-b1fb-ad11c38bdf63",
                "path": "product\/Fudges_Decorados_Tom_e_Jerry.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "7b8b294d-e251-4b1a-ae7b-c4918a28b748",
        "product_description": "Doces Decorados Tom e Jerry",
        "product_details": "Doces Decorados Tom e Jerry",
        "product_unique_name": "doces-decorados-tom-e-jerry",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Tom_e_Jerry.jpg",
        "category": [
            {
                "id": "aca2aa58-44ca-4242-bc1c-d157ce080f06",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Tom e Jerry",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "09ea77e6-692f-4481-b1eb-639533e0a7f0",
                "path": "product\/Doces_Decorados_Tom_e_Jerry.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "bbb17717-072c-4a31-af23-3cf8f13d8c71",
        "product_description": "Bolo Decorado Flor de Cerejeira",
        "product_details": "Bolo Decorado Flor de Cerejeira",
        "product_unique_name": "bolo-decorado-flor-de-cerejeira",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Flor_de_Cerejeira.jpg",
        "category": [
            {
                "id": "71729ebe-6c5c-434f-8b7c-fff5454f13b1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "bda1fbc1-655b-4d39-b448-f68a27f60f37",
                "path": "product\/Bolo_Decorado_Flor_de_Cerejeira.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "ff13a3f8-5ac5-435a-a64a-5cfa63632db5",
        "product_description": "Cupcakes Decorados Flor de Cerejeira",
        "product_details": "Cupcakes Decorados Flor de Cerejeira",
        "product_unique_name": "cupcakes-decorados-flor-de-cerejeira",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Flor_de_Cerejeira.jpg",
        "category": [
            {
                "id": "71729ebe-6c5c-434f-8b7c-fff5454f13b1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "09b5f628-8c7c-4c90-871b-a9406ed60875",
                "path": "product\/Cupcakes_Decorados_Flor_de_Cerejeira.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "26ed0686-1c42-4977-a195-049bd037a071",
        "product_description": "Doces Decorados Flor de Cerejeira",
        "product_details": "Doces Decorados Flor de Cerejeira",
        "product_unique_name": "doces-decorados-flor-de-cerejeira",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Flor_de_Cerejeira.jpg",
        "category": [
            {
                "id": "71729ebe-6c5c-434f-8b7c-fff5454f13b1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "f5ba95ac-40bf-49a3-96b9-80bd3413bbf5",
                "path": "product\/Doces_Decorados_Flor_de_Cerejeira.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "bc8c6a3f-3c56-4608-8b95-d11cb42f21c2",
        "product_description": "Cake Pops Decorados Flor de Cerejeira",
        "product_details": "Cake Pops Decorados Flor de Cerejeira",
        "product_unique_name": "cake-pops-decorados-flor-de-cerejeira",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Cake_Pops_Decorados_Flor_de_Cerejeira.jpg",
        "category": [
            {
                "id": "71729ebe-6c5c-434f-8b7c-fff5454f13b1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "e22a2af8-6dab-491a-bc02-8bceafde00e9",
                "path": "product\/Cake_Pops_Decorados_Flor_de_Cerejeira.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "41fc86f3-a09b-4cd0-8af0-9b48108ff3c3",
        "product_description": "Fudges Decorados Flor de Cerejeira ",
        "product_details": "Fudges Decorados Flor de Cerejeira ",
        "product_unique_name": "fudges-decorados-flor-de-cerejeira",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Fudges_Decorados_Flor_de_Cerejeira.jpg",
        "category": [
            {
                "id": "71729ebe-6c5c-434f-8b7c-fff5454f13b1",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Flores",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d71caf94-feb6-4be8-a9de-d5447fa6f0b5",
                "path": "product\/Fudges_Decorados_Flor_de_Cerejeira.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "9f325cef-526a-4bd4-8aee-60dfb0781bd2",
        "product_description": "Torta Tropical",
        "product_details": "Torta Tropical",
        "product_unique_name": "torta-tropical",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Torta_Tropical.jpg",
        "category": [
            {
                "id": "3dd4ca8e-6179-4ec9-ab7b-88c5e2451fd8",
                "theme_id": "2a14ff8a-985e-4840-b5c2-dfd21aae81c8",
                "category_description": "Sabores de Torta",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "a9f42b83-c854-4b9f-87fd-f07e7f5d67c3",
                "path": "product\/Torta_Tropical.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "9b533e78-7a42-4513-b87d-e907718be7f1",
        "product_description": "Bolo Decorado Ladybug",
        "product_details": "Bolo Decorado Ladybug",
        "product_unique_name": "bolo-decorado-ladybug",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Ladybug.jpg",
        "category": [
            {
                "id": "452aa180-8a5c-4f59-a0b4-04a835294337",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ladybug",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7c5ea0b8-8987-481a-b482-93471a3e59a8",
                "path": "product\/Bolo_Decorado_Ladybug.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "33d0fa5d-9b87-44a4-83aa-f7cddb273593",
        "product_description": "Cupcakes Decorados Ladybug",
        "product_details": "Cupcakes Decorados Ladybug",
        "product_unique_name": "cupcakes-decorados-ladybug",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Ladybug.jpg",
        "category": [
            {
                "id": "452aa180-8a5c-4f59-a0b4-04a835294337",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ladybug",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "4f1038b2-dc24-46e1-80f4-891381a0f54a",
                "path": "product\/Cupcakes_Decorados_Ladybug.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "7ba49c59-211a-40e0-9897-892ea508dc4a",
        "product_description": "Bolo Decorado Pokemon",
        "product_details": "Bolo Decorado Pokemon",
        "product_unique_name": "bolo-decorado-pokemon",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Pokemon.jpg",
        "category": [
            {
                "id": "a474da37-8d16-4039-ba7e-524f071d1ab0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pokemon",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "d33d674e-5f1d-46a4-b44d-08d09b958544",
                "path": "product\/Bolo_Decorado_Pokemon.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "fd1a4131-0617-4804-aaae-b64b0411a5ca",
        "product_description": "Cupcakes Decorados Pokemon",
        "product_details": "Cupcakes Decorados Pokemon",
        "product_unique_name": "cupcakes-decorados-pokemon",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Pokemon.jpg",
        "category": [
            {
                "id": "a474da37-8d16-4039-ba7e-524f071d1ab0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pokemon",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "cce704df-db36-4db1-bab7-39bb55de19e1",
                "path": "product\/Cupcakes_Decorados_Pokemon.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "f36a6371-9556-47df-ab45-64e3154567fe",
        "product_description": "Bolo Decorado Pokemon (Opção 2)",
        "product_details": "Bolo Decorado Pokemon (Opção 2)",
        "product_unique_name": "bolo-decorado-pokemon-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Pokemon_(Opção_2).jpg",
        "category": [
            {
                "id": "a474da37-8d16-4039-ba7e-524f071d1ab0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pokemon",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ee5658df-58bf-42ad-929c-c055483cec6f",
                "path": "product\/Bolo_Decorado_Pokemon_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "f235f973-0c0d-44cd-b475-38f9bd093c06",
        "product_description": "Doces Decorados Pokemon ",
        "product_details": "Doces Decorados Pokemon ",
        "product_unique_name": "doces-decorados-pokemon",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Pokemon.jpg",
        "category": [
            {
                "id": "a474da37-8d16-4039-ba7e-524f071d1ab0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pokemon",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "56e859d3-9ed1-44f2-8985-3a0eac2122ed",
                "path": "product\/Doces_Decorados_Pokemon.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "2f8390af-8918-475f-8ec2-85f9809395cc",
        "product_description": "Cupcakes Decorados Pokemon (Opção 2)",
        "product_details": "Cupcakes Decorados Pokemon (Opção 2)",
        "product_unique_name": "cupcakes-decorados-pokemon-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_Pokemon_(Opção_2).jpg",
        "category": [
            {
                "id": "a474da37-8d16-4039-ba7e-524f071d1ab0",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Pokemon",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "7bc9507e-b1cb-496b-8e7d-032c4d5b714f",
                "path": "product\/Cupcakes_Decorados_Pokemon_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "fa025b8e-918d-4f37-abc8-6350b1f7fa65",
        "product_description": "Bolo Decorado Ursinhos Carinhosos ",
        "product_details": "Bolo Decorado Ursinhos Carinhosos ",
        "product_unique_name": "bolo-decorado-ursinhos-carinhosos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Ursinhos_Carinhosos.jpg",
        "category": [
            {
                "id": "26c5743c-9340-499b-bf0b-75aaf80117c7",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos Carinhosos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "411704f8-177b-4da3-86ed-9e13b851e7bb",
                "path": "product\/Bolo_Decorado_Ursinhos_Carinhosos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "5b0746df-4ffe-4e02-b4c2-fd35746e7f07",
        "product_description": "Doces Decorados Ursinhos Carinhosos ",
        "product_details": "Doces Decorados Ursinhos Carinhosos ",
        "product_unique_name": "doces-decorados-ursinhos-carinhosos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Doces_Decorados_Ursinhos_Carinhosos.jpg",
        "category": [
            {
                "id": "26c5743c-9340-499b-bf0b-75aaf80117c7",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Ursinhos Carinhosos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "c6296f38-b44a-46d6-877d-f609be28621c",
                "path": "product\/Doces_Decorados_Ursinhos_Carinhosos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "4ae5edbf-c7d8-4776-8589-03b1e6dbcea6",
        "product_description": "Bolo Decorado One Piece ",
        "product_details": "Bolo Decorado One Piece ",
        "product_unique_name": "bolo-decorado-one-piece",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_One_Piece.jpg",
        "category": [
            {
                "id": "0429c632-a1f6-4f90-bfb6-79ed3e888553",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "One Piece",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ace2b4d1-d942-4d64-b413-12936cfcce17",
                "path": "product\/Bolo_Decorado_One_Piece.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "83ed3bd0-8a62-4ed5-bf48-58917d60c3f2",
        "product_description": "Cupcakes Decorados One Piece",
        "product_details": "Cupcakes Decorados One Piece",
        "product_unique_name": "cupcakes-decorados-one-piece",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_One_Piece.jpg",
        "category": [
            {
                "id": "0429c632-a1f6-4f90-bfb6-79ed3e888553",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "One Piece",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "0cfdb7f4-32de-47bf-b78a-65997fb39ab8",
                "path": "product\/Cupcakes_Decorados_One_Piece.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "f8c66a29-ec22-43ac-be21-103bab60bed2",
        "product_description": "Pirulitos Personalizados One Piece",
        "product_details": "Pirulitos Personalizados One Piece",
        "product_unique_name": "pirulitos-personalizados-one-piece",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_Personalizados_One_Piece.jpg",
        "category": [
            {
                "id": "0429c632-a1f6-4f90-bfb6-79ed3e888553",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "One Piece",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "49c78422-1430-4985-a4c3-cbbd2c07527e",
                "path": "product\/Pirulitos_Personalizados_One_Piece.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "7c4a4be2-889b-4877-a901-1497292e7dfb",
        "product_description": "Cupcakes Decorados 90 anos",
        "product_details": "Cupcakes Decorados 90 anos",
        "product_unique_name": "cupcakes-decorados-90-anos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Captura_de_Tela_2017-10-03_às_16_13_00.png",
        "category": [
            {
                "id": "3e118823-1540-4786-8815-3f0ac91171db",
                "theme_id": "af9e5867-6dad-4f30-8e6e-ed97145cea25",
                "category_description": "90 Anos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "ca632190-75db-4b40-8704-b8e8237f2314",
                "path": "product\/Captura_de_Tela_2017-10-03_às_16_13_00.png",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "e1365377-4885-4e15-8269-d249726f0796",
        "product_description": "Doces Decorados 90 Anos",
        "product_details": "Doces Decorados 90 Anos",
        "product_unique_name": "doces-decorados-90-anos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Captura_de_Tela_2017-10-03_às_16_13_15.png",
        "category": [
            {
                "id": "3e118823-1540-4786-8815-3f0ac91171db",
                "theme_id": "af9e5867-6dad-4f30-8e6e-ed97145cea25",
                "category_description": "90 Anos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "beca82ee-581d-48e9-a62a-5c77d11e4448",
                "path": "product\/Captura_de_Tela_2017-10-03_às_16_13_15.png",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "5b74979a-b9cb-4fe6-97d5-2617001a573d",
        "product_description": "Mini Cupcakes Decorados Branca de Neve (Opção 2)",
        "product_details": "Mini Cupcakes Decorados Branca de Neve (Opção 2)",
        "product_unique_name": "mini-cupcakes-decorados-branca-de-neve-opcao-2",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:45.000000Z",
        "updated_at": "2023-01-26T14:23:45.000000Z",
        "imagePath": "\/storage\/product\/Mini_Cupcakes_Decorados_Branca_de_Neve_(Opção_2).jpg",
        "category": [
            {
                "id": "f84dc9fb-3024-4b4b-bcd6-17606f81d484",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Branca de Neve",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [],
        "image": [
            {
                "id": "24dc8656-015d-4c19-a53e-50ae5aeaba76",
                "path": "product\/Mini_Cupcakes_Decorados_Branca_de_Neve_(Opção_2).jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:45.000000Z",
                "updated_at": "2023-01-26T14:23:45.000000Z"
            }
        ]
    },
    {
        "id": "7e065b43-2b24-482d-adc1-a90c846c9735",
        "product_description": "Bolo Decorado Esquilos de Natal",
        "product_details": "Bolo Decorado Esquilos de Natal",
        "product_unique_name": "bolo-decorado-esquilos-de-natal",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-02-02T21:43:42.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Esquilos.jpg",
        "category": [
            {
                "id": "617dc314-4f71-429e-80d1-c0422d5e7503",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Esquilos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "a3ac87b5-46f7-49b4-983f-b1809415b9b3",
                "event_description": "Xmas",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "7408bef8-9a22-4773-8985-ccb9d5c00c9c",
                "path": "product\/Bolo_Decorado_Esquilos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "4175d385-801a-47eb-8f66-639f9d022c51",
        "product_description": "Pirulito de chocolate Patrulha Canina",
        "product_details": "Pirulito de chocolate Patrulha Canina",
        "product_unique_name": "pirulito-de-chocolate-patrulha-canina",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-02-02T21:46:01.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_de_chocolate_Patrulha_Canina.jpg",
        "category": [
            {
                "id": "5923a2ff-70e1-47cc-965c-94dda9c3631e",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Patrulha Canina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "03327636-30df-41c5-98d9-4983a4e09dde",
                "event_description": "Páscoa",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "7d868af7-b491-4361-95a3-a67f6e5e4d63",
                "path": "product\/Pirulitos_de_chocolate_Patrulha_Canina.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "40ab1e63-aae4-4df8-b03b-4ff6d97b1a70",
        "product_description": "Pirulitos de Chocolate Nossa Senhora",
        "product_details": "Pirulitos de Chocolate Nossa Senhora",
        "product_unique_name": "pirulitos-de-chocolate-nossa-senhora",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:35.000000Z",
        "updated_at": "2023-02-02T21:46:16.000000Z",
        "imagePath": "\/storage\/product\/Pirulitos_de_Chocolate_Nossa_Senhora.jpg",
        "category": [
            {
                "id": "52ecf1da-9399-4074-b390-89119c1b7987",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Religiosos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "03327636-30df-41c5-98d9-4983a4e09dde",
                "event_description": "Páscoa",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "9908f8c0-b174-4376-abf1-e4d55819efc0",
                "path": "product\/Pirulitos_de_Chocolate_Nossa_Senhora.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:35.000000Z",
                "updated_at": "2023-01-26T14:23:35.000000Z"
            }
        ]
    },
    {
        "id": "f66351b6-4a27-4931-9d80-d651aff88505",
        "product_description": "Bolo Decorado Festa Junina (Arraiá)",
        "product_details": "Bolo Decorado Festa Junina (Arraiá)",
        "product_unique_name": "bolo-decorado-festa-junina-(arraia)",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-02-02T21:46:34.000000Z",
        "imagePath": "\/storage\/product\/Bolo_festa_junina_arraiá.jpg",
        "category": [
            {
                "id": "2c9495d8-8e7d-4989-a305-6424e4ee9154",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Bolos de Festa Junina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "d07204b5-d691-4fce-925f-88e7a35980dc",
                "event_description": "Festa Junina",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "d08790be-60ef-4dc4-a120-59867aadb893",
                "path": "product\/Bolo_festa_junina_arraiá.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "3249a118-77aa-4069-8aad-1b02948b5f9f",
        "product_description": "Bolo Decorado Batizado Ursinho",
        "product_details": "Bolo Decorado Batizado Ursinho",
        "product_unique_name": "bolo-decorado-batizado-ursinho",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:41.000000Z",
        "updated_at": "2023-02-02T21:46:50.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Batizado_Ursinho.jpg",
        "category": [
            {
                "id": "bca3a878-d222-4c62-8edc-acb83838b282",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Passarinhos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "daf59067-ed2b-4ef3-a4fb-f4b6e36f8472",
                "event_description": "Batizado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "9ed8ee4e-40c7-49f1-a19f-885c45fe9664",
                "path": "product\/Bolo_Batizado_Ursinho.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:41.000000Z",
                "updated_at": "2023-01-26T14:23:41.000000Z"
            }
        ]
    },
    {
        "id": "eb740243-db7f-4874-9ee4-dab717dc129a",
        "product_description": "Bolo Decorado Batizado",
        "product_details": "Bolo Decorado Batizado",
        "product_unique_name": "bolo-decorado-batizado",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:36.000000Z",
        "updated_at": "2023-02-02T21:47:09.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Batizado.jpg",
        "category": [
            {
                "id": "bd35dc6d-f831-492a-96fd-a1ff8dce8256",
                "theme_id": "9b3dc097-f051-4f45-9d37-27b5deb5f1c7",
                "category_description": "Batizado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "daf59067-ed2b-4ef3-a4fb-f4b6e36f8472",
                "event_description": "Batizado",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "66a44216-208f-43cd-bd16-758a2c8f09ae",
                "path": "product\/Bolo_Decorado_Batizado.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:36.000000Z",
                "updated_at": "2023-01-26T14:23:36.000000Z"
            }
        ]
    },
    {
        "id": "3f16167f-6c36-4966-a5de-768f88b542d1",
        "product_description": "Bolo Decorado Dia das Mães (Opção 2)",
        "product_details": "Bolo Decorado Dia das Mães (Opção 2)",
        "product_unique_name": "bolo-decorado-dia-das-maes-(opcao-2)",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:37.000000Z",
        "updated_at": "2023-02-02T21:47:53.000000Z",
        "imagePath": "\/storage\/product\/Bolo_dia_das_mães_opção_2.jpg",
        "category": [
            {
                "id": "1d724543-7ad8-47b2-9436-b80a5ee1e0d9",
                "theme_id": "1b131c45-363f-497d-a69a-e44df260e40e",
                "category_description": "Bolos Dia das Mães",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "6ce5991c-7202-4771-8d5e-a53c9b277164",
                "event_description": "Dia das Mães",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "46a67a8d-0632-4dfa-85d2-748993d9d6c6",
                "path": "product\/Bolo_dia_das_mães_opção_2.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:37.000000Z",
                "updated_at": "2023-01-26T14:23:37.000000Z"
            }
        ]
    },
    {
        "id": "24ae31d6-8f44-4184-917e-d047647cb05c",
        "product_description": "Cupcakes Decorados 15 anos",
        "product_details": "Cupcakes Decorados 15 anos",
        "product_unique_name": "cupcakes-decorados-15-anos",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-02-02T21:48:51.000000Z",
        "imagePath": "\/storage\/product\/Cupcakes_Decorados_15_anos.jpg",
        "category": [
            {
                "id": "d79767dc-79d6-4ba8-9d9a-0583d224dc0a",
                "theme_id": "09b4b0b9-3149-435e-8ed7-8d62277649e7",
                "category_description": "CupCakes 15 anos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "83d07a17-de21-4dfc-becf-b3bda7bd0f6b",
                "event_description": "15 Anos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "6bcc89ed-62c3-47e3-9553-42f2cb9db0a4",
                "path": "product\/Cupcakes_Decorados_15_anos.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    },
    {
        "id": "4f527648-384d-42eb-8fed-68bfe3b3446a",
        "product_description": "Bolo Decorado 15 anos Anjos e Demônios",
        "product_details": "Bolo Decorado 15 anos Anjos e Demônios",
        "product_unique_name": "bolo-decorado-15-anos-anjos-e-demonios",
        "product_value": "0.00",
        "quantity": 0,
        "balance": 0,
        "highlight_product": false,
        "product_image": null,
        "active": true,
        "created_at": "2023-01-26T14:23:33.000000Z",
        "updated_at": "2023-02-02T21:49:05.000000Z",
        "imagePath": "\/storage\/product\/Bolo_Decorado_Anjos_e_Demons.jpg",
        "category": [
            {
                "id": "5145e7b1-b4ee-4cac-a927-7be6c6a5e383",
                "theme_id": "09b4b0b9-3149-435e-8ed7-8d62277649e7",
                "category_description": "Bolo 15 anos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "event": [
            {
                "id": "83d07a17-de21-4dfc-becf-b3bda7bd0f6b",
                "event_description": "15 Anos",
                "active": true,
                "created_at": "2023-01-26T11:23:33.171795Z",
                "updated_at": "2023-01-26T11:23:33.171795Z"
            }
        ],
        "image": [
            {
                "id": "c7be05d3-be5e-4c33-8de7-45484c7e89f6",
                "path": "product\/Bolo_Decorado_Anjos_e_Demons.jpg",
                "type": "product",
                "created_at": "2023-01-26T14:23:33.000000Z",
                "updated_at": "2023-01-26T14:23:33.000000Z"
            }
        ]
    }
]

export const filterProductsList = (filterName, item) => {
    let filtered = []

    if(!filterName || !item)
    {
        return products
    }

    if(filterName === 'productDescription') {
        for (let i = 0; i < products.length; i++)
        {
            let str = (products[i].product_description).toLowerCase()

            if(str.includes(item))
            {
                filtered.push(products[i])
            }
        }

        return filtered
    }

    if(filterName === 'category') {
        return products.filter(product => product.category[0].id === item);
    }
}

export const findByEvent = eventId => {
    const productsEvents = products.filter(product => product.event.length > 0);

    if(!eventId) {
        return productsEvents
    }

    return productsEvents.filter(product => product.event[0].id === eventId);
}

export const findHighlightProducts = () => {
    return products.filter(product => product.highlight_product);
}

export const findByUniqueName = uniqueName => {
    let product = null

    for(let i = 0; i < products.length; i++)
    {
        if(products[i].product_unique_name === uniqueName)
        {
            product = products[i]
        }
    }

    return product
}

export default products