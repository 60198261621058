import axiosIns from '@/libs/axios'
import apiRoutes from '@/router/apiRoutes'

export const getAllProducts = params => new Promise((resolve, reject) => {
  axiosIns
    .get(apiRoutes.products, { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getHighlightProducts = params => new Promise((resolve, reject) => {
  axiosIns
    .get(apiRoutes.highlightProducts, { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const showProductId = id => new Promise((resolve, reject) => {
  axiosIns
    .get(apiRoutes.productId(id))
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const showProductByUniqueName = uniqueName => new Promise((resolve, reject) => {
  axiosIns
    .get(apiRoutes.productUniqueName(uniqueName))
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
